import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";


export function getCouponsRequest(token) {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${BACKEND_URL}/ride/getCoupons`,
    });
  }