import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";
import { date } from "yup";

export function getCreditCustomersRequest({ payload: {
  token,
  mainRoute,
  subRoute
},}) {
  console.log(token,mainRoute);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/creditCustomer/?mainRoute=${mainRoute}&subRoute=${subRoute}`,
  });
}
export function getPaymentCollectionRequest({ payload: {
  token,
  mainRoute,
  subRoute,
  

},}) {
  console.log(token,mainRoute);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/payment-collection/?mainRoute=${mainRoute}&subRoute=${subRoute}`,
  });
}
export function getPaymentHistoryRequest({ payload: {
  token,
  rideId
  

},}) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/order/payment-history/${rideId}`,
  });
}
export function payCreditRequest({
  payload: {
    token,
    formValues: { ...data },

    rideId,
    // mainRoute,
    // subRoute

  },
}){
    console.log(token);
    console.log(data);
    data.rideId =rideId
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    data: JSON.stringify( data),

    method: "post",
    url: `${BACKEND_URL}/order/collect-payment`,
  });
}
