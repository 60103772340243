import { call, put } from "redux-saga/effects";
import { addStockRequest, getStockRequest } from "../requests/StockRequest";
import { addRideRequest ,endRideRequest,getRideRequest} from "../requests/RideRequest";
import { setRide,resetRide } from "../../ducks/RideSlice";
import { setError } from "../../ducks/ErrorSlice";
import { setLoading } from "../../ducks/LoadingSlice";
import handleAuthError from "./HandleAuthErrors";

export function* handleGetRide(action) {
  console.log(action);
  try {
    const { data } = yield call(getRideRequest, action);
    if (data) {
      yield put(setRide(data));
    }
    yield put(setLoading({ loading: false }));
  } catch (error) {
    console.log(error);
    console.log('hello end is here',error);
    handleAuthError(error);
  }
}

export function* handleAddRide(action) {
    console.log(action);
  try {
    const { data } = yield call(addRideRequest, action);
    if (data) {
      yield put(setRide(data.data));
      yield put(setError(null))

      console.log(data);
    } else {
      throw Error("Stock is not added to the data base");
    }
  } catch (error) {
    console.log(error.response.data.message,'add ride error');
    yield put(setError(error.response.data.message))

    
  }
}
export function* handleEndRide(action) {
  console.log(action);
try {
  const { data } = yield call(endRideRequest, action);
  if (data) {
    console.log('working');
    yield put(resetRide(null));

  } else {
    throw Error("Stock is not added to the data base");
  }
} catch (error) {
  console.log(error);
  console.log(error.response.data.message);

  
}
}
