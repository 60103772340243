import { createSlice } from "@reduxjs/toolkit";
const salesSlice = createSlice({
  name: "sales",
  initialState:{
  todaySales: null,
  cashSales: null,
  creditSales: null,
  isLoading: false
},

  reducers: {
    setTodaySales(state, action) {
      const Sales = action.payload;
      return { ...state, todaySales: Sales };
    },
    setCreditSales(state, action) {
      const Sales = action.payload;
      return { ...state, creditSales: Sales };
    },
    setCashSales(state, action) {
      const Sales = action.payload;
      return { ...state, cashSales: Sales };
    },
    setIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },

    getTodaySales() {},
    getCashSales() {},
    getCreditSales() {},
  },
});

export const {
  setTodaySales,
  setCashSales,
  setCreditSales,
  getCashSales,
  getCreditSales,
  getTodaySales,
  setIsLoading,
} = salesSlice.actions;

export default salesSlice.reducer;
