import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import Input from "../input/Input";
import Section from "../commonListSection/Section";
import Pagination from "../pagination/Pagination";
import Skeleton from "../skeleton/Skeleton";
import { getTodaySales } from "../../redux/ducks/SalesSlice";
const TodaySales = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { todaySales } = useSelector((state) => state.sales)??{};
  const { token } = JSON.parse(localStorage.getItem("user"));
  const { mainRoute, subRoute } = useSelector((state) => state.ride)??{};
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const rideId = useSelector((state) => state.ride._id);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
console.log(todaySales,'todaySALES');
  const handleFiltering = (event) => {
    const input = event.target.value;
    setInputValue(input);

    // Filter options by input value
    const filtered = todaySales?.filter((option) => {
      const Name = option.Name;
      return Name?.toLowerCase().includes(input.toLowerCase());
    });

    setFilteredOptions(filtered);
  };

  useEffect(() => {
    dispatch(getTodaySales({ token, rideId }));
  }, [dispatch]);

    useEffect(() => {
      if (todaySales?.length) {
        setFilteredOptions(todaySales);
      }
    }, [todaySales]);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    const totalPages = Math.ceil(filteredOptions.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibletodaySales = filteredOptions.slice(startIndex, endIndex);
  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-10 select-none">
      <div className="flex gap-7">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-5 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <Input
            id={"search__input"}
            type={"text"}
            value={inputValue}
            onChange={handleFiltering}
            name={"search__input"}
            placeholder={"Search"}
            styles={`w-full px-3 py-2 pl-14 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              placeholder-slate-400  block sm:text-sm focus:ring-1 rounded-3xl`}
          />
        </div>
       
      </div>
      {!todaySales?<div className="flex flex-col gap-4">
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
        </div>:<><div className="flex flex-col gap-2">
        {todaySales?.length ? (
          visibletodaySales.length ? (
            visibletodaySales.map((x, idx) => (
              <Section
                key={idx}
                name={x.Name}
                subOne={`${subRoute},${mainRoute}`}
                mobile={x.mobileNumber}
                // dueAmount={x.dueAmount}
                Amount={x.Amount}
                img={ "/userProfile.74cda66309e83509d2bd.avif"}
                onClick={() => navigate(`/customers/customer/${x.customerId}`)}
                
                phone ={x.phone}

                type={2}
              ></Section>
            ))
            
          ) : (
            <div className="flex justify-center items-center h-48">
              No todaySales matching with that name
            </div>
          )
        ) : (
          <div className="flex justify-center items-center h-48">No todaySales</div>
        )}
      </div>
      
      {visibletodaySales.length ? (
        <Pagination
          currentPage={currentPage}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}
          totalPages={Math.ceil(filteredOptions.length / itemsPerPage)}
        ></Pagination>
      ) : (
        ""
      )}
      </>}
      
      
    </div>
  );
};

export default TodaySales;
