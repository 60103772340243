/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { addStock } from "../../redux/ducks/StockSlice";
import Input from "../input/Input";
import Select from "../select/Select";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
// import { getnewRideRequest } from "../../redux/sagas/requests/RideRequest";
import { getRide } from "../../config/api/Api";
import { addRide } from "../../redux/ducks/RideSlice";
import Stock from "../Stock/Stock";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setError } from "../../redux/ducks/ErrorSlice";
import SelectCustom from "../select/SelectCustom";
import { getRouteStockTotalRequest } from "../../redux/sagas/requests/RideRequest";
// const subRoute = [{ name: "3214" }, { name: "8567" }, { name: "347" }];
import CircularLoading from "../circularloading/CircularLoading";

const initialValues = {
  mainRoute: "",
  subRoute: "",
  driver: "",
  helper: "",
  vehicleNo: "",
  openingStock: "",
  loadedStock: "",
  totalStockInHand: "",
  startedKm: "",
};

const rideSchema = yup.object().shape({
  vehicleNo: yup.string().required("Vehicle is required"),
  mainRoute: yup.string().required("Main route is required"),
  subRoute: yup.string().required("Sub route is required"),
  helper: yup.string(),
  // .required("Helper is required"),
  driver: yup.string().required("Driver is required"),
  openingStock: yup.string().required("Required"),
  loadedStock: yup
    .string()
    .required("Required")
    .test("invalid", "invalid", (value) => {
      const val = parseInt(value);
      return val >= 0;
    }),
  totalStockInHand: yup
    .string()
    .required("Required")
    .test("invalid", "Invalid Stock", (value) => {
      const val = parseInt(value);
      return val > 0;
    }),
  startedKm: yup
    .string()
    .required("Required")
    .test("invalid", "invalid", (value) => {
      const val = parseInt(value);
      return val > 0;
    }),
});

const StartRideFormSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ride = useSelector((state) => state.ride);
  const rideError = useSelector((state) => state.error.Rideerrors);
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    if (rideError) {
      if (rideError === "Vehicle Already in use") {
        const name = "vehicleNo";

        setFormErrors({
          ...formErrors,
          [name]: "vehicle already selected",
        });
      }
      if (rideError === "Route Already in use") {
        const name = "mainRoute";

        setFormErrors({
          ...formErrors,
          [name]: "route no already selected",
        });
      }
      if (rideError === "Helper Already in ride") {
        const name = "helper";

        setFormErrors({
          ...formErrors,
          [name]: "Helper already selected ",
        });
      }
      if (rideError === "Driver Already in ride") {
        const name = "Driver";

        setFormErrors({
          ...formErrors,
          [name]: "Driver already selected ",
        });
      }

      toast.error(rideError, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          setToastVisible(false);
        },
      });
    }

    // Add a cleanup function here
    return () => {
      setToastVisible(false);
      dispatch(setError(""));
    };
  }, [rideError]);

  const { token } = JSON.parse(localStorage.getItem("user"));

  const [mainRoute, setMainRoute] = useState([
    { name: "3214" },
    { name: "8567" },
    { name: "347" },
  ]);
  const [subRoute, setSubRoute] = useState([]);
  const [drivers, setDrivers] = useState([[]]);
  const [helpers, setHelpers] = useState([[]]);
  const [vehicles, setVehicles] = useState([]);

  const [Route, setRoute] = useState([]);
  const [vehicleDetail, setVehicleDetail] = useState([]);
  const [vehicleNum, setVehicleNum] = useState("");

  const [total, settotal] = useState(0);
  const [openStock, setopenStock] = useState(0);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  console.log(formErrors);
  console.log(vehicleDetail);
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "loadedStock") {
      console.log("not working");
      console.log(value);
      handleTotalSTock(value);
    }
    setFormValues({ ...formValues, [name]: value });

    try {
      await yup.reach(rideSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      console.log(error);
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };
  const handleVehiclStockChange = async (e) => {
    const { name, value } = e.target;
    console.log(name);
    console.log(value);
    console.log(vehicleDetail);
    // const stock = vehicleDetail.find((obj) => obj.vehicleNo === value);
    // console.log(stock);
    // setopenStock(stock.stock);
    // setVehicleNum(Stock.vehicleNo);

    try {
      setFormValues({ ...formValues, [name]: value });

      await yup.reach(rideSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
      return;
    } catch (error) {
      console.log(error);
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };

  const handleMainRouteChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value, subRoute: "" });
    const selectedRoute = Route.find((route) => route?.mainRoute === value);
    const { loadedStock, openingStock, totalStock } =
      (await getRouteStockTotalRequest(token, value)) ?? {
        loadedStock: 0,
        openingStock: 0,
        totalStock: 0,
      };

    console.log(loadedStock, openingStock, "selectedsubroute");

    if (selectedRoute) {
      console.log(selectedRoute);
      if (selectedRoute.subRoute) {
        setSubRoute(selectedRoute?.subRoute?.map((obj) => ({ name: obj })));
      } else {
        alert("Not yet subroute");
        return;
      }
    } else {
      // Handle the case when no matching route is found
      setSubRoute([]);
    }
    try {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [name]: value,
        loadedStock: loadedStock,
        // vehicleNo: selectedRoute?.vehicleNo?'KL408':'KL408',
        openingStock: openingStock,
        totalStockInHand: totalStock,
      }));
      // setVehicleNum('KL408');
      await yup.reach(rideSchema, name).validate(value);
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: null,
        loadedStock: null,
        openingStock: null,
        totalStockInHand: null,
      }));
    } catch (error) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: error.message,
      }));
    }
  };

  // ******** if vehicle is assign to route
  // useEffect(() => {
  //   console.log("kkkdsfdsagfdgdgfdgfdg");

  //   if (vehicleNum) {
  //     console.log("qqqdsfdsagfdgdgfdgfdg");

  //     const stock = vehicleDetail.find((obj) => obj.vehicleNo === vehicleNum);
  //     if (stock) {
  //       console.log("dsfdsagfdgdgfdgfdg");
  //       console.log(stock.stock);
  //       const name = "openingStock";
  //       setFormValues((prevFormValues) => ({
  //         ...prevFormValues,
  //         [name]: stock.stock,
  //       }));
  //     }
  //   }
  // }, [vehicleNum]);
  const handleTotalSTock = async (e) => {
    const value = parseInt(formValues.openingStock) + parseInt(e);

    const name = "totalStockInHand";
    settotal(value);

    try {
      setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
      await yup.reach(rideSchema, name).validate(value);
      setFormErrors((prevFormErrors) => ({ ...prevFormErrors, [name]: null }));
      return;
    } catch (error) {
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [name]: error.message,
      }));
    }
  };
  console.log(formValues);

  // ********since opening stock and loaded stock are coming from route
  // useEffect(() => {
  //   const name = "totalStockInHand";
  //   // vehicleDetail.map((vehicle) => ({ name: openStock }));
  //   const totalstock =
  //     (parseInt(formValues.openingStock) ?? 0) +
  //     (parseInt(formValues.loadedStock) ?? 0);
  //   setFormValues((prevFormValues) => ({
  //     ...prevFormValues,
  //     [name]: totalstock,
  //   }));
  // }, [formValues.loadedStock, formValues.openingStock]);

  useEffect(() => {
    getRide()
      .then(({ data }) => {
        console.log(data, "data from datkend");
        setVehicleDetail(data?.vehicles);
        setDrivers(data?.drivers);
        setHelpers(data?.helpers);

        setRoute(data?.routes);
        // Fix the misspelled property name and use data.vehicles for mapping
        setMainRoute(data?.routes.map((obj) => ({ name: obj.mainRoute })));
        setVehicles(data?.vehicles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (toastVisible) {
      return;
    }
    try {
      setToastVisible(true);

      await rideSchema.validate(formValues, { abortEarly: false });
      // dispatch(addStock({ formValues, token }));
      dispatch(addRide({ formValues, token }));
      if (ride) {
        navigate("/");
      }
    } catch (error) {
      setTimeout(() => {
        setToastVisible(false);

      }, 1000);
      const validationErrors = {};

      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };

  return (
    <div className="flex flex-col min-h-base px-10 py-10 select-none">
      <div className="bg-white p-3 rounded-md ">
        <label className="text-red-350 text-sm">{rideError}</label>
        <form onSubmit={handleSubmit}>
          {/* <Select
            name={"mainRoute"}
            options={mainRoute}
            label={formErrors?.mainRoute}
            onChange={handleMainRouteChange}
            defaultValue={{ name: "Main route" }}
            labelStyles={"text-red-350 text-sm"}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-black border shadow-sm
            ${
              formErrors?.mainRoute
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            childStyles={"cursor-pointer"}
          /> */}
          <SelectCustom
            name={"mainRoute"}
            options={mainRoute}
            label={"MainRoute"}
            onChange={handleMainRouteChange}
            error={formErrors?.mainRoute}
            errorMessage={formErrors?.mainRoute}
            bgColor={"white"}
          ></SelectCustom>
          <SelectCustom
            name={"subRoute"}
            options={subRoute}
            label={"Sub Route"}
            value={formValues.subRoute}
            onChange={handleInputChange}
            error={formErrors?.subRoute}
            errorMessage={formErrors?.subRoute}
            bgColor={"white"}
          ></SelectCustom>

          {/* <Select
            name={"subRoute"}
            options={subRoute}
            label={formErrors?.subRoute}
            value={formValues.subRoute}
            onChange={handleInputChange}
            labelStyles={"text-red-350 text-sm"}
            defaultValue={{ name: "Select sub route" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.subRoute
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <SelectCustom
            name={"helper"}
            options={helpers}
            label={"Helper"}
            errorMessage={formErrors.helper}
            error={formErrors.helper}
            onChange={handleInputChange}
            bgColor={"white"}
          ></SelectCustom>
          {/* <Select
            name={"helper"}
            options={helpers}
            label={formErrors?.helper}
            onChange={handleInputChange}
            labelStyles={"text-red-350 text-sm"}
            defaultValue={{ name: "Helper" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.helper
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <SelectCustom
            options={drivers}
            name={"driver"}
            onChange={handleInputChange}
            label={"Driver"}
            error={formErrors.driver}
            errorMessage={formErrors.driver}
            bgColor={"white"}
          ></SelectCustom>
          {/* <Select
            options={drivers}
            name={"driver"}
            onChange={handleInputChange}
            label={formErrors?.driver}
            labelStyles={"text-red-350 text-sm"}
            defaultValue={{ name: "Driver" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.driver
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <SelectCustom
            options={vehicles}
            name={"vehicleNo"}
            onChange={handleInputChange}
            label={"Vehicle No"}
            labelStyles={"text-red-350 text-sm"}
            error={formErrors.vehicleNo}
            errorMessage={formErrors.vehicleNo}
            bgColor={"white"}
          ></SelectCustom>

          {/* <Select
            options={vehicles}
            name={"vehicleNo"}
            onChange={handleInputChange}
            label={formErrors?.vehicleNo}
            labelStyles={"text-red-350 text-sm"}
            value={formValues.vehicleNo}
            defaultValue={{ name: "VehicleNo" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.vehicleNo
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          {/* <Input
            type={"text"}
            value={formValues.vehicleNo}
            placeholder={'VehicleNo'}
            styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                  ${
                    formErrors?.vehicleNo
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
            readOnly={true}
          /> */}

          <div className="px-5 py-3">
            <div className="flex gap-4 mb-3 items-center">
              <h1 className="whitespace-nowrap text-black w-3/6 text-sm">
                Opening Stock
              </h1>
              <div className="w-3/6">
                <label className="text-red-350 text-sm">
                  {formErrors?.openingStock}
                </label>
                <Input
                  type={"number"}
                  name={"openingStock"}
                  value={formValues.openingStock}
                  styles={`inline w-full px-3 py-0.5 bg-white border shadow-sm 
                  
                  ${
                    formErrors?.openingStock
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-black w-3/6 text-sm">
                Loaded Stock
              </h1>
              <div className="w-3/6">
                <label className="text-red-350 text-sm">
                  {formErrors.loadedStock}
                </label>
                <Input
                  type={"number"}
                  name={"loadedStock"}
                  value={formValues.loadedStock}
                  styles={`inline w-full px-3 py-0.5 bg-white border shadow-sm 
                  ${
                    formErrors?.loadedStock
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center mb-3">
              <h1 className="whitespace-normal text-black w-3/6 text-sm">
                Total Stock in Hand
              </h1>
              <div className="w-3/6">
                <label className="text-red-350 text-sm">
                  {formErrors.totalStockInHand}
                </label>
                <Input
                  type={"number"}
                  name={"totalStockInHand"}
                  value={formValues.totalStockInHand}
                  styles={`inline w-full max-h-[30px] px-3 py-0.5 bg-white border shadow-sm 
                  ${
                    formErrors?.totalStockInHand
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-black w-3/6 text-sm">
                Started KM
              </h1>
              <div className="w-3/6">
                <label className="text-red-350 text-sm">
                  {formErrors.startedKm}
                </label>
                <Input
                  type={"number"}
                  name={"startedKm"}
                  onChange={handleInputChange}
                  styles={`inline w-full px-3 py-0.5 bg-white border shadow-sm 
                  ${
                    formErrors?.startedKm
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-main text-white font-medium py-2 px-4 rounded-md mt-6 w-52"
              >
                {toastVisible ? <CircularLoading /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default StartRideFormSection;
