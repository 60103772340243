import LoginSection from "../../components/loginSection/LoginSection";

const Login = () => {
  return (
    <>
      <LoginSection />
    </>
  );
};

export default Login;
