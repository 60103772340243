import { createSlice } from "@reduxjs/toolkit";
const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    loading: true,
    sendLoading:false,
    sendSuccess:false,
    sendError:false

  },
  reducers: {
    setLoading(state, action) {
      console.log('ice ',action);
      const loading = action.payload;
      return { ...state, ...loading };
    },
    setSendLoading(state, action) {
      console.log('ice ',action);
      return { ...state, sendLoading:action.payload };
    },
    setSendSuccess(state, action) {
      console.log('oombi');
      return { ...state, sendSuccess:action.payload };
    },
    setSendError(state, action) {
      return { ...state, sendError:action.payload };
    },
  },
});

export const { setLoading ,setSendLoading,setSendSuccess,setSendError} = loadingSlice.actions;

export default loadingSlice.reducer;
