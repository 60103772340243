import api from "../axios/Axios";
export default  function  getOrders(){
    console.log('working');
   return api.post('/user/first' ,{name:'fazmir'})
}
export  function getRide(){
    console.log('working ride');
    
   return api.get('/ride/new-ride')
}
export  function getRoute(){
    
    return api.get('/user/first' ,{name:'fazmir'})
 }
 export  function getHelper(){
    
    return api.post('/user/first' ,{name:'fazmir'})
 }
