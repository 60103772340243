/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import Input from "../input/Input";
import Select from "../select/Select";
import { getVisitHistory } from "../../redux/ducks/VisitHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Section from "../commonListSection/Section";
import { getRide } from "../../redux/ducks/RideSlice";
import { getHomeStockBlance, getRouteStockRequest } from "../../redux/sagas/requests/StockRequest";
import { getQuantityRequest } from "../../redux/sagas/requests/SalesRequest";
import { setStockBalance } from "../../redux/ducks/VanLife";
const Stock = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { token } = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [sold, setSold] = useState([]);
  const [empty, setEmpty] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const {stockBalance } = useSelector(
    (state) => state.vanLife
  );
  const {
    cashSale,
    creditSale,
    totalStockInHand,
    openingStock,
    soldBottle,
    emptyBottle,
    loadedStock,
    mainRoute,
    additionalStock,
    waterDamage,
    capLeak,
    BottleLeak,
    
  } = useSelector((state) => state.ride) || {};
  const rideId = useSelector((state) => state.ride._id);

  useEffect(() => {
    console.log("WORKING");
    dispatch(getRide({ token }));
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRouteStockRequest(token, mainRoute);
      setStockItems(data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { sold, emptyBottle } = await getQuantityRequest(token, rideId);
        // Handle data or set state here
        console.log(sold, "hi");
        setSold(sold);
        setEmpty(emptyBottle);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the asynchronous function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getHomeStockBlance(token, rideId);
      const {totalStock,soldQuantity} =data
      dispatch(setStockBalance(totalStock));
      // dispatch(setSoldStock(soldQuantity))
      // setStockItems(data);
      // dispatch(setStockItems(data))
    };
    fetchData();
  }, []);
  const totalSold = (sold.length > 0 && sold[0]?.totalQuantity) || 0;
  const totalEmpty = (empty.length > 0 && empty[0]?.totalQuantity) || 0;
  const totalQuantity = stockItems.reduce((total, obj) => total + obj.quantity, 0);

  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-8 select-none ">
      <div className="flex w-full justify-between gap-5 pt-8">
        <div className="flex flex-col justify-center items-center bg-white  hover:bg-sub   py-0.5 w-1/2 rounded-2xl">
          <h1 className="text-main text-4xl font-bold py-2">
            {stockBalance}
          </h1>
          <h1 className="text-main text-md font-normal text-center py-0.5 tracking-tighter">
            Stock in Hand
          </h1>
        </div>
        <div className="flex flex-col justify-center items-center bg-white   hover:bg-sub  py-0.5 w-1/2 rounded-2xl">
          <h1 className="text-main text-4xl font-bold py-2">{waterDamage+capLeak+BottleLeak}</h1>
          <h1 className="text-main text-md font-normal text-center py-0.5 tracking-tighter">
            Damage Stock
          </h1>
        </div>
      </div>
      {/* <div className="flex w-full justify-between gap-5 ">
        <div className="flex flex-col justify-center items-center bg-white  hover:bg-sub  px-6 py-0.5 w-1/2 rounded-2xl">
          <h1 className="text-main text-4xl font-bold py-2">
            {parseInt(totalStockInHand)-parseInt(emptyBottle)}
          </h1>
          <h1 className="text-main text-md font-normal text-center py-0.5 tracking-tighter">
            Stock Balance
          </h1>
        </div>
        <div className="flex flex-col justify-center items-center bg-white px-6  hover:bg-sub  py-0.5 w-1/2 rounded-2xl">
          <h1 className="text-main text-4xl font-bold py-2">{emptyBottle}</h1>
          <h1 className="text-main text-md font-normal text-center py-0.5 tracking-tighter">
            Empty Stock
          </h1>
        </div>
      </div> */}
      <div className="flex justify-center items-center p-2 bg-white rounded-3xl  hover:bg-sub">
        <div className="flex flex-col w-full justify-center items-center">
          <h1 className="text-main text-4xl font-bold">00</h1>
          <h1 className="text-main text-md font-normal py-0.5">
            Internal Transfers
          </h1>
        </div>
      </div>
      <div
        className="flex justify-center items-center p-2 bg-white  hover:bg-sub rounded-3xl "
        onClick={() => {
          navigate("/item");
        }}
      >
        <div className="flex flex-col w-full h-20 justify-center items-center ">
          <h1 className="text-main text-2xl font-semibold">Others</h1>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center p-3 bg-white rounded-3xl">
        <div className="flex w-full justify-center ">
          <div className="flex flex-col justify-center items-center w-full   border-r border-[#B3E5AA]">
            <h1 className="text-main text-xl font-bold py-0.5">
              {totalEmpty}
            </h1>
            {/* <h1 className="text-main text-md font-medium py-0.5"></h1> */}
            <h1 className="text-main text-md font-normal text-center py-0.5">
              Empty Stock
            </h1>
          </div>
          <div className="flex flex-col justify-center items-center w-full ">
            <h1 className="text-main text-xl font-bold py-0.5">
              {totalQuantity}
            </h1>
            {/* <h1 className="text-main text-md font-medium py-0.5">AED</h1> */}
            <h1 className="text-main text-md font-normal py-0.5 text-center">
              Balance Stock
            </h1>
          </div>
        </div>
        <h1 className="text-main text-md font-medium pt-2">Started Stock :</h1>
        <h1 className="text-main text-md font-medium py-2">
          {totalSold + totalQuantity}
        </h1>
      </div>
    </div>
  );
};

export default Stock;
