export const navList = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Orders",
    path: "/orders",
  },
  {
    name: "Customers",
    path: "/customers",
  },
  {
    name: "Stock",
    path: "/stock",
  },
  {
    name: "Summary",
    path: "/summary",
  },
  {
    name: "Return",
    path: "/return-customer",
  },
  {
    name: "Payment Collection",
    path: "/payment-collection",
  },
  {
    name: "Credit Issued",
    path: "/credit-issued",
  },
  // {
  //   name: "Credit Collecttion",
  //   path: "/credit-collection",
  // },
  // {
  //   name: "Coupon Sale",
  //   path: "/membership-sale",
  // },
  {
    name: "Coupon Customers",
    path: "/membership-customers",
  },
  {
    name: "Route",
    path: "/route",
  },
  {
    name: "Visit History",
    path: "/visit-history",
  },
  {
    name: "End Ride",
    path: "/end-ride",
  },
];
