import { BiSolidPhone } from "react-icons/bi";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Children } from "react";
const Section = ({
  image,
  name,
  subOne,
  subTwo,
  mobile,
  type,
  onClick,
  img,
  path,
  visited,
  phone,
  dueAmount,
  id,
  delivered,
  Mode,
  Total,
  stock,
  collected,
  coupon,
  foc,
  returnamount,
  Amount
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-around bg-white rounded-lg p-2">
      <div
        className="flex justify-center border-r-2 border-r-[#EEE7FF] w-3/12"
        onClick={onClick}
      >
        <img
          className="w-14 h-14 rounded-full"
          src={img ? `${img}` : "/userProfile.74cda66309e83509d2bd.avif"}
          alt=""
          onClick={() => {
            navigate(path);
          }}
        />
      </div>
      <div className="flex flex-col    gap-2 leading-normal w-5/12">
        <div className="">
          <h4 className="text-black  font-semibold text-xs">{name}</h4>
          <h2 className="text-black   text-xs mt-1.5 leading-tight">
            {subOne}
          </h2>
          {!mobile ? (
            <div className="flex flex-col  ">
              {dueAmount ? (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">Due Amount - </span>
                  <span className="text-red-500 text-xs font-bold mt-1.5">
                    AED{dueAmount}
                  </span>
                </div>
              ) : (
                ""
              )}
              {Mode && (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">{Mode}</span>
                </div>
              )}
             
              {collected && (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">Collected - </span>
                  <span className="text-red-500 text-xs font-bold mt-1.5">
                    AED {collected}
                  </span>
                </div>
              )}
               {Amount && (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">Amount - </span>
                  <span className="text-red-500 text-xs font-bold mt-1.5">
                    AED {Amount}
                  </span>
                </div>
              )}
               { returnamount && (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">Return amount - </span>
                  <span className="text-red-500 text-xs font-bold mt-1.5">
                    AED {returnamount}
                  </span>
                </div>
              )}
               {coupon && (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">Coupon remaining - </span>
                  <span className="text-red-500 text-xs font-bold mt-1.5">
                    {foc}
                  </span>
                </div>
              )}

              {Total ? (
                <div className="flex flex-row">
                  <span className="text-mud text-xs mt-1.5">Total - </span>
                  <span className="text-red-500 text-xs font-bold mt-1.5">
                    AED {Total}
                  </span>
                </div>
              ): (
                 ""
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center  w-full gap-2 justify-start px-3  ">
                <div
                  className="rounded-full cursor-pointer"
                  // onClick={handleDecrement}
                >
                  <AiFillMinusCircle size={15} color="green" />
                </div>
                <div className="w-10 border border-green-500 text-center text-xs">
                  {/* {count.toString().padStart(2, "0")} */}
                  {stock}
                </div>
                <div
                  className="rounded-full cursor-pointer"
                  // onClick={handleIncrement}
                >
                  <AiFillPlusCircle size={15} color="green" />
                </div>
              </div>
              <span className="text-mud text-xs mt-1.5">
                stock - <span className="text-red-600">{stock} nos</span>{" "}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center w-3/12">
        {type === 1 ? (
          <>
            <div
              onClick={onClick}
              className="text-main font-bold text-xs bg-sub-plus px-4 py-2 text-center rounded-lg cursor-pointer"
            >
              <h4>View</h4>
            </div>
          </>
        ) : type === 2 ? (
          <>
            <div>
              <div
                onClick={() => {
                  window.location.href = `tel:${phone}`;
                }}
                className="text-red font-bold text-xs w-13 h-10 bg-sub-plus px-4 py-2 text-center rounded-lg cursor-pointer"
              >
                <BiSolidPhone className="text-main w-5 h-5  text-center " />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              <div
                onClick={() => {}}
                className="text-black font-bold text-xs bg-sub-plus  py-2 text-center rounded-lg cursor-pointer"
              >
                {visited ? (
                  <p className="text-main px-4 text-xxs"> Visited</p>
                ) : (
                  <p
                    onClick={() => {
                      onClick(id);
                    }}
                    className="text-main  text-xxs"
                  >
                    Not Visited
                  </p>
                )}
              </div>
              <div className="text-black font-bold text-xs bg-sub-plus  py-2 text-center rounded-lg cursor-pointer">
                {delivered ? (
                  <h6 className="text-main text-xxs px-4"> Delivered</h6>
                ) : (
                  <h6 className="text-main text-xxs">Not Delivered</h6>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Section;
