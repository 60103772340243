/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import Input from "../input/Input";
import Section from "../commonListSection/Section";
import { getCreditCustomers, setCreditCustomerLoading } from "../../redux/ducks/CreditCustomerSlice";
import Skeleton from "../skeleton/Skeleton";
const AllcreditCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(useSelector((state) => state.creditCustomer));
  const { creditCustomers } = useSelector((state) => state.creditCustomer);
  const { token } = JSON.parse(localStorage.getItem("user"));
  console.log(useSelector((state) => state.ride?._id));
  const { mainRoute, subRoute } = useSelector((state) => state.ride)??{};
  const { creditCustomerLoading } = useSelector(
    (state) => state.creditCustomer
  );
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const handleFiltering = (event) => {
    const input = event.target.value;
    setInputValue(input);

    // Filter options by input value
    const filtered = creditCustomers?.filter((option) => {
      const name = option.name;
      return name?.toLowerCase().includes(input.toLowerCase());
    });

    setFilteredOptions(filtered);
  };
  useEffect(() => {
    dispatch(getCreditCustomers({ token, mainRoute, subRoute }));
    return (()=> {
      dispatch(setCreditCustomerLoading(false));
    }
    )
  }, []);

  useEffect(() => {
    if (creditCustomers?.length) {
      setFilteredOptions(creditCustomers);
    }
  }, [creditCustomers]);
  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-10 select-none">
      <div className="flex gap-7">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-5 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <Input
            id={"search__input"}
            type={"text"}
            value={inputValue}
            onChange={handleFiltering}
            name={"search__input"}
            placeholder={"Search"}
            styles={`w-full px-3 py-2 pl-14 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              placeholder-slate-400  block sm:text-sm focus:ring-1 rounded-3xl`}
          />
        </div>
        {/* <div
          onClick={() => navigate("/creditCustomers/add-customer")}
          className="flex items-center justify-center h-9 rounded-full bg-mud-plus w-[2.7rem] cursor-pointer"
        >
          <AiOutlinePlus />
        </div> */}
      </div>
      {!creditCustomers? (
        <div className="flex flex-col gap-4">
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {creditCustomers?.length ? (
            filteredOptions?.length ? (
              filteredOptions.map((x, idx) => (
                <Section
                  key={idx}
                  name={x.name}
                  subOne={`${x.subRoute},${x.mainRoute}`}
                  mobile={x.mobileNumber}
                  dueAmount={x.dueAmount===0?"0":x.dueAmount}
                  // onClick={() =>
                  //   navigate(`/creditCustomers/creditCustomer/${x._id}`)
                  // }
                  onClick={() => navigate(`/customers/customer/${x._id}`)}

                  img={ "/userProfile.74cda66309e83509d2bd.avif"}
                  phone={x.mobile}
                  type={2}
                />
              ))
            ) : (
              <div className="flex justify-center items-center h-48">
                No creditCustomers matching with that name
              </div>
            )
          ) : (
            <div className="flex justify-center items-center h-48">
              No creditCustomers
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AllcreditCustomer;
