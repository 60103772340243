import { createSlice } from "@reduxjs/toolkit";

const creditCustomersSlice = createSlice({
  name: "creditCustomer",
  initialState: {
    creditCustomers: "",
    creditCustomerLoading: true,
    paymentCollection: "",
    paymentHistory: "",
  },
  reducers: {
    getCreditCustomers() {},
    getPaymentCollection() {},
    getPaymentHistory() {},
    payCredit() {},

    setCreditCustomers(state, action) {
      const CreditCustomers = action.payload;
      return { ...state, creditCustomers: CreditCustomers };
    },
    setPaymentCollection(state, action) {
      return { ...state, paymentCollection: action.payload };
    },
    setPaymentHistory(state, action) {
      return { ...state, paymentHistory: action.payload };
    },
    setCreditCustomerLoading(state, action) {
      return { ...state, creditCustomerLoading: action.payload };
    },
  },
});

export const {
  getCreditCustomers,
  setCreditCustomers,
  setPaymentCollection,
  getPaymentCollection,
  setCreditCustomerLoading,
  payCredit,
  getPaymentHistory,
  setPaymentHistory
} = creditCustomersSlice.actions;

export default creditCustomersSlice.reducer;
