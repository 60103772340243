import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";


import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // This will use local storage
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/RootSaga";
import { createLogger } from "redux-logger";
// Import your reducers here
import userReducer from "./ducks/UserSlice";
import stockReducer from "./ducks/StockSlice";
import loadingReducer from "./ducks/LoadingSlice";
import customerReducer from "./ducks/CustomerSlice";
import orderReducer from "./ducks/OrderSlice";
import rideReducer from "./ducks/RideSlice";
import errorReducer from "./ducks/ErrorSlice";
import creditCustomerReducer from './ducks/CreditCustomerSlice'
import returnReducer from './ducks/ReturnSlice'
import visitHistoryReducer from "./ducks/VisitHistorySlice";
import summaryReducer from "./ducks/SummarySlice"
import membershipReducer from "./ducks/MembershipSlice"
import salesReducer from "./ducks/SalesSlice";
import sidebarReducer from "./ducks/sidebarSlice";
import vanLifeReducer from "./ducks/VanLife";
const persistConfig = {
  key: "root",
  storage,
  whitelist: [], // Only "ride" slice will be persisted
};

const rootReducer = combineReducers({
  user: userReducer,
  stock: stockReducer,
  loading: loadingReducer,
  customers: customerReducer,
  order: orderReducer,
  ride:rideReducer,
  error:errorReducer,
  creditCustomer:creditCustomerReducer,
  return:returnReducer,
  visitHistory:visitHistoryReducer,
  summary:summaryReducer,
  membership:membershipReducer,
  sales:salesReducer,
  siderbar:sidebarReducer,
  vanLife:vanLifeReducer

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();
const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware, loggerMiddleware,],
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
;
