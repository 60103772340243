import { call, put } from "redux-saga/effects";
import handleAuthError from "./HandleAuthErrors";

import { setVisitHistory ,setVisitHistoryLoading,} from "../../ducks/VisitHistorySlice";
import { getVisitHistoryRequest,addVisitHistoryRequest } from "../requests/VisitHistoryRequest";
export function* handleGetVisitHistory(action) {
  try {
    // yield put(setLoading({ loading: true }));

    const { data } = yield call(getVisitHistoryRequest, action);

    yield put(setVisitHistory(data));
    console.log('working');
    yield put(setVisitHistoryLoading(false))

    // yield put(setLoading({ loading: false }),
    // )
  } catch (error) {
    console.log(error);
    // yield put(setVisitHistoryLoading(true))

    handleAuthError(error);
  }
}

export function* handleAddVisitHistory(action) {
  console.log(action);
try {
  const { data } = yield call(addVisitHistoryRequest, action);
  if (data) {
    // yield put(setChangeVisitHistory(false));
  } else {
    throw Error("Stock is not added to the data base");
  }
} catch (error) {
  console.log(error);
}
}


