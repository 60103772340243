import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getItemsRequest(token,mainRoute) {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${BACKEND_URL}/ride/getItems?mainRoute=${mainRoute}`,
    });
  }

  
export function getSingleItemsRequest(token,id,mainRoute) {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${BACKEND_URL}/ride/getItems/${id}?mainRoute=${mainRoute}`,
    });
  }