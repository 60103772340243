import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function addReturnRequest({
  payload: {
    token,
    formValues: { ...data },
  },
}) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify({ data }),
    url: `${BACKEND_URL}/order//return-item`,
  });
}
export function getReturnCustomerRequest({ payload: {
  token,
  rideId,
},}) {
console.log(token);
console.log(rideId);


  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}` ,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/return-customer/${rideId}`,
  });
}