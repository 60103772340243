import * as yup from "yup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Input from "../input/Input";
import { userLogin } from "../../redux/ducks/UserSlice";
import { getOrder, addOrder } from "../../redux/ducks/OrderSlice";
const loginSchema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const LoginSection = () => {
  const dispatch = useDispatch();
  const { loginError } = useSelector((state) => state.user);
  const orderchange = useSelector((state) => state.order.orderChange);
  console.log(loginError);
  if (orderchange) {
    console.log("working orderchange");
  }
  console.log("working");
  useEffect(() => {
    console.log("changing working properly");
  }, [orderchange]);

  const [loginErrors, setLoginErrors] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);

  const [showPassword, setShowPassword] = useState(false);

  function showMePassword() {
    const inp_password = document.querySelector("#inp_password");
    const eye_open = document.querySelector("#eye_open");
    const eye_closed = document.querySelector("#eye_closed");
    showPassword ? setShowPassword(false) : setShowPassword(true);

    if (showPassword) {
      inp_password.setAttribute("TYPE", "password");
      eye_open.classList.add("hidden");
      eye_closed.classList.remove("hidden");
    } else {
      inp_password.setAttribute("TYPE", "Text");
      eye_closed.classList.add("hidden");
      eye_open.classList.remove("hidden");
    }
  }

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    try {
      await yup.reach(loginSchema, name).validate(value);
      setLoginErrors({ ...loginErrors, [name]: null });
    } catch (error) {
      setLoginErrors({ ...loginErrors, [name]: error.message });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await loginSchema.validate(formValues, { abortEarly: false });
      dispatch(userLogin(formValues));
    } catch (error) {
      const validationErrors = {};

      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setLoginErrors(validationErrors);
    }
  };

  // useEffect(() => {
  //   setLoginErrors(loginError);
  // }, [loginError]);
  // useEffect(() => {
  //   dispatch(addOrder({name:'fazmir'}))
  // }, []);

  return (
    <div className="flex flex-col justify-around min-h-screen px-10 select-none">
      <div>
        <h2 className="text-main text-4xl font-normal">Life Water</h2>
        <h2 className="text-main text-4xl font-semibold">VanSale</h2>
        <span className="text-mud">Delivery Everywhere</span>
      </div>

      <div className="max-w-full">
        <form onSubmit={handleSubmit}>
          <h1 className="text-mud mb-6">Login with your credentials</h1>
          <label className="text-red-350">{loginErrors?.email}</label>
          <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.email}
            name={"email"}
            placeholder={"Email or Phone Number"}
            styles={`w-full px-3 py-2 mb-2 bg-mud border shadow-sm  ${
              loginErrors?.email
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-rey  block rounded-md sm:text-sm focus:ring-1`}
          />
          <label className="text-red-350">{loginErrors?.password}</label>

          <div className="relative">
            <div className="absolute end-5 top-2">
              <svg
                id="eye_open"
                onClick={showMePassword}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer hidden"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <svg
                id="eye_closed"
                onClick={showMePassword}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            </div>
            <Input
              id={"inp_password"}
              type={"password"}
              onChange={handleInputChange}
              value={formValues.password}
              name={"password"}
              placeholder={"Enter Your Password"}
              styles={`w-full px-3 py-2  bg-mud border shadow-sm ${
                loginErrors?.password
                  ? "border-red-500 focus:outline-none   focus:border-red-500 focus:ring-red-500"
                  : "border-slate-300 focus:outline-none  focus:border-sky-500 focus:ring-sky-500"
              } placeholder-rey  block rounded-md sm:text-sm focus:ring-1`}
            />
          </div>
          {loginError ? (
            <label className="text-red-350 ">
              password or username is invalid
            </label>
          ) : (
            ""
          )}

          <Link
            className="text-red-350 block w-full text-end text-xs font-medium mt-2"
            to="/forgot-password"
          >
            Forgot Password?
          </Link>
          <button className="bg-main text-white font-medium py-2 px-4 rounded-md mt-6 w-full">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginSection;
