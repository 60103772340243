import CustomerDashboard from "../../components/customerDashboard/CustomerDashboard";

const Customer = () => {
  return (
    <>
      <CustomerDashboard />
    </>
  );
};

export default Customer;
