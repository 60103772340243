/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as yup from "yup";
import Input from "../input/Input";
import Select from "../select/Select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { addorder } from "../../redux/ducks/orderSlice";
// import { BsPersonAdd } from "react-icons/bs";
import { AiFillCaretDown } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/en-US";
import DatePicker from "react-datepicker";
import { getCustomer } from "../../redux/ducks/CustomerSlice";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import AutocompleteDropdown from "../selectBySearch/SearchBySelect";
import SelectCustom from "../select/SelectCustom";
import {
  addOrder,
  setOrderFail,
  setOrderLoading,
  setOrderSuccess,
} from "../../redux/ducks/OrderSlice";
import ReusableModal from "../modal/Modal";
import SendLoading from "../../layouts/Loading";
import { ToastContainer, toast } from "react-toastify";
import { getRide } from "../../redux/ducks/RideSlice";
import { getSubRouteRequest } from "../../redux/sagas/requests/RideRequest";
import CustomInput from "../customInput/CustomInput";
import { getRouteStockRequest } from "../../redux/sagas/requests/StockRequest";
import CustomTextArea from "../textArea/CustomTextArea";
import CustomSelect from "../select/CustomSelect";
import { getCustomerSingleDetailService } from "../../redux/sagas/requests/CustomerRequest";

registerLocale("es", es);

const selectMode = [{ name: "cash" }, { name: "credit" }, { name: "foc" }];
const item = [
  { name: "5 Gallon Bottle" },
  { name: "Water Dispenser", rate: 8 },
  { name: "Table Top", rate: 10 },
  { name: "Water Pumb", rate: 12 },
];

const initialValues = {
  orderNo: "1",
  item: "",
  customerId: "",
  mainRoute: "",
  subRoute: "",
  returnBottle: "",
  total: 0,
  paymentMethod: "",
  quantity: 1,
  rate: "",
  remarks: "",
  date: new Date().toISOString().slice(0, 10),
};

const AddOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [SubRoutes, setSubRoutes] = useState([]);
  const [load, setLoad] = useState(false);

  const { token } = JSON.parse(localStorage.getItem("user"));
  const rideId = useSelector((state) => state.ride._id);
  const {
    mainRoute,
    subRoute,
    OrderNo,
    openingStock,
    loadedStock,
    soldBottle,
  } = useSelector((state) => state.ride);
  const { customers } = useSelector((state) => state.customers);
  const { orderLoading, orderSuccess, orderFail } = useSelector(
    (state) => state.order
  );
  const [cusName, setCusName] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [count, setCount] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [limit, setLimit] = useState(999);
  const [customerDetail, setCustomerDetail] = useState("");
  const [maxRB, setMaxRB] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [mode, setMode] = useState(selectMode);
  const [stockItems, setStockItems] = useState([]);
  const [itemLimit, setItemLimit] = useState(999);
  const [couponLimit, setCouponLimit] = useState(999);
  const [itemRate, setItemRate] = useState(0);

  const [filteredCustomers, setFilteredCustomers] = useState([]);

  const [warningColor, setWarningColr] = useState(false);

  const orderSchema = yup.object().shape({
    orderNo: yup.string().required("Name is required"),

    item: yup.string().required("Item is required"),
    customerId: yup.string().required("Name is required"),
    quantity: yup
      .string()
      .required("Quantity is required")
      .test("Invalid quantity", (value) => {
        const bot = parseInt(value);
        return bot > 0;
      }),
    returnBottle: yup
      .string()
      .required("No of return bottles is required")
      .test("invalid number", (value) => {
        const bot = parseInt(value);
        return bot >= 0 && bot <= parseInt(maxRB);
      }),
    paymentMethod: yup.string().required("Payment mode is required"),
    rate: yup.string().required("Rate is required"),
    total: yup.string().required("Total is required"),
    date: yup.string().required("Date is required"),
    remarks: yup.string(),
  });
  useEffect(() => {
    dispatch(getCustomer({ token, rideId }));
  }, []);
  useEffect(() => {
    if (customers) {
      setFilteredCustomers(customers);
    }
  }, [customers]);

  const handleIncrement = () => {
    const name = "quantity";
    if (limit > count) {
      setCount((prevCount) => prevCount + 1);
      setFormValues({ ...formValues, [name]: count + 1 });
    }
  };
  useEffect(() => {
    if (customers) {
      setCusName(
        customers.map((item) => {
          return { name: item.name, value: item._id };
        })
      );
    }
  }, [customers]);

  useEffect(() => {
    const fetchdata = async () => {
      const { data } = await getSubRouteRequest();
      setSubRoutes(data);
    };
    fetchdata();
  }, []);

  useEffect(() => {
    if (orderSuccess) {
      toast.success(" order created successfully!", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          navigate(-1);
          // This code will execute after the toast is closed
        },
      });
    }
    return () => {
      dispatch(setOrderSuccess(false));
    };
  }, [orderSuccess]);
  useEffect(() => {
    if (orderFail) {
      toast.error(" something went wrong!", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // onClose: () => {
        //   navigate(-1);
        //   // This code will execute after the toast is closed
        // },
      });
    }
    return () => {
      dispatch(setOrderFail(false));
    };
  }, [orderFail]);

  const handleDecrement = () => {
    const name = "quantity";

    setCount((prevCount) => Math.max(prevCount - 1, 1));
    if (count > 1) {
      setFormValues({ ...formValues, [name]: count - 1 });
    }
  };
  //   useEffect(()=>{
  // setFormValues({...formValues ,mainRoute:mainRoute,subRoute:subRoute})
  //   },[mainRoute,subRoute])
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRouteStockRequest(token, mainRoute);
      setStockItems(data);
    };
    fetchData();
  }, []);
  useEffect(() => {
    dispatch(getRide({ token }));
  }, []);
  useEffect(() => {
    if (formValues?.rate > 0) {
      const rate = parseFloat(formValues.rate);
      const totalCount = rate * count;
      const name = "total";
      setFormValues({ ...formValues, [name]: totalCount });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      const name = "total";

      setFormValues({ ...formValues, [name]: 0 });
    }
  }, [formValues.rate, count]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });

    try {
      await yup.reach(orderSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };

  const handlePaymentChange = async (e) => {
    const { name, value } = e.target;

    if (value === "foc") {
      setFormValues({ ...formValues, [name]: value, rate: 0 });
    } else {
      setFormValues({ ...formValues, [name]: value, rate: itemRate });
    }
    try {
      await yup.reach(orderSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };
  const handleRemarksChange = async (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });

    try {
      await yup.reach(orderSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await orderSchema.validate(formValues, { abortEarly: false });
      setIsModalOpen(true);
      // navigate("/orders");
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };

  const sendData = () => {
    dispatch(setOrderLoading(true));
    setIsModalOpen(false);

    dispatch(addOrder({ formValues, token, rideId, mainRoute, subRoute }));
  };

  const cancel = () => {
    setIsModalOpen(false);
  };
  // const CustomerSelect = async (e) => {
  //   const { name, value } = e.target;
  //   if (value) {
  //     const customerByName = customers.find(
  //       (customer) => customer._id === value
  //     );
  //     console.log(customerByName);
  //     setCustomerDetail(customerByName);
  //     setCount(1);
  //     if (customerByName.membership && customerByName.FOC > 0) {
  //       setLimit(customerByName.FOC);
  //       setFormValues({
  //         ...formValues,
  //         [name]: value,
  //         paymentMethod: 'coupon',
  //         rate: 0,
  //         returnBottle: customerByName.bottlesInHand,
  //         item: "5 Gallon Bottle",
  //         quantity: customerByName.numberOfBottles,
  //       });
  //       setCount(customerByName.numberOfBottles);
  //       setMaxRB(customerByName.bottlesInHand);
  //     } else {
  //       setLimit(999);
  //       setFormValues({
  //         ...formValues,
  //         [name]: value,
  //         paymentMethod: customerByName.paymentMode,
  //         rate: customerByName.amount,
  //         returnBottle: parseInt(customerByName.bottlesInHand),
  //         item: "5 Gallon Bottle",
  //         quantity: customerByName.numberOfBottles,
  //       });
  //       setMaxRB(customerByName.bottlesInHand);
  //       setCount(customerByName.numberOfBottles);
  //     }
  //   }
  // };
  const onSelect = async (value) => {
    if (value) {
      setLoad(true);
      const { data } = await getCustomerSingleDetailService(token, value._id);
      setLoad(false);

      console.log(data, "fsdf");
      const customerByName = data;

      setCustomerDetail(data);
      setCount(1);
      const stockitem = stockItems?.find(
        (obj) => obj.name === "5 Gallon Bottle"
      );
      if (!stockitem) {
        setItemLimit(0);
        setCount(0);
        alert("Stock not found");
        setFormValues({
          ...formValues,
          customerId: customerByName._id,
          paymentMethod: customerByName.paymentMode,
          rate:
            customerByName.paymentMode === "foc" ? 0 : customerByName.amount,
          returnBottle: parseInt(customerByName.bottlesInHand),
          item: "5 Gallon Bottle",
          quantity: 0,
        });

        return;
      }

      if (customerByName?.membership && customerByName.FOC > 0) {
        setLimit(customerByName.FOC);
        setItemLimit(stockitem?.quantity);
        setCouponLimit(customerByName.FOC);
        setFormValues({
          ...formValues,
          customerId: customerByName._id,
          paymentMethod: "coupon",
          rate: 0,
          returnBottle: customerByName.bottlesInHand,
          item: "5 Gallon Bottle",
          quantity: calculateFOCQuantity(
            customerByName.FOC,
            stockitem.quantity,
            customerByName?.numberOfBottles
          ),
        });
        setFormErrors({
          ...formErrors,
          customerId: null,
          paymentMethod: null,
          rate: null,
          returnBottle: null,
          item: null,
          quantity: null,
        });
        setCount(
          calculateFOCQuantity(
            customerByName.FOC,
            stockitem.quantity,
            customerByName?.numberOfBottles
          )
        );
        setLimit(
          calculateFOCStockLimit(customerByName.FOC, stockitem.quantity)
        );
        setMaxRB(customerByName.bottlesInHand);
      } else {
        setLimit(stockitem.quantity);
        setItemLimit(stockitem.quantity);
        setCouponLimit(0);
        setFormValues({
          ...formValues,
          customerId: customerByName._id,
          paymentMethod: customerByName.paymentMode,
          rate:
            customerByName.paymentMode === "foc" ? 0 : customerByName.amount,
          returnBottle: parseInt(customerByName.bottlesInHand),
          item: "5 Gallon Bottle",
          quantity: calQuantity(
            stockitem.quantity,
            customerByName.numberOfBottles
          ),
        });
        setFormErrors({
          ...formErrors,
          customerId: null,
          paymentMethod: null,
          rate: null,
          returnBottle: null,
          item: null,
          quantity: null,
        });
        setMaxRB(customerByName.bottlesInHand);
        setCount(
          calQuantity(stockitem.quantity, customerByName.numberOfBottles)
        );
      }
      if (customerByName?.membership && customerByName.FOC == 0) {
        alert("Membership Renewal");
        setWarningColr(true);
      } else {
        setWarningColr(false);
      }
    }
  };
  const SelectItem = async (e) => {
    const { name, value } = e.target;
    if (!value) {
      return;
    }
    const stockitem = stockItems?.find((obj) => obj?.name === value);
    if (!stockitem) {
      setItemLimit(0);
      setCount(0);
      alert("Stock not found");
      setFormValues({
        ...formValues,
        rate: 0,
        returnBottle: 0,
        quantity: 0,
        item: value,
        paymentMethod: customerDetail?.paymentMode,
      });
      return;
    }
    alert(`${stockitem.name} stock limit is ${stockitem.quantity}`);
    setItemLimit(stockitem.quantity);

    if (value !== "5 Gallon Bottle") {
      setLimit(stockitem.quantity);
      const singleItem = item.find((obj) => obj.name === value);
      setItemRate(stockitem?.price);
      setFormValues({
        ...formValues,
        [name]: value,
        rate: customerDetail.paymentMode === "foc" ? 0 : stockitem?.price,
        returnBottle: 0,
        quantity: stockitem.quantity === 0 ? 0 : 1,
        paymentMethod: customerDetail?.paymentMode,
      });
      stockitem.quantity === 0 ? setCount(0) : setCount(1);

      setMaxRB(0);
      setFormErrors({
        ...formErrors,
        customerId: null,
        paymentMethod: null,
        rate: null,
        returnBottle: null,
        item: null,
        quantity: null,
      });
    } else {
      if (customerDetail) {
        setFormValues({
          ...formValues,
          [name]: value,
          rate:
            customerDetail.paymentMode === "foc"
              ? 0
              : customerDetail.FOC > 0
              ? 0
              : customerDetail.amount,

          returnBottle: customerDetail.bottlesInHand,
          quantity:
            customerDetail.FOC > 0
              ? calculateFOCQuantity(
                  customerDetail.FOC,
                  stockitem.quantity,
                  customerDetail?.numberOfBottles
                )
              : calQuantity(
                  stockitem.quantity,
                  customerDetail?.numberOfBottles
                ),

          paymentMethod:
            customerDetail.FOC > 0 ? "coupon" : customerDetail.paymentMode,
        });
        setMaxRB(customerDetail.bottlesInHand);
        setFormErrors({
          ...formErrors,
          customerId: null,
          paymentMethod: null,
          rate: null,
          returnBottle: null,
          item: null,
          quantity: null,
        });
        const count = customerDetail?.numberOfBottles || 1;

        if (customerDetail.FOC > 0) {
          setCouponLimit(customerDetail.FOC);
          setCount(
            calculateFOCQuantity(
              customerDetail.FOC,
              stockitem.quantity,
              customerDetail?.numberOfBottles
            )
          );
          setLimit(
            calculateFOCStockLimit(customerDetail.FOC, stockitem.quantity)
          );
        } else {
          setCouponLimit(0);

          setCount(
            calQuantity(stockitem.quantity, customerDetail?.numberOfBottles)
          );
          setLimit(stockitem.quantity);
        }
      }
    }
  };
  console.log(customerDetail, "hi");
  const stocklimit = () => {
    navigate("/");
  };
  // const calculateFOCQuantity = (foc, sl, nob) => {
  //   console.log(foc,sl,nob);
  //   if (foc > nob) {
  //     if (nob > sl) {
  //       return sl;
  //     }
  //     return nob;
  //   }
  //   if (foc < nob) {
  //     if (foc > sl) {
  //       return sl;
  //     }
  //     return foc;
  //   }
  //   return foc
  // };
  const calculateFOCQuantity = (foc, sl, nob) => {
    return Math.min(foc, nob, sl);
  };
  // const calQuantity = (sl, nob) => {
  //   if (sl < nob) {
  //     return sl;
  //   }
  //   return nob;
  // };
  const calQuantity = (sl, nob) => Math.min(sl, nob);

  // const calculateFOCStockLimit = (foc, sl) => {
  //   if (sl < foc) {
  //     return sl;
  //   }
  //   return foc;
  // }
  const calculateFOCStockLimit = (foc, sl) => Math.min(sl, foc);

  const handlefilterbySubRoute = async (e) => {
    const value = e.target.value;
    if (!value) {
      setFilteredCustomers(customers);
      return;
    }
    const filtereddata = customers.filter((obj) => obj.subRoute == value);
    setFilteredCustomers(filtereddata);
  };
  console.log(formValues);
  const loadsmall = (
    <div className="fixed top-0 left-0 right-0 z-50 p-10 overflow-hidden h-screen w-screen flex items-center justify-center bg-transparent">
      <div className="flex flex-row items-center max-w-md bg-transparent rounded shadow">
        <div className="text-center bg-transparent">
          <div role="status">
            <svg
              aria-hidden="true"
              className="animate-spin w-8 h-8 text-gray-200 dark:text-gray-600 fill-sub"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className=" flex flex-col gap-4  py-4 px-8 select-none ">
      <div className="flex flex-col justify-center items-center p-3 bg-white rounded-lg">
        <div className="flex w-full justify-center">
          <div
            className="flex flex-col justify-center items-center w-full px-5 hover:rounded-lg border-r border-[#B3E5AA]  hover:bg-sub"
            // onClick={() => {
            //   navigate("/cash-sales");
            // }}
          >
            {/* <h1 className="text-main text-md font-medium py-0.5">
              hi
            </h1> */}
            <h1
              className={`${
                formErrors.quantity ? "text-red-500" : "text-main"
              } text-md font-normal  py-0.5`}
            >
              Stock Limit
            </h1>

            <h1
              className={`${
                formErrors.quantity ? "text-red-500" : "text-main"
              } text-md font-medium py-0.5`}
            >
              {itemLimit || 0}
            </h1>
          </div>
          <div
            className="flex flex-col justify-center items-center w-full px-5 hover:rounded-lg  hover:bg-sub"
            // onClick={() => {
            //   navigate("/credit-sales");
            // }}
          >
            {/* <h1 className="text-main text-md font-medium py-0.5">
              hi
            </h1> */}
            <h1 className="text-main text-md font-normal py-0.5">
              Coupon Limit
            </h1>
            <h1
              className={` ${
                customerDetail?.FOC === 0 && customerDetail.membership
                  ? "text-red-500"
                  : "text-main"
              } text-md font-medium py-0.5`}
            >
              {couponLimit}{" "}
              <span className="text-red-500 text-xs font-medium ">
                {customerDetail?.FOC === 0 && customerDetail.membership
                  ? "Renew"
                  : ""}
              </span>
            </h1>
          </div>
        </div>
        {/* <h1 className="text-main text-md font-medium pt-2">
          Total Sales Amount :
        </h1>
        <h1 className="text-main text-md font-medium py-2">AED</h1> */}
      </div>
      <div className="bg-white p-5 h-full rounded-md ">
        <form onSubmit={handleSubmit}>
          {/* <div className="flex flex-col  items-end ">
            <div className="flex flex-col w-full   ">
              <Input
                type={"date"}
                value={formValues.date}
                name={"date"}
                placeholder={"DD-MM-YYY"}
                onChange={handleInputChange}
                styles={`w-full px-3 py-0.5 mb-3 bg-white border  shadow-sm  ${
                  formErrors?.date
                    ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                    : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
                // readOnly={true}
              />
            </div>
          </div> */}
          <CustomInput
            type={"date"}
            value={new Date().toISOString().slice(0, 10)}
            label={"Date"}
            readOnly={true}
            bgColor={"white"}
          ></CustomInput>

          <div className="flex w-full justify-between items-center space-x-2 ">
            <div className=" flex flex-col w-[60vw] sm:w-[50vw]">
              <label className="text-red-350">{formErrors?.orderNo}</label>
              <div className="flex flex-row  ">
                <div className="w-full px-3 py-0.5 mb-3 W-3/4 bg-white border  shadow-sm border-sub border-r-black border-r-blackfocus:outline-none focus:border-sky-500 focus:ring-sky-500  block rounded-l-md sm:text-sm focus:ring-1">
                  <p style={{ width: "70px" }}>Order No</p>
                </div>
                <div className="divide-x divide-black W-1/4">
                  <Input
                    type={"number"}
                    value={OrderNo + 1}
                    name={"orderNo"}
                    placeholder={"100"}
                    styles={`w-full text-center py-0.5 mb-3 bg-white border  shadow-sm ${
                      formErrors?.orderNo
                        ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                        : "border-sub border-l-black focus:outline-none focus:border-sky-500  focus:ring-sky-500"
                    } placeholder-mud block rounded-r-md sm:text-sm focus:ring-1 ${
                      window.innerWidth >= 440 ? "sm:text-center" : ""
                    }`}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="flex flow-col  w-[40vw] sm:w-[50vw] ">
              <CustomSelect
                name={"subRoute"}
                options={SubRoutes}
                // label={formErrors?.subRoute}
                // value={formValues.subRoute}
                onChange={handlefilterbySubRoute}
                labelStyles={"text-red-350 text-sm"}
                defaultValue={{ name: "Filter" }}
                parentStyles={"relative inline-block w-full"}
                styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white  border shadow-sm
            ${
              formErrors?.subRoute
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
              />
            </div>
          </div>

          <div className="flex w-full justify-between items-center ">
            {/* <div className="flex flex-col w-3/4">
              <Select
                name={"customerId"}
                options={cusName}
                label={formErrors?.customerId}
                defaultValue={{ name: "Select Customer" }}
                value={formValues.customerId}
                onChange={CustomerSelect}
                labelStyles={"text-red-350 text-sm"}
                selected={{ name: "Select a customername" }}
                parentStyles={"relative inline-block w-full"}
                styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.customerId
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
              />
            </div> */}
            <div className="flex flex-col w-3/4 mb-3">
              <label className="text-red-350">{formErrors?.customerId}</label>

              <div className="relative">
                <AutocompleteDropdown
                  customers={filteredCustomers}
                  onSelect={onSelect}
                  label={"Search for a customer"}
                  bgWhite={"white"}
                ></AutocompleteDropdown>
              </div>
            </div>
            <div
              className={`w-1/4 flex justify-center ${
                warningColor ? "border-red-500" : ""
              } mb-3`}
            >
              <div
                className="  rounded"
                onClick={() => {
                  navigate("/customers/add-customer");
                }}
              >
                <img src="/personicon.png" className="w-12" alt="" />
              </div>
            </div>
          </div>

          <div className="flex w-full justify-between   ">
            <div className="flex flex-col w-3/4">
              {/* <Select
                name={"item"}
                options={item}
                label={formErrors?.item}
                onChange={SelectItem}
                value={formValues.item}
                labelStyles={"text-red-350 text-sm"}
                defaultValue={{ name: "Select a item" }}
                parentStyles={"relative inline-block w-full"}
                styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white text-mud border shadow-sm
            ${
              formErrors?.item
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
              /> */}
              <SelectCustom
                name={"item"}
                options={item}
                label={"Select an item"}
                onChange={SelectItem}
                value={formValues.item}
                error={formErrors.item}
                errorMessage={formErrors.item}
                bgColor={"white"}
              ></SelectCustom>
            </div>
            <div className="flex items-start mt-1.5 w-1/4 justify-center  ">
              <div
                className="rounded-full cursor-pointer"
                onClick={handleDecrement}
              >
                <AiFillMinusCircle size={16} color="green" />
              </div>
              {/* <div className="w-9 border-white mt-3  text-center  text-xs">

              <Input
               type={"number"}
               onChange={handleInputChange}
               value={formValues.quantity.toString().padStart(3, "0")}
               name={"quantity"}
               placeholder={"count"}
               styles={`w-full h-full px-3 py-0.5 mb-3 bg-white border text-mud shadow-sm  ${
                 formErrors?.quantity
                   ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                   : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
               } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
              ></Input>
              </div> */}
              <div className="w-9 h-5  border border-green-500 text-center  text-xs">
                {count.toString().padStart(3, "0")}
              </div>
              <div
                className="rounded-full cursor-pointer"
                onClick={handleIncrement}
              >
                <AiFillPlusCircle size={16} color="green" />
              </div>
            </div>
          </div>

          {formValues.item === "5 Gallon Bottle" ? (
            <CustomInput
              type={"text"}
              onChange={handlePaymentChange}
              value={formValues.paymentMethod}
              name={"paymentMethod"}
              label={"Payment Mode"}
              error={formErrors.paymentMethod}
              errorMessage={formErrors.paymentMethod}
              readOnly={true}
            ></CustomInput>
          ) : (
            <SelectCustom
              name={"paymentMethod"}
              options={mode}
              label={"Paymnet Mode"}
              onChange={handlePaymentChange}
              value={formValues.paymentMethod}
              error={formErrors.paymentMethod}
              errorMessage={formErrors.paymentMethod}
              bgColor={"white"}
            ></SelectCustom>
          )}

          {/* <div className="flex flex-row justify-between  w-full">
            <label
              className={
                formErrors?.rate
                  ? "text-red-350 mb-3 ml-2"
                  : "text-mud mb-3 ml-2"
              }
            >
              Rate
            </label>

            <Input
              type={"number"}
              onChange={handleInputChange}
              value={formValues.rate}
              name={"rate"}
              placeholder={"Rate"}
              styles={`w-3/4 px-3 py-0.5 mb-3 bg-white border text-mud shadow-sm  ${
                formErrors?.rate
                  ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                  : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
              readOnly={true}
            />
          </div> */}
          {/* <div className="flex flex-col  w-3/4">
            <Select
              name={"paymentMethod"}
              options={selectMode}
              value={formValues.paymentMethod}
              label={formErrors?.paymentMethod}
              onChange={handleInputChange}
              labelStyles={"text-red-350 text-sm"}
              defaultValue={{ name: "Mode" }}
              placeholder={"SELECT A PAYMENT METHOD"}
              parentStyles={"relative inline-block w-full"}
              styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white text-mud border shadow-sm
            ${
              formErrors?.paymentMethod
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            />
          </div> */}
          {/* <div className="flex flex-row  justify-between  w-full">
            <label
              className={
                formErrors?.paymentMethod
                  ? "text-red-350 mb-3 ml-2"
                  : "text-mud mb-3 ml-2"
              }
            >
              Mode
            </label>

            <Input
              type={"string"}
              value={formValues.paymentMethod}
              name={"paymentMethod"}
              placeholder={"Payment Mode"}
              styles={`w-3/4 px-3 py-0.5 mb-3 bg-white border text-mud shadow-sm  ${
                formErrors?.paymentMethod
                  ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                  : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
              readOnly={true}
            />
          </div> */}
          <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.rate}
            name={"rate"}
            label={"Rate per item"}
            error={formErrors.rate}
            errorMessage={formErrors.rate}
            readOnly={true}
          ></CustomInput>
          <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.returnBottle}
            name={"returnBottle"}
            label={"Return Empty Bottle"}
            error={formErrors.returnBottle}
            errorMessage={formErrors.returnBottle}
          ></CustomInput>
          {/* <div className="flex flex-row justify-between  w-full">
            <label
              className={
                formErrors?.returnBottle
                  ? "text-red-350 mb-3 ml-2"
                  : "text-mud mb-3 ml-2"
              }
            >
              Return
            </label>

            <Input
              type={"number"}
              onChange={handleInputChange}
              value={formValues.returnBottle}
              name={"returnBottle"}
              placeholder={"Return Empty Bottle"}
              styles={`w-3/4 px-3 py-0.5 mb-3 bg-white border text-mud shadow-sm  ${
                formErrors?.returnBottle
                  ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                  : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
            />
          </div> */}
          <CustomTextArea
            onChange={handleRemarksChange}
            value={formValues.Remarks}
            name={"remarks"}
            label={"Remarks"}
            error={formErrors.Remarks}
            errorMessage={formErrors.Remarks}
          ></CustomTextArea>

          <div className="flex justify-start w-full ">
            <div className="flex w-3/4">
              <div className=" px-3 py-0.5 mb-3 text-black   sm:text-sm focus:ring-1">
                Total
              </div>

              <Input
                type={"number"}
                value={formValues.total}
                name={"total"}
                placeholder={"Total"}
                styles={`w-full px-3 py-0.5 mb-3 bg-white border text-black shadow-sm  ${
                  formErrors?.total
                    ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                    : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
                readOnly={true}
              />
            </div>
          </div>
          <div className="flex justify-center mb-10">
            <button
              type="submit"
              className="bg-main text-white font-medium py-2 px-4 rounded-md mt-6 w-52"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* {openingStock + loadedStock - soldBottle <= 0 ? (
        <ReusableModal
          title={" Stock LIMIT "}
          message={"Your stock has been sold out"}
          onConfirm={stocklimit}
          onCancel={stocklimit}
          type={1}
        />
      ) : (
        ""
      )} */}
      {isModalOpen && (
        <ReusableModal
          title={"Order Confirm "}
          message={"are you sure?"}
          onConfirm={sendData}
          onCancel={cancel}
          type={1}
        />
      )}
      {orderLoading ? <SendLoading></SendLoading> : ""}
      <ToastContainer></ToastContainer>
      {load ? loadsmall : ""}
    </div>
  );
};

export default AddOrder;
