import React, { useState } from "react";
import Input from "../input/Input";
import Select from "../select/Select";
import TextArea from "../textArea/TextArea";
const ReusableModal = ({
  title,
  message,
  onConfirm,
  onCancel,
  type,
  data,
  formValues,
  formErrors,
  reasons,
  handleInputChange,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div>
      <div className="fixed top-0 left-0 right-0 z-50 px-6  overflow-x-hidden overflow-y-auto md:inset-0 h-screen w-screen flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="relative w-full max-w-md bg-white rounded-xl shadow dark:bg-gray-700">
          <button
            onClick={onCancel}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="py-6 text-center ">
            {type === 1 ? (
              <>
                <div>
                  <svg
                    className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    {message}
                  </h3>
                  <div className="flex justify-center items-center gap-2">
                    <button
                      onClick={handleConfirm}
                      className="text-white bg-main hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-sub dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-start  mr-2"
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      onClick={handleCancel}
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      No, cancel
                    </button>
                  </div>
                </div>
              </>
            ) : type === 2 ? (
              <>
                <div className="p-6 text-start ">
                  <div className="flex gap-4 mb-3 items-center">
                    <h1 className="whitespace-nowrap text-main w-2/5 text-start  text-md font-semibold">
                      Date
                    </h1>
                    <div className="w-3/5">
                      <label className="text-red-350 text-sm">
                        {/* {formErrors?.openingStock} */}
                      </label>
                      <Input
                        type={"string"}
                        name={"rate"}
                        value={data.date.slice(0, 10)}
                        styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                 border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500
                  
                  block rounded-md sm:text-sm focus:ring-1`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-3 items-center">
                    <h1 className="whitespace-nowrap text-main w-2/5 text-md font-medium">
                      Quantity
                    </h1>
                    <div className="w-3/5">
                      <label className="text-red-350 text-sm">
                        {/* {formErrors?.openingStock} */}
                      </label>
                      <Input
                        type={"number"}
                        name={"openingStock"}
                        value={data.quantity}
                        styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                 border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500
                  
                  block rounded-md sm:text-sm focus:ring-1`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-3 items-center">
                    <h1 className="whitespace-nowrap text-main w-2/5 text-start  text-md font-semibold">
                      Item
                    </h1>
                    <div className="w-3/5">
                      <label className="text-red-350 text-sm">
                        {/* {formErrors?.openingStock} */}
                      </label>
                      <Input
                        type={"text"}
                        name={"item"}
                        value={data.item}
                        styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                 border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500
                  
                  block rounded-md sm:text-sm focus:ring-1`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-3 items-center">
                    <h1 className="whitespace-nowrap text-main w-2/5 text-start  text-md font-semibold">
                      Rate
                    </h1>
                    <div className="w-3/5">
                      <label className="text-red-350 text-sm">
                        {/* {formErrors?.openingStock} */}
                      </label>
                      <Input
                        type={"number"}
                        name={"rate"}
                        value={data.rate}
                        styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                 border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500
                  
                  block rounded-md sm:text-sm focus:ring-1`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-3 items-center">
                    <h1 className="whitespace-nowrap text-main w-2/5 text-md font-semibold">
                      Total
                    </h1>
                    <div className="w-3/5">
                      <label className="text-red-350 text-sm">
                        {/* {formErrors?.openingStock} */}
                      </label>
                      <Input
                        type={"number"}
                        name={"openingStock"}
                        value={data.total}
                        styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                 border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-3 items-center">
                    <h1 className="whitespace-nowrap text-main w-2/5 text-md font-semibold">
                      Remaining
                    </h1>
                    <div className="w-3/5">
                      <label className="text-red-350 text-sm">
                        {/* {formErrors?.openingStock} */}
                      </label>
                      <Input
                        type={"number"}
                        name={"openingStock"}
                        value={data.paymentRemaining}
                        styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                 border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500 block rounded-md sm:text-sm focus:ring-1`}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col gap-1 pt-5 px-5 ">
                  <div>
                    <Select
                      name={"reason"}
                      defaultValue={{ name: "reason" }}
                      options={reasons}
                      label={formErrors?.reason}
                      onChange={handleInputChange}
                      labelStyles={"text-red-350 text-sm"}
                      parentStyles={"relative inline-block w-full"}
                      styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
                    ${
                      formErrors?.reason
                        ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                        : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                    } block rounded-md sm:text-sm focus:ring-1`}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="text-red-350">
                      {formErrors?.remarks}
                    </label>

                    <TextArea
                      onChange={handleInputChange}
                      value={formValues.remarks}
                      name={"remarks"}
                      placeholder={"Remarks"}
                      style={`w-full px-3 py-0.5 h-14 mb-3 bg-mud border shadow-sm  ${
                        formErrors?.remarks
                          ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                          : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                      } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
                    />
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      onClick={handleConfirm}
                      className="bg-main text-white font-medium py-2 px-4 rounded-md mt-2 w-[12rem]"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReusableModal;
