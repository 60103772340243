/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import Select from "../select/Select";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { getCustomer } from "../../redux/ducks/CustomerSlice";
import DatePicker from "react-datepicker";
import { AiFillCaretDown } from "react-icons/ai";
import {
  addMembership,
  setMCSendLoading,
  setMCAddSuccess,
} from "../../redux/ducks/MembershipSlice";
import Input from "../input/Input";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import SendLoading from "../../layouts/Loading";
import { ToastContainer, toast } from "react-toastify";
import AutocompleteDropdown from "../selectBySearch/SearchBySelect";
import CustomInput from "../customInput/CustomInput";
import { getCouponsRequest } from "../../redux/sagas/requests/CouponRequest";

// Register the 'en-US' locale
registerLocale("en-US", enUS);

const initialValues = {
  couponId: "",
  customerId: "",
  amount: "",
};

const memberSchema = yup.object().shape({
  customerId: yup.string().required("customerName is required"),
  couponId: yup.string().required("coupon is required"),
});
// const coupons = [
//   {
//     value: 1,
//     name: "10 waterbottles + 2",
//     total: 12,
//     quantity:12,

//     amount: 100,
//   },
//   {
//     value: 2,
//     name: "20 waterbottles + 5",
//     total: 25,
//     quantity:25,

//     amount: 200,
//   },
//   {
//     value: 3,
//     name: "30 waterbottles + 10",
//     total: 40,
//     quantity:40,
//     amount: 300,
//   },
//   // Add more coupon objects here...
// ];

const CouponSale = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());

  const { token } = JSON.parse(localStorage.getItem("user"));
  const [show, setShow] = useState(false);
  const { customers } = useSelector((state) => state.customers);
  const [cusName, setCusName] = useState([]);
  const { mcSendLoading, mcAddSuccess } = useSelector(
    (state) => state.membership
  );
  const [coupon, setCoupon] = useState();
  const [coupons, setCoupons] = useState([]);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const rideId = useSelector((state) => state.ride._id);

  useEffect(() => {
    dispatch(getCustomer({ token, rideId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    const fetchData = async ()=>{
      const {data} = await getCouponsRequest(token)
      setCoupons(data)

    }
    fetchData()
  },[])
  useEffect(() => {
    if (mcAddSuccess) {
      toast.success(" memebership created successfully!", {
        position: "bottom-center",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          // This code will execute after the toast is closed
          navigate(-1); // Navigate back to the previous page
        },
      });
    }
    return () => {
      dispatch(setMCAddSuccess(false));
    };
  }, [mcAddSuccess]);
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
   
    if (name === "couponId") {
      const coupon = coupons.find((obj) => obj.value == value);
      if (coupon) {
        setFormValues({
          ...formValues,
          [name]: value,
          amount: coupon?.amount || 0,
          quantity: coupon?.quantity,
        });
      }
      setCoupon(coupon)
    } 
    try {
      await yup.reach(memberSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null ,amount:null,quantity:null});
    } catch (error) {
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await memberSchema.validate(formValues, { abortEarly: false });
      dispatch(setMCSendLoading(true));
      dispatch(addMembership({ formValues, token }));
    } catch (error) {
      const validationErrors = {};

      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };

  const cancel = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   if (customers) {
  //     setCusName(
  //       customers.map((item) => {
  //         return { name: item.name, value: item._id };
  //       })
  //     );
  //   }
  // }, [customers]);
const onSelect =async(value)=>{
  setFormValues({ ...formValues, customerId: value._id });
setFormErrors({ ...formErrors, customerId: null })

}
  return (
    <div className="flex flex-col min-h-base px-8 py-8 select-none">
      <div className="bg-white p-4 pt-8   rounded-md ">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col   rounded-md ">
            {/* <Select
              name={"customerId"}
              options={cusName}
              label={formErrors?.customerId}
              onChange={handleInputChange}
              labelStyles={"text-red-350 text-sm"}
              defaultValue={{ name: "customerName" }}
              parentStyles={"relative inline-block w-full"}
              styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white text-black border shadow-sm
            ${
              formErrors?.customerId
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            /> */}
            <label className="text-red-350">{formErrors?.customerId}</label>

            <div className="relative mb-3">
              <AutocompleteDropdown
              label={'Search for a customer'}
                customers={customers}
                onSelect={onSelect}
                bgWhite={true}
              ></AutocompleteDropdown>
            </div>
            <Select
              options={coupons}
              name={"couponId"}
              onChange={handleInputChange}
              label={formErrors?.couponId}
              labelStyles={"text-red-350 text-sm"}
              defaultValue={{ name: "coupon" }}
              parentStyles={"relative inline-block w-full"}
              styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white text-black border shadow-sm
            ${
              formErrors?.couponId
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            />
            <div className="flex flex-row justify-between ">
              <div className="px-5 py-1">
                <h2 className="text-black text-md   tracking-wide">Valid from</h2>
              </div>
              <div className="w-1/2 flex items-center justify-center sm:justify-end ">
                <div className="relative justify-end">
                  <DatePicker
                    locale="en-US"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="w-full sm:w-[45vw] py-0.5 px-2 mb-3 bg-white border shadow-sm border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder-mud text-black block rounded-md sm:text-sm focus:ring-1"
                    minDate={new Date()} // Disable all dates before today
                    maxDate={new Date()} // Disable all dates after today
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 bottom-2 flex items-center px-2 text-gray-700">
                    <AiFillCaretDown />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-between ">
              <div className="px-5 py-1 ">
                <h2 className="text-black text-md font-normal  tracking-wide">
                  Valid to
                </h2>
              </div>
              <div className="w-1/2 flex items-center justify-center sm:justify-end">
                <div className="relative justify-end">
                  <DatePicker
                    locale="en-US"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="w-full sm:w-[45vw] py-0.5 mb-3 px-2 bg-white border shadow-sm border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder-mud text-black block rounded-md sm:text-sm focus:ring-1"
                    minDate={new Date()} // Disable all dates before today
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 bottom-2 flex items-center px-2 text-gray-700">
                    <AiFillCaretDown />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Input
            type={"number"}
            name={"amount"}
            value={formValues.amount}
            placeholder={"Amount"}
            styles={`inline w-full px-3 py-0.5 bg-white border shadow-sm 
                  
                  ${
                    formErrors?.amount
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
            readOnly={true}
          /> */}
          <CustomInput
            type={"number"}
            // onChange={handleInputChange}
            value={coupon?.quantity}
            name={"Quantity"}
            label={"Quantity"}
            error={formErrors?.quantity}
            errorMessage={formErrors?.quantity}
            readOnly={true}
          ></CustomInput>
           <CustomInput
            type={"number"}
            // onChange={handleInputChange}
            value={formValues.amount}
            name={"amount"}
            label={"Coupon Amount"}
            error={formErrors?.amount}
            errorMessage={formErrors?.amount}
            readOnly={true}
          ></CustomInput>
          <div className="flex justify-evenly mb-10">
            <button
              type="submit"
              className="bg-main text-white font-medium py-1 px-4 rounded-md mt-6 w-20"
            >
              Submit
            </button>
            <button
              className="bg-main text-white font-medium py-1 px-4 rounded-md mt-6 w-20"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              cancel
            </button>
          </div>
        </form>
      </div>
      {mcSendLoading ? <SendLoading></SendLoading> : ""}
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default CouponSale;
