import { call, put } from "redux-saga/effects";
import { userLoginRequest } from "../requests/UserRequest";
import { setLoginError, setUser } from "../../ducks/UserSlice";

export function* handleUserLogin(action) {
  console.log(action);
  try {
    const {
      data: { email, token },
    } = yield call(userLoginRequest, action);

    localStorage.setItem("user", JSON.stringify({ email, token }));

    yield put(setLoginError(null));
    yield put(setUser(email));
  } catch (error) {
    const {
      response: { data },
    } = error;

    console.log(data);
    yield put(setLoginError(data));
  }
}
