import CustomerOrderHistory from "../../components/customerOrderHistory/CustomerOrderHistory";

const CustomerOrderHistoryPage = () => {
  return (
    <>
      <CustomerOrderHistory />
    </>
  );
};

export default CustomerOrderHistoryPage;
