import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login/Login";
import StartRideForm from "./pages/startRideForm/StartRideForm";
import Home from "./pages/home/Home";
import Customer from "./pages/customer/Customer";
import { useSelector } from "react-redux";
import PrivateRoute from "./utils/PrivateRoute";
import StockProtectedRoute from "./utils/StockProtectedRoute";
import AllCustomers from "./components/allCustmers/AllCustomers";
import AddCustomer from "./components/addCustomer/AddCustomer";
import CustomerProfile from "./components/customerProfile/CustomerProfile";
import Order from "./pages/order/Order";
import ViewSummary from "./components/viewSummary/ViewSummary";
import AddOrder from "./components/addOrder/addOrder";
import ReturnFormSection from "./components/return/Return";
import MapRoute from "./components/route/Route";
import Skeleton from "./components/skeleton/Skeleton";
import ReusableModal from "./components/modal/Modal";
import Single from "./components/singleItemDetail/SingleItemDetail";
import EndRIde from "./components/endRide/EndRide";
import DoutAddOrder from "./components/dout/dout";
import Map from "./components/dout/dout";
import CustomerSearch from "./components/dout/selectsearch";
import AllcreditCustomer from "./components/creditCustomer/CreditCustomer";
import SendLoading from "./layouts/Loading";
import VisitHistory from "./components/visitHistory/VisitHistory";
import PaymentCollection from "./components/paymentCollection/PaymentCollection";
import CreditCollection from "./components/creditCollection/CreditCollection";
import Stock from "./components/Stock/Stock";
import Item from "./components/item/Item";
import CouponSale from "./components/coupon/Coupon";
import MembershipCustomers from "./components/membershipCustomer/MembershipCustomer";
import NotFoundPage from "./pages/NotFoundPage";
import ReturnCustomer from "./components/returnCustomers/ReturnCustomers";
import CustomerOrder from "./components/customerOrder/CustomerOrder";
import TodaySalesPage from "./pages/TodaySales/TodaySales";
import CreditSalesPage from "./pages/Sales/CreditSales";
import CashSalesPage from "./pages/Sales/CashSales";
import CustomerOrderHistoryPage from "./pages/customer/OrderHistory";
import CustomerDispenserPage from "./pages/customer/CustomerDispenser";
import MapPage from "./components/mapBox/MapBox";
import LocationButton from "./components/mapBox/CurrentLocation";
function App() {
  const { user } = useSelector((state) => state.user);
  return (
    <div className="App min-h-screen bg-mud">
      <Router>
        <Routes>
          {/* <Route path="/PHONE" element={<PhoneDial></PhoneDial>}></Route> */}
          <Route path="/view" element={<LocationButton></LocationButton>}></Route>
          <Route path="*" element={<NotFoundPage></NotFoundPage>}></Route>

          <Route
            path="/modal"
            element={<ReusableModal></ReusableModal>}
          ></Route>

          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Home />} />
            <Route path="mapPage" element={<MapPage></MapPage>}></Route>

            <Route
              path="/membership-sale"
              element={<CouponSale></CouponSale>}
            ></Route>
            <Route
              path="/membership-customers"
              element={<MembershipCustomers></MembershipCustomers>}
            ></Route>

            <Route
              path="/payment-collection"
              element={<PaymentCollection></PaymentCollection>}
            ></Route>
            <Route path="/route" element={<MapPage></MapPage>}></Route>
            <Route
              path="/credit-issued"
              element={<AllcreditCustomer></AllcreditCustomer>}
            ></Route>
            <Route
              path="/credit-collection"
              element={<CreditCollection></CreditCollection>}
            ></Route>
            <Route
              path="/visit-history"
              element={<VisitHistory></VisitHistory>}
            ></Route>

            <Route
              path="return-customer"
              element={
                <StockProtectedRoute
                  element={<ReturnCustomer></ReturnCustomer>}
                  protect={true}
                />
              }
            ></Route>
            <Route
              path="orders/customer-order/:id"
              element={
                <StockProtectedRoute
                  element={<CustomerOrder></CustomerOrder>}
                  protect={true}
                />
              }
            ></Route>
             <Route
              path="customer/order-history/:id"
              element={
                <StockProtectedRoute
                  element={<CustomerOrderHistoryPage></CustomerOrderHistoryPage>}
                  protect={true}
                />
              }
            ></Route>
             <Route
              path="customer/dispenser/:id"
              element={
                <StockProtectedRoute
                  element={<CustomerDispenserPage></CustomerDispenserPage>}
                  protect={true}
                />
              }
            ></Route>

            <Route
              path="return"
              element={
                <StockProtectedRoute
                  element={<ReturnFormSection></ReturnFormSection>}
                  protect={true}
                />
              }
            ></Route>
            <Route
              path="today-sales"
              element={
                <StockProtectedRoute
                  element={<TodaySalesPage></TodaySalesPage>}
                  protect={true}
                />
              }
            ></Route>
            <Route
              path="credit-sales"
              element={
                <StockProtectedRoute
                  element={<CreditSalesPage></CreditSalesPage>}
                  protect={true}
                />
              }
            ></Route>
            <Route
              path="cash-sales"
              element={
                <StockProtectedRoute
                  element={<CashSalesPage></CashSalesPage>}
                  protect={true}
                />
              }
            ></Route>
            <Route
              path="orders/add-order"
              element={<AddOrder></AddOrder>}
            ></Route>
            <Route path="summary" element={<ViewSummary></ViewSummary>}></Route>
            <Route path="single-item/:id" element={<Single></Single>}></Route>
            <Route path="stock" element={<Stock></Stock>}></Route>
            <Route path="item" element={<Item></Item>}></Route>

            <Route
              path="start-your-ride"
              element={
                <StockProtectedRoute
                  element={<StartRideForm />}
                  protect={false}
                />
              }
            />
            <Route
              path="/end-ride"
              element={
                <StockProtectedRoute
                  element={<EndRIde></EndRIde>}
                  protect={true}
                />
              }
            ></Route>
            <Route
              path="customers"
              element={
                <StockProtectedRoute element={<Customer />} protect={true} />
              }
            />
            <Route
              path="orders"
              element={
                <StockProtectedRoute element={<Order />} protect={true} />
              }
            />

            <Route
              path="customers/all-customers"
              element={
                <StockProtectedRoute
                  element={<AllCustomers />}
                  protect={true}
                />
              }
            />
            <Route
              path="customers/customer/:id"
              element={
                <StockProtectedRoute
                  element={<CustomerProfile />}
                  protect={true}
                />
              }
            />
            <Route
              path="customers/add-customer"
              element={
                <StockProtectedRoute element={<AddCustomer />} protect={true} />
              }
            />
          </Route>
          <Route
            path="login"
            element={user ? <Navigate to="/" /> : <Login />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
