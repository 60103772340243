/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import Select from "../select/Select";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { addRide } from "../../redux/ducks/RideSlice";
import ReusableModal from "../modal/Modal";
import {
  getPaymentCollection,
  setCreditCustomerLoading,
  payCredit,
} from "../../redux/ducks/CreditCustomerSlice";
import DatePicker from "react-datepicker";
import { AiFillCaretDown } from "react-icons/ai";
import { setSendLoading, setSendSuccess } from "../../redux/ducks/LoadingSlice";
import SendLoading from "../../layouts/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutocompleteDropdown from "../selectBySearch/SearchBySelect";
import CustomInput from "../customInput/CustomInput";
import SelectCustom from "../select/SelectCustom";
import Input from "../input/Input";
import { getCustomerPendingOrder } from "../../redux/sagas/requests/CustomerRequest";
// const subRoute = [{ name: "3214" }, { name: "8567" }, { name: "347" }];

const initialValues = {
  invoice: "",
  customerName: "",
  total: 0,
};

const CreditCollection = () => {
  const creditSchema = yup.object().shape({
    customerName: yup.string().required("Customer name is required"),
    customerId: yup.string().required("Customer name  is required"),

    invoice: yup.string().required("invoice is required"),
    total: yup
      .string()
      .required("collect amount is required")
      .test("Invalid Amount", "Invalid Amount", (value) => {
        const bot = parseInt(value);
        return bot > 0 && bot <= parseInt(maxCA);
      }),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerName =
    useSelector((state) => state.creditCustomer?.paymentCollection) ?? {};
  const [startDate, setStartDate] = useState(new Date());

  const { token } = JSON.parse(localStorage.getItem("user"));
  const [invoice, setInvoice] = useState([]);
  const [maxCA, setMaxCA] = useState(0);

  const [orderDetail, setOrderDetail] = useState("") ?? {};
  const [show, setShow] = useState(false);
  const [customer, setCustomer] = useState([]);
  const { sendLoading, sendSuccess } = useSelector((state) => state.loading);
  const [selectedInvoice, setSelectedInvoice] = useState(0);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const { mainRoute, subRoute } = useSelector((state) => state.ride) ?? {};
  const rideId = useSelector((state) => state.ride._id) ?? {};

  console.log(invoice);
  useEffect(() => {
    dispatch(getPaymentCollection({ token, mainRoute, subRoute }));

    return () => {
      dispatch(setSendLoading(false));
    };
  }, []);

  useEffect(() => {
    if (sendSuccess) {
      toast.success("Payment collected successfully!", {
        position: "bottom-center",
        autoClose: 1000, // Set autoClose to 1000 milliseconds (1 second)
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        onClose: () => {
          navigate(-1); // Navigate back to the previous page
        },
      });
    } else {
      console.log("sendSuccess is false");
    }
  
    return () => {
      dispatch(setSendSuccess(false)); // Move dispatch inside the cleanup function
    };
  }, [sendSuccess,]); // Add dispatch and navigate to dependency array
  

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (!value) {
      setFormValues({
        ...formValues,
        [name]: "",
        total: "",
      });
      return;
    }

    if (name === "invoice") {
      const foundInvoice = invoice.find((invoice) => invoice.orderNo == value);
      setOrderDetail(foundInvoice);

      setFormValues({
        ...formValues,
        [name]: value || 0,
        total: foundInvoice?.paymentRemaining || 0,
      });
      setMaxCA(foundInvoice?.paymentRemaining);

      if (value) {
        setShow(true);
      }
    } else {
      setFormValues({
        ...formValues,
        total: value,
      });
    }
    try {
      await yup.reach(creditSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null, total: null });
    } catch (error) {
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await creditSchema.validate(formValues, { abortEarly: false });
      dispatch(setSendLoading(true));
      dispatch(payCredit({ formValues, token, rideId }));
    } catch (error) {
      const validationErrors = {};

      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  const cancel = () => {
    setShow(false);
  };
  const onSelect = async (value) => {
    const customer = value;
    setCustomer(customer);
    setFormValues({
      ...formValues,
      customerName: customer.name,
      customerId: customer._id,
    });
//    const {data} = await getCustomerPendingOrder(token,customer._id)
//  setInvoice(data)
    setInvoice(
      customer?.invoice?.map((invoice) => {
        return {
          ...invoice,
          name: invoice?.orderNo,
        };
      })
    );
    setFormErrors({ ...formErrors, customerName: null });
  };

  return (
    <div className="flex flex-col min-h-base px-8 py-8 select-none">
      <div className="bg-white p-4 pt-4  rounded-md ">
        <form onSubmit={handleSubmit} onKeyDown={handleKeyPress}>
          <div className="flex flex-col   rounded-md ">
            {/* <div className="flex relative w-full justify-end ">
              <DatePicker
                locale="us"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="w-full   pr-32 px-3 py-0.5 mb-3 bg-white border shadow-sm border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder-mud text-black  block rounded-md sm:text-sm focus:ring-1"
                minDate={new Date()} // Disable all dates before today
                maxDate={new Date()}
                // Disable all dates after today
              />

              <div className="pointer-events-none absolute inset-y-0 right-0 bottom-2 flex items-center px-2 text-gray-700">
                <AiFillCaretDown />
              </div>
            </div> */}
            <div className="flex flex-col w-full   ">
              <CustomInput
                type={"date"}
                value={new Date().toISOString().slice(0, 10)}
                name={"date"}
                label={'Date'}
                readOnly={true}
              ></CustomInput>
            </div>
            {/* <label className="text-red-350">{formErrors?.customerId}</label> */}

            <div className="relative mb-3">
              <AutocompleteDropdown
                customers={customerName}
                onSelect={onSelect}
                bgWhite={true}
                label={"Select customer"}
                error={formErrors.customerId}
                errorMessage={formErrors.customerId}
              ></AutocompleteDropdown>
            </div>

            {/* <Select
              name={"customerName"}
              options={customerName}
              label={formErrors?.customerName}
              onChange={handleInputChange}
              labelStyles={"text-red-350 text-sm"}
              defaultValue={{ name: "customerName" }}
              parentStyles={"relative inline-block w-full"}
              styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white text-black border shadow-sm
            ${
              formErrors?.customerName
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            /> */}
            {/* <Select
              options={invoice}
              name={"invoice"}
              onChange={handleInputChange}
              label={formErrors?.invoice}
              labelStyles={"text-red-350 text-sm"}
              defaultValue={{ name: "invoice" }}
              parentStyles={"relative inline-block w-full"}
              styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-white text-black border shadow-sm
            ${
              formErrors?.invoice
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            /> */}
            <SelectCustom
              options={invoice}
              name={"invoice"}
              onChange={handleInputChange}
              label={"Invoice No"}
              error={formErrors.invoice}
              errorMessage={formErrors.invoice}
              // value={formValues.invoice}
              bgColor={'white'}
            ></SelectCustom>
            <CustomInput
              type={"number"}
              // onChange={handleInputChange}
              value={orderDetail?.total || 0}
              // name={"total"}
              // error={formErrors?.total}
              // errorMessage={formErrors?.total}
              label={"Invoice Amount"}
              bgColor={"white"}
              readOnly={true}
            ></CustomInput>
            <CustomInput
              type={"number"}
              // onChange={handleInputChange}
              value={orderDetail?.paymentRemaining || 0}
              // name={"total"}
              // error={formErrors?.total}
              // errorMessage={formErrors?.total}
              label={"Remaining amount"}
              bgColor={"white"}
              readOnly={true}
            ></CustomInput>
            <CustomInput
              type={"number"}
              onChange={handleInputChange}
              value={formValues.total}
              name={"total"}
              error={formErrors?.total}
              errorMessage={formErrors?.total}
              label={"Collect Amount"}
              bgColor={"white"}
            ></CustomInput>
            <div
              // onClick={() => navigate("all-customers")}
              className="mt-4 bg-white rounded-2xl hover:bg-sub cursor-pointer"
              style={{
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Adjust the color and intensity as needed
              }}
            >
              <h1 className="text-main text-md font-medium  text-center">
                {customer?.dueAmount || 0}
              </h1>
              <h1 className="text-main text-sm font-medium  text-center mb-3">
                Total Outstanding Amount
              </h1>
            </div>
          </div>
          <div className="flex justify-around mb-10 mt-4">
            <button
              type="submit"
              className="bg-main text-white font-medium py-1 px-4 rounded-md mt-6 w-20"
            >
              Submit
            </button>
            <button
              className="bg-main text-white font-medium py-1 px-4 rounded-md mt-6 w-20"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              cancel
            </button>
          </div>
        </form>
      </div>
      {sendLoading ? <SendLoading></SendLoading> : ""}
      {show ? (
        <ReusableModal type={2} data={orderDetail} onCancel={cancel}>
          {" "}
        </ReusableModal>
      ) : (
        ""
      )}
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default CreditCollection;
