const TextArea = ({ name, placeholder, onChange, style, value, disabled }) => {
  return (
    <textarea
      value={value}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      className={style}
      disabled={disabled}
    />
  );
};

export default TextArea;
