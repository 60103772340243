export default function handleAuthError(err) {
  const {
    response: {
      data: { error },
      status,
    },
  } = err;
  console.log(err);
  console.log(status);
  if(status ===401){
    localStorage.clear();
    window.location.reload();
    console.log('working  of  dcecdecauth');
  }
  console.log(err.response);
  if(err.response.status ===401){
    console.log('working auth');
  }
  if (error === "Request is not authorized") {
   
  }
}
