/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../input/Input";
import Section from "../commonListSection/Section";
import { getItemsRequest } from "../../redux/sagas/requests/itemRequest";
import { ADMIN_BACKEND_URL } from "../../config/Config";
import { useDispatch, useSelector } from "react-redux";
import { setItems } from "../../redux/ducks/VanLife";

// const item = [
//   {
//     name: "10 water gallon",
//     img: `water10gallonbottle.png`,
//     path: `/single-item/${0}`,
//   },
//   {
//     name: "Water Dispenser",
//     img: "palsticdispencer.png",
//     path: `/single-item/${1}`,
//   },
//   {
//     name: "Water Pump",
//     img: "palsticdispencer.png",
//     path: `/single-item/${2}`,
//   },
//   { name: "Table Top", img: "palsticdispencer.png", path: `/single-item/${3}` },
// ];
const Item = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  // const [item, setItem] = useState("");
  const dispatch = useDispatch();
  const { mainRoute, subRoute } = useSelector((state) => state.ride);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const { item} = useSelector(
    (state) => state.vanLife
  );

  const [filteredOptions, setFilteredOptions] = useState(item);
  const handleFiltering = (event) => {
    const input = event.target.value;
    setInputValue(input);

    // Filter options by input value
    const filtered = item?.filter((option) => {
      const name = option.name;
      return name.toLowerCase().includes(input.toLowerCase());
    });

    setFilteredOptions(filtered);
  };
  useEffect(()=>{
    const fetchData = async()=>{
     const {data }= await getItemsRequest(token,mainRoute)
    //  setItem(data)
     dispatch(setItems(data))
     setFilteredOptions(data)
     
    }
    fetchData()

  },[])

  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-8 select-none">
      <div className="flex gap-7">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-5 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <Input
            id={"search__input"}
            type={"text"}
            value={inputValue}
            onChange={handleFiltering}
            name={"search__input"}
            placeholder={"Search"}
            styles={`w-full px-3 py-2 pl-14 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              placeholder-slate-400  block sm:text-sm focus:ring-1 rounded-3xl`}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2">
        {item?.length ? (
          filteredOptions?.length ? (
            filteredOptions.map((x, idx) => (
              <Section
                key={idx}
                name={x.name}
                img={`${ADMIN_BACKEND_URL}/${x.img}`}
                path={x.path}
                // subOne={x.address}
                stock={x.stock}
                mobile={true}
                onClick={() => navigate(x.path)}
                type={1}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-48">
              No item matching with that name
            </div>
          )
        ) : (
          <div className="flex justify-center items-center h-48">No item</div>
        )}
      </div>
    </div>
  );
};

export default Item;
