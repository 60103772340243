import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getVisitHistoryRequest({ payload: { token } }) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/get-history`,
  });
}
export function addVisitHistoryRequest({
  payload: {
    token,
    formValues: { ...data },
    customerId,
    _id
    
  },
}) {
  data.rideId = _id;
  data.customerId = customerId;
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify({ data }),
    url: `${BACKEND_URL}/ride/visit-history`,
  });
}