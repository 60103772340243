import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StockProtectedRoute = ({ element: Element, protect }) => {
  const ride = useSelector((state) => state.ride);

// const stock = true
// remeber to change stock
  const hasStock = !!ride;
  if(hasStock) {
    console.log('workings of hasStock');
  }

  return protect ? (
    hasStock ? (
      Element
    ) : (
      <Navigate to="/" />
    )
  ) : hasStock ? (
    <Navigate to="/" />
  ) : (
    Element
  );
};

export default StockProtectedRoute;
