import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};
const user = JSON.parse(localStorage.getItem("user"));

if (user) {
  initialState.user = user.email;
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: initialState.user,
    loginError: null,
  },
  reducers: {
    userLogin() {},
    setUser(state, action) {
      const user = action.payload;
      return { ...state, user };
    },
    setLoginError(state, action) {
      const loginError = action.payload;
      return { ...state, loginError, user: null };
    },
  },
});

export const { userLogin, setUser, setLoginError } = userSlice.actions;

export default userSlice.reducer;
