/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import Input from "../input/Input";
import Select from "../select/Select";
import {
  getVisitHistory,
  addVisitHistory,
} from "../../redux/ducks/VisitHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Section from "../commonListSection/Section";
import ReusableModal from "../modal/Modal";
import * as yup from "yup";
import SendLoading from "../../layouts/Loading";
import Pagination from "../pagination/Pagination";

const reasons = [{ name: "Not Available" }, { name: "Not required" }];
const initialValues = {
  reason: "",
  remarks: "",
};
const selectfilter = [
  { name: "filter",value:''},

  { name: "visited" },
  { name: "Not Visited" },
  { name: "delivered" },
  { name: "Not Delivered" },
];
const VisitHistory = () => {
  const VisitSchema = yup.object().shape({
    reason: yup.string().required("Reason is required"),

    remarks: yup.string().required("remarks is required"),
  });
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customers = useSelector(
    (state) => state.visitHistory?.VisitHistory?.ride?.users
  );
  const visit = useSelector((state) => state.visitHistory?.VisitHistory?.visit);
  const [reload, setReload] = useState(false);
  console.log(visit);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [customerId, setCustomerId] = useState("");
  const { _id } = useSelector((state) => state.ride) ?? "";
  const [filterStatus, setFilterStatus] = useState("");
  const dispatch = useDispatch();
  console.log(customers);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const [filteredOptions, setFilteredOptions] = useState([]);
  console.log(filteredOptions);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  useEffect(() => {
    dispatch(getVisitHistory({ token }));
  }, [reload]);
  useEffect(() => {
    if (customers?.length) {
      setFilteredOptions(customers);
    }
  }, [customers]);
  const handleFiltering = (event) => {
    const input = event.target.value;
    setFilterStatus("");

    // Filter options by input value
    const filtered = customers?.filter((option) => {
      const name = option.name;
      return name.toLowerCase().includes(input.toLowerCase());
    });
    setFilteredOptions(filtered);
  };
  const VisitReason = (id) => {
    console.log(id);
    setCustomerId(id);
    setIsModalOpen(true);
  };
  const handleFilteringbyStatus = (event) => {
    const input = event.target.value;
    console.log(input);
    setFilterStatus(input);

    if (input === "visited") {
      const filtered = customers.filter((option) => option.visited);
      setFilteredOptions(filtered);
    } else if (input === "Not Visited") {
      const filtered = customers.filter((option) => !option.visited);
      setFilteredOptions(filtered);
    }
    else if (input === "delivered") {
      const filtered = customers.filter((option) => option.delivered);
      setFilteredOptions(filtered);
    }
    else if (input === "Not Delivered") {
      const filtered = customers.filter((option) => !option.delivered);
      setFilteredOptions(filtered);
    }
    else{
      setFilteredOptions(customers)
    }
  };
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    try {
      await yup.reach(VisitSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      console.log(error);
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };
  const closeModal = (e) => {
    setIsModalOpen(false);
    setFormValues({ ...formValues, reason: '',remarks:'' });

    setFormErrors({ ...formErrors, reason: null, remarks: null });
  };
  const handleSubmit = async () => {
    console.log(customerId);
    try {
      await VisitSchema.validate(formValues, { abortEarly: false });
      dispatch(addVisitHistory({ formValues, token, customerId, _id }));
      setFormValues({ ...formValues, reason: '',remarks:'' });

      setIsModalOpen(false);
      setTimeout(() => {
        setReload(!reload);
      }, 500);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    const totalPages = Math.ceil(filteredOptions.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleCustomers = filteredOptions.slice(startIndex, endIndex);

  return (
    <div>
      {customers ? (
        <div
          className="flex flex-col gap-5 min-h-base px-8 py-8 select-none "
          style={{ overflowY: "hidden" }}
        >
          <div className="flex w-full gap-4 justify-between">
            <div className="flex flex-col bg-mud   rounded-lg w-1/2">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-5 flex items-center   pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  id={"search__input"}
                  type={"text"}
                  name={"search__input"}
                  placeholder={"Search"}
                  onChange={handleFiltering}
                  autoComplete="off"
                  className="w-full px-3 py-1 pl-10 bg-white border text-start shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder-slate-400 block sm:text-sm focus:ring-1 rounded-lg"
                />
              </div>
            </div>
            <div className="flex bg-white rounded-lg w-1/2">
              <Select
                options={selectfilter}
                name={"filter"}
                onChange={handleFilteringbyStatus}
                labelStyles={"text-red-350 text-sm"}
                value={filterStatus}
                // defaultValue={{ name: "filter" }}
                parentStyles={"relative inline-block w-full"}
                styles={`cursor-pointer block appearance-none w-full px-3 py-1 bg-white text-slate-400 border tracking-wide  shadow-sm
          "border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
              />
            </div>
          </div>

          <div className="flex w-full justify-between gap-5">
            {visit?.map((obj, idx) => {
              return (
                <div
                  className="flex flex-col justify-center items-center bg-white  py-2 w-1/2 rounded-lg"
                  key={idx}
                >
                  <h1 className="text-main text-4xl font-bold ">{obj.count}</h1>
                  <h1 className="text-main text-md font-medium  text-center py-0.5 tracking-tighter">
                    {obj.visited ? " Visted Customer" : "Not Visited Customer"}
                  </h1>
                </div>
              );
            })}
            {visit.length === 1 ? (
              <div className="flex flex-col justify-center items-center bg-white  py-2 w-1/2 rounded-lg">
                <h1 className="text-main text-4xl font-bold ">0</h1>
                <h1 className="text-main text-md font-medium  text-center py-0.5 tracking-tighter">
                  Not Visted Customer
                </h1>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex justify-center items-center p-2 bg-white rounded-3xl">
            <div className="flex flex-col w-full justify-center items-center">
              <h1 className="text-main text-4xl font-bold">
                {customers?.length || 0}
              </h1>
              <h1 className="text-main text-md font-bold py-0.5">
                Total Customers
              </h1>
            </div>
          </div>
          <div
            className="flex flex-col gap-3 flex-grow  overflow-y-scroll"
            style={{ height: "30vh" }}
          >
            {customers?.length ? (
              visibleCustomers?.length ? (
                visibleCustomers.map((x, idx) => (
                  <Section
                    key={idx}
                    name={x.name}
                    subOne={x.address}
                    id={x.userId}
                    mobile={x.mobileNumber}
                    onClick={VisitReason}
                    visited={x.visited}
                    delivered={x.delivered}
                    type={3}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center h-48">
                  No customers matching that criteria
                </div>
              )
            ) : (
              <div className="flex justify-center items-center h-48">
                No customers
              </div>
            )}
          </div>
          {visibleCustomers?.length ? (
            <Pagination
              currentPage={currentPage}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              totalPages={Math.ceil(filteredOptions.length / itemsPerPage)}
            ></Pagination>
          ) : (
            ""
          )}
          {isModalOpen ? (
            <ReusableModal
              onCancel={closeModal}
              type={3}
              formValues={formValues}
              formErrors={formErrors}
              reasons={reasons}
              handleInputChange={handleInputChange}
              onConfirm={handleSubmit}
              
            ></ReusableModal>
          ) : (
            ""
          )}
        </div>
      ) : (
        <SendLoading></SendLoading>
      )}
    </div>
  );
};

export default VisitHistory;
