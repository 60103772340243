import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function userLoginRequest(data) {
  const { email, password } = data.payload;
  const phoneOrMail = email;
  return axios.request({
    headers: { "Content-type": "application/json" },
    method: "post",
    data: JSON.stringify({ phoneOrMail, password }),
    url: `${BACKEND_URL}/auth/user-login`,
  });
}
