
export default function Skeleton() {
  return (
    <div role="status" className="flex items-center justify-around bg-white rounded-lg  shadow-lg">
    <div className="flex flex-col items-center justify-center animate-pulse  w-14 h-14">
      <div className="w-12 h-12 bg-mud-plus rounded-full"></div>
      {/* <div className="h-2 w-24 bg-gray-300 rounded-full"></div> */}
    </div>

    <div className="flex flex-col space-y-1 animate-pulse">
      <div className="h-2 w-12 bg-gradient-to-br from-mud to-white rounded-full"></div>
      <div className="h-2 w-24 bg-gradient-to-br from-mud to-sub rounded-full"></div>
      <div className="h-2 w-36 bg-gradient-to-br from-mud to-white  rounded-full"></div>
    </div>
  </div>
  );
}
