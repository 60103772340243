/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getCustomer } from "../../redux/ducks/CustomerSlice";
import { getACustomerDetailRequest,addCustomerLocationRequest } from "../../redux/sagas/requests/CustomerRequest";
import { CiLocationOn } from "react-icons/ci";
import ReusableModal from "../modal/Modal";

import LocationButton from "../mapBox/CurrentLocation";

const CustomerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.customers);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const rideId = useSelector((state) => state.ride._id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [text, setText] = useState('Mark Location');


  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [cusDetail, setCusDetail] = useState(null);
  const [coordiantes, setCoordinate] = useState(null);

  const filterItems = (id) => {
    const items = customers?.filter((x) => x._id === id);
    setSelectedCustomer(...items);
    if(items[0]?.coordinates){
     setCoordinate(items[0]?.coordinates)
     setText('Change Location')
    }

  };
  console.log(selectedCustomer);

  useEffect(() => {
    if (customers?.length) {
      filterItems(id);
    }
  }, [customers]);

  useEffect(() => {
    dispatch(getCustomer({ token, rideId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fecthdata = async () => {
      console.log("working of");
      const { data } = await getACustomerDetailRequest(token, id);
      console.log(data);
      setCusDetail(data?.coupon[0]);
    };
    fecthdata();
  }, []);
  const onLocationChange =(value)=>{
    setCoordinate(value)
    setIsModalOpen(true)
  }
  const sendData = async() => {
    setIsModalOpen(false);

   await addCustomerLocationRequest({coordiantes,id},token)
   setText('Change Location')


  }
  const cancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="flex flex-col gap-3 min-h-base px-8 py-10 select-none">
      <div className="flex bg-white rounded-3xl">
        <div className="flex justify-center items-center w-3/6">
          <img
            className="object-cover w-28 h-28 rounded-full"
            src="/userProfile.74cda66309e83509d2bd.avif"
            alt=""
          />
        </div>
        <div className="flex flex-col justify-between p-4 leading-normal w-3/6">
          <span className="text-mud-plus text-xs mt-1.5 font-medium">Name</span>

          <h1 className="text-black text-md font-normal py-0.5 tracking-tighter">
            {selectedCustomer?.name}
          </h1>
         
          {/* <span className="text-mud-plus text-xs mt-1.5 font-medium">Customer ID</span>

          <h1 className="text-black text-md font-normal py-0.5 tracking-tighter">
            {selectedCustomer?.cusID}
          </h1> */}
          <span className="text-mud-plus text-xs mt-1.5 font-medium">
            Address
          </span>

          <h1 className="text-mud text-md font-normal py-0.5 tracking-tighter">
            {selectedCustomer?.Address}
          </h1>
          <h1 className="text-mud text-xs font-normal py-0.5 tracking-tighter">
            {coordiantes?`Lat:${coordiantes.lat}  Log:${coordiantes.log}`:''}
          </h1>
          <span className="text-mud-plus text-xs mt-1.5 font-medium">
            Whatsapp No
          </span>

          <h1 className="text-mud text-md font-normal py-0.5 tracking-tighter">
            {selectedCustomer?.whatsAppNo}
          </h1>

        </div>
      </div>
    <LocationButton buttonText={text} onLocationChange={onLocationChange}></LocationButton>

      <div className=" p-2 bg-white rounded-xl">
        <div className="flex justify-center items-center gap-5">
          <h1 className="text-black text-xl font-bold py-1 text-center">
            Couponsale
          </h1>
          <div className="py-1 px-3 rounded-full bg-sub">
            {selectedCustomer?.FOC ? 1 : 0}
          </div>
        </div>
        <h1 className="text-[#9FADB8] text-md font-normal py-0.5 tracking-tighter text-center">
          Total No. of bottle -{" "}
          {cusDetail?.quantity|| 0}
        </h1>
        {/* <h1 className="text-[#9FADB8] text-md font-normal py-0.5 tracking-tighter text-center">
          From: 20 Aug 22 To : 22 Aug 23
        </h1> */}
        <h1 className="text-[#9FADB8] text-md font-normal py-0.5 tracking-tighter text-center">
          Sold Items - {cusDetail?.totalDebit  || 0}
        </h1>
        <h1 className="text-[#9FADB8] text-md font-normal py-0.5 tracking-tighter text-center">
          Items Left - {cusDetail?.remaining || 0}
        </h1>
        <h1 className="text-[#9FADB8] text-md font-normal py-0.5 tracking-tighter text-center">
          {selectedCustomer?.FOC
            ? "Unlimited days "
            : "No coupons purchased"}
        </h1>
      </div>

      <div className=" p-2 bg-white rounded-xl cursor-pointer">
        <h1
          className="text-main text-md font-medium py-1 text-center"
          onClick={() => navigate(`/customer/order-history/${id}`)}
        >
          Order History
        </h1>
      </div>
      <div className=" p-2 bg-white rounded-xl cursor-pointer">
        <h1
          className="text-main text-md font-medium py-1 text-center"
          onClick={() => navigate(`/customer/dispenser/${id}`)}
        >
          Dispenser
        </h1>
      </div>
      {isModalOpen && (
        <ReusableModal
          title={"Location "}
          message={"Set the location?"}
          onConfirm={sendData}
          onCancel={cancel}
          type={1}
        />
      )}
    </div>
  );
};

export default CustomerProfile;
