import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getTodaySalesRequest({ payload: { token, rideId } }) {
  console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/today-sales/${rideId}`,
  });
}
export function getCashSalesRequest({ payload: { token, rideId } }) {
  console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/cash-sales/${rideId}`,
  });
}
export function getCreditSalesRequest({ payload: { token, rideId } }) {
  console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/credit-sales/${rideId}`,
  });
}

export function getTotalSalesListRequest(token, rideId) {
  console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/total-sales/${rideId}`,
  });
}
export function getPendingAmountRequest(token, rideId) {
  console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/pending-amount/${rideId}`,
  });
}
export async function getQuantityRequest(token, rideId) {
  console.log(token);

 const {data}= await axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/ride-quantity/${rideId}`,
  });
  console.log(data,'hi');
  return data
}
export async function getSummaryStockRequest(token, rideId) {
  console.log(token);

 const {data}= await axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/summary-stock/${rideId}`,
  });
  console.log(data,'hi');
  return data
}


