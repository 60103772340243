import React, { useEffect, useState, useRef } from "react";
import { AiFillCaretDown } from "react-icons/ai";

const CustomInput = ({
  value,
  label,
  onChange,
  name,
  type,
  error,
  errorMessage,
  readOnly,
  bgColor
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [labelMerged, setLabelMerged] = useState(!!value);
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setLabelMerged(!!event.target.value);
    onChange && onChange(event);
  };

  useEffect(() => {
    if (value || value ===0) {
      setInputValue(value);
      setLabelMerged(true);
    }
  }, [value]);

  const handleLabelMouseEnter = () => {
    setLabelMerged(true);
  };

  const handleLabelMouseLeave = () => {
    setLabelMerged(false);
  };

  const handleLabelClick = () => {
    // Focus the input field when the label is clicked
    // setLabelMerged(true);

    inputRef.current.focus();
  };

  return (
    <div className="relative mb-3">
      <input
        ref={inputRef} // Ref added to the input element
        type={type}
        name={name}
        placeholder={label}
        className={`border appearance-none w-full placeholder-black text-md rounded-md px-3 py-1 focus:outline-none bg-white focus:ring focus:border-sub ${
          labelMerged ? "border-merged" : ""
        } ${error ? "border-error border-red-600" : "border-sub"}`}
        onChange={handleInputChange}
        value={inputValue}
        readOnly={readOnly}
      />
      {type==='date'&&<div className="pointer-events-none absolute inset-y-0 right-0 bottom-1 flex items-center px-2 text-gray-700">
          <AiFillCaretDown />
        </div>}
      {error ? (
        <label
          className={`absolute left-2 -top-2 bg-white text-xs text-red-600 transition-all`}
        >
          {errorMessage}
        </label>
      ) : (
        <label
          // onMouseEnter={handleLabelMouseEnter}
          // onMouseLeave={handleLabelMouseLeave}
          // onClick={handleLabelClick}
          className={`absolute left-2 ${
            labelMerged
              ? "-top-2 bg-white text-xs text-gray-600"
              : "top-0.5 text-sm bg-mud hidden"
          } transition-all`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CustomInput;
