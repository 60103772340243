import { call, put } from "redux-saga/effects";
import {
  addMembershipRequest,
  getMembershipCustomersRequest,
} from "../requests/MembershipRequest";
import handleAuthError from "./HandleAuthErrors";

import {
  setMembershipCustomer,
  setMCLoading,
  setMCSendLoading,
  setMCAddSuccess
} from "../../ducks/MembershipSlice";

export function* handleAddMembership(action) {
  console.log(action);
  try {
    const { data } = yield call(addMembershipRequest, action);
    if (data) {
      yield put(setMCSendLoading(false));
      yield put(setMCAddSuccess(true));

    } else {
      throw Error("Stock is not added to the data base");
    }
  } catch (error) {
    console.log(error);
  }
}
export function* handleGetMembershipCustomers(action) {
  try {
    // yield put(setLoading({ loading: true }));

    const { data } = yield call(getMembershipCustomersRequest, action);

    yield put(setMembershipCustomer(data));
    yield put(setMCLoading(false));
    console.log("working");
  } catch (error) {
    console.log(error);
    // yield put(setMembershipCustomersLoading(true));

    handleAuthError(error);
  }
}
