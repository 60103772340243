import { call, put } from "redux-saga/effects";
import handleAuthError from "./HandleAuthErrors";

import {
  setCreditCustomers,
  setCreditCustomerLoading,
  setPaymentCollection,
  setPaymentHistory
} from "../../ducks/CreditCustomerSlice";
import {
  getCreditCustomersRequest,
  getPaymentCollectionRequest,
  payCreditRequest,
  getPaymentHistoryRequest
} from "../requests/CreditCustomerRequest";
import { setSendLoading, setSendSuccess } from "../../ducks/LoadingSlice";
export function* handleGetCreditCustomer(action) {
  try {
    // yield put(setLoading({ loading: true }));

    const { data } = yield call(getCreditCustomersRequest, action);

    yield put(setCreditCustomers(data));
    console.log("working");
    yield put(setCreditCustomerLoading(false));

    // yield put(setLoading({ loading: false }),
    // )
  } catch (error) {
    console.log(error);
    // yield put(setCreditCustomerLoading(true))

    handleAuthError(error);
  }
}
export function* handleGetPaymentCollection(action) {
  try {
    // yield put(setLoading({ loading: true }));

    const { data } = yield call(getPaymentCollectionRequest, action);

    yield put(setPaymentCollection(data));
    console.log("working");
    yield put(setCreditCustomerLoading(false));

    // yield put(setLoading({ loading: false }),
    // )
  } catch (error) {
    console.log(error);
    // yield put(setCreditCustomerLoading(true))

    handleAuthError(error);
  }
}
export function* handleGetPaymentHistory(action) {
  try {
    // yield put(setLoading({ loading: true }));

    const { data } = yield call(getPaymentHistoryRequest, action);

    yield put(setPaymentHistory(data));
    yield put(setCreditCustomerLoading(false));

   
  } catch (error) {
    console.log(error);

    handleAuthError(error);
  }
}
export function* handlePayCredit(action) {
  console.log(action);
  try {
    const { data } = yield call(payCreditRequest, action);
    if (data) {
      yield put(setSendLoading(false));
      yield put(setSendSuccess(true));

    } else {
      throw Error("Stock is not added to the data base");
    }
  } catch (error) {
    console.log(error);
  }
}
