import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { useState } from "react";
import { navList } from "../../constant/Constant";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShow, setClose } from "../../redux/ducks/sidebarSlice";

const Navbar = ({ sideBar, Backcall, pathname }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.siderbar);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <>
      <nav
        className={`flex select-none bg-red justify-between pl-5 pr-5 items-center w-full h-16`}
      >
        {sideBar && (
          <button
            onClick={() => dispatch(setShow())}
            type="button"
            className="inline-flex items-center w-10 h-10 justify-center text-sm text-black rounded-lg"
          >
            <AiOutlineMenu className="w-full h-full p-2" />
          </button>
        )}
        {Backcall.show && (
          <button
            onClick={() => {
              navigate(-1);
            }}
            type="button"
            className="inline-flex items-center w-10 h-10 justify-center text-sm text-black rounded-lg"
          >
            {/* <AiOutlineMenu  /> */}
            {/* <AiOutlineArrowLeft></AiOutlineArrowLeft> */}
            <IoIosArrowBack className="w-full h-full p-2" />
          </button>
        )}
        {!sideBar && !Backcall?.show && (
          <div className="flex items-center ">
            <h1 className="font-manrope  font-medium text-lg leading-6 "></h1>
          </div>
        )}

        {Backcall?.name && (
          <div className="flex items-center ">
            <h1 className="font-manrope font-medium text-lg leading-6 ">
              {Backcall?.name}
            </h1>
          </div>
        )}
        {sideBar?.name && (
          <div className="flex items-center   ">
            <h1 className="font-manrope font-medium text-lg leading-6 ">
              {sideBar.name}
            </h1>
          </div>
        )}

        <div className="flex items-center">
          {/* {Backcall&&!Backcall?.name && !Backcall?.show && (
            <div className="mt-3 mr-2">
              <h1 className="text-black font-bold text-xs text-end">
                Hello, User
              </h1>
              <h1 className="text-mud text-xs mt-1 text-end">Welcome</h1>
              {!Backcall.show && (
                <div className="text-main text-xs mt-1  ">
                  Last login in : 0 min ago
                </div>
              )}
            </div>
          )} */}
          {sideBar && !sideBar?.show && (
            <div className="mt-3 mr-2">
              <h1 className="text-black font-bold text-xs text-end">
                Hello, User
              </h1>
              <h1 className="text-mud text-xs mt-1 text-end">Welcome</h1>
              {!Backcall.show && (
                <div className="text-main text-xs mt-1  ">
                  Last login in : 0 min ago
                </div>
              )}
            </div>
          )}
          {!sideBar && !Backcall?.show && (
            <div className="mt-3 mr-2">
              <h1 className="text-black font-bold text-xs text-end">
                Hello, User
              </h1>
              <h1 className="text-mud text-xs mt-1 text-end">Welcome</h1>
              {!Backcall.show && (
                <div className="text-main text-xs mt-1  ">
                  Last login in : 0 min ago
                </div>
              )}
            </div>
          )}
          <div className="relative">
            <img
              className="w-10 h-10 rounded-full cursor-pointer"
              src="/userProfile.74cda66309e83509d2bd.avif"
              alt=""
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div className="absolute top-8 right-0 mt-2 p-2 bg-white border rounded shadow ">
                <div className="relative">
                  <div className="w-4 h-4 bg-white absolute rotate-45 transform top-[-12px] right-1"></div>
                </div>
                {/* Dropdown content */}
                <ul>
                  <li
                    className="cursor-pointer hover:bg-mud px-2 py-1 rounded"
                    onClick={() => {
                      localStorage.clear();
                      window.location.reload();
                    }}
                  >
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        {sideBar && show && (
          <div className="absolute top-0 left-0 min-h-screen  flex flex-row w-full z-50">
            <div className="w-4/6 bg-sub">
              <div className="flex justify-between items-center px-5 py-3">
                <h1 className="text-lg font-medium">Menu</h1>
                <GrClose
                  className="cursor-pointer"
                  onClick={() => dispatch(setClose())}
                />
              </div>
              <ul className="font-medium flex flex-col  px-7">
                {navList.map((x, idx) => (
                  <li
                    key={idx}
                    className={`p-1 hover:bg-mud rounded-md transition  duration-200 ${
                      pathname === x.path ? "bg-mud" : ""
                    }`}
                  >
                    <Link onClick={() => dispatch(setClose())} to={x.path}>
                      {x.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="w-2/6 bg-transparent"
              onClick={() => dispatch(setClose())}
            ></div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
