import HomeSection from "../../components/homeSection/HomeSection";
import StartRideSection from "../../components/startRideSection/StartRideSection";
import { useSelector } from "react-redux";

const Home = () => {
  const ride = useSelector((state) => state.ride);

  // const stock = true;
  return <>{ride ? <HomeSection stock={ride} /> : <StartRideSection />}</>;
};

export default Home;
