import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getStockRequest({ payload }) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${payload}`,
    },
    method: "get",
    url: `${BACKEND_URL}/stock/get-stock`,
  });
}

export function addStockRequest({
  payload: {
    token,
    formValues: { ...data },
  },
}) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify({ data }),
    url: `${BACKEND_URL}/stock/add-stock`,
  });
}

export function getRouteStockRequest(token,mainRoute) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/route/stock?mainRoute=${mainRoute}`,
  });
}
export function getHomeStockBlance(token,id) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/balance-stock/${id}`,
  });
}