import { call, put } from "redux-saga/effects";
import { addReturnRequest, getReturnCustomerRequest} from "../requests/ReturnRequest";
import handleAuthError from "./HandleAuthErrors";
import { setReturnCustomers } from "../../ducks/ReturnSlice";
import { setSendLoading ,setSendSuccess} from "../../ducks/LoadingSlice";

export function* handleAddreturn(action) {
    console.log(action);
  try {
    const { data } = yield call(addReturnRequest, action);
    if (data) {
      // yield put(setChangereturn(false));
      yield put(setSendLoading(false));
      yield put(setSendSuccess(true));


    } else {
      throw Error("Stock is not added to the data base");
    }
  } catch (error) {
    console.log(error);
  }
}
export function* handleGetReturnCustomer(action) {
  try {

    const { data } = yield call(getReturnCustomerRequest, action);

    yield put(setReturnCustomers(data));

  } catch (error) {
    console.log(error);

    handleAuthError(error);
  }
}
