import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getSummaryRequest({
  payload: {
    token,
    formValues: { ...data },
    rideId,
  },
}) {
  console.log("nor working");
  console.log(data);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: JSON.stringify(data),

    method: "post",
    url: `${BACKEND_URL}/ride/view-summary/${rideId}`,
  });
}

export function addSummaryRequest(token, formValues,rideId) {
  console.log("nor working");
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: JSON.stringify(formValues),

    method: "post",
    url: `${BACKEND_URL}/ride/view-summary/${rideId}`,
  });
}
