import { call, put } from "redux-saga/effects";
import { addStockRequest, getStockRequest } from "../requests/StockRequest";
import {
  getTodaySalesRequest,
  getCashSalesRequest,
  getCreditSalesRequest,
} from "../requests/SalesRequest";
import {
  setCashSales,
  setCreditSales,
  setTodaySales,
  setIsLoading,
} from "../../ducks/SalesSlice";
import handleAuthError from "./HandleAuthErrors";

export function* handleGetTodaySales(action) {
  console.log(action);
  try {
    const { data } = yield call(getTodaySalesRequest, action);
    if (data) {
      yield put(setTodaySales(data));
    }
    yield put(setIsLoading({ loading: false }));
  } catch (error) {
    console.log(error);
    console.log("hello end is here");
    handleAuthError(error);
  }
}

export function* handleGetCreditSales(action) {
  console.log(action);
  try {
    const { data } = yield call(getCreditSalesRequest, action);
    if (data) {
      yield put(setCreditSales(data));
    }
    yield put(setIsLoading({ loading: false }));
  } catch (error) {
    console.log(error);
    console.log("hello end is here");
    handleAuthError(error);
  }
}
export function* handleGetCashSales(action) {
  console.log(action);
  try {
    const { data } = yield call(getCashSalesRequest, action);
    if (data) {
      yield put(setCashSales(data));
    }
    yield put(setIsLoading({ loading: false }));
  } catch (error) {
    console.log(error);
    console.log("hello end is here");
    handleAuthError(error);
  }
}
