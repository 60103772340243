import { createSlice } from "@reduxjs/toolkit";

const MembershipSlice = createSlice({
  name: "membership",
  initialState: {
    MembershipCustomers: "",
    mcLoading: true,
    mcSendLoding: false,
    mcAddSuccess: false,
  },
  reducers: {
    addMembership() {},
    getMembership() {},
    setMembershipCustomer(state, action) {
      const customers = action.payload;
      return { ...state, MembershipCustomers: customers };
    },
    setMCLoading(state, action) {
      return {
        ...state,
        mcLoading: action.payload,
      };
    },
    setMCSendLoading(state, action) {
      return {
        ...state,
        mcSendLoading: action.payload,
      };
    },
    setMCAddSuccess(state, action) {
      return {
        ...state,
        mcAddSuccess: action.payload,
      };
    },
  },
});

export const {
  addMembership,
  getMembership,
  setMembershipCustomer,
  setMCLoading,
  setMCSendLoading,
  setMCAddSuccess
} = MembershipSlice.actions;

export default MembershipSlice.reducer;
