/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomerDashboard } from "../../redux/ducks/CustomerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getRide } from "../../redux/ducks/RideSlice";
import { getCustomer } from "../../redux/ducks/CustomerSlice";
const CustomerDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rideId = useSelector((state) => state.ride._id);
  const { cashSale, creditSale } = useSelector((state) => state.ride);

  const { token } = JSON.parse(localStorage.getItem("user"));
  const { customerDashboard, customers } = useSelector(
    (state) => state.customers
  );
  const membershipNumber = customers.filter(
    (customer) => customer.membership === true
  );

  useEffect(() => {
    dispatch(getCustomerDashboard({ token, rideId }));
  }, []);
  useEffect(() => {
    dispatch(getRide({ token }));
  }, []);
  useEffect(() => {
    dispatch(getCustomer({ token, rideId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-10 select-none">
      <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-8   select-none">
        {customerDashboard?.map((dashboard, idx) => (
          <div
            className="flex flex-col justify-center items-center bg-white  py-0.5 rounded-lg"
            key={idx}
          >
            <h1 className="text-main text-4xl font-bold py-2">
              {dashboard.quantity}
            </h1>
            <h1 className="text-main text-md font-normal py-0.5 tracking-tighter">
              {dashboard._id} AED
            </h1>
          </div>
        ))}

        {/* <div className="flex flex-col justify-center items-center bg-white px-6 py-0.5 rounded-lg">
          <h1 className="text-main text-4xl font-bold py-2">30</h1>
          <h1 className="text-main text-md font-normal py-0.5 tracking-tighter">
            5 AED
          </h1>
        </div>
        <div className="flex flex-col justify-center items-center bg-white px-6 py-0.5 rounded-lg">
          <h1 className="text-main text-4xl font-bold py-2">40</h1>
          <h1 className="text-main text-md font-normal py-0.5 tracking-tighter">
            5 AED
          </h1>
        </div> */}
      </div>
      <div className="bg-white rounded-3xl">
        <div className="flex w-full">
          <div className="flex w-full justify-center py-3 rounded-l-3xl hover:bg-sub cursor-pointer divide-x">
            <div
              className="flex flex-col justify-center items-center w-full border-r border-[#B3E5AA]"
              onClick={() => {
                navigate("/cash-sales");
              }}
            >
              <h1 className="text-main text-xl font-bold py-0.5">{cashSale}</h1>
              <h1 className="text-main text-md font-medium py-0.5">AED</h1>
              <h1 className="text-main text-md font-normal py-0.5">
                Cash Sale
              </h1>
            </div>
          </div>
          <div
            className="flex flex-col justify-center items-center w-full hover:bg-sub cursor-pointer rounded-r-3xl"
            onClick={() => {
              navigate("/credit-sales");
            }}
          >
            <h1 className="text-main text-xl font-bold py-0.5">{creditSale}</h1>
            <h1 className="text-main text-md font-medium py-0.5">AED</h1>
            <h1 className="text-main text-md font-normal py-0.5">
              Credit Sale
            </h1>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center p-2 bg-white rounded-3xl">
        <div
          className="flex flex-col w-full justify-center items-center"
          onClick={() => {
            navigate("/membership-customers");
          }}
        >
          <h1 className="text-main text-4xl font-bold">
            {membershipNumber.length}
          </h1>
          <h1 className="text-main text-md font-normal py-0.5">Membership</h1>
          <h1 className="text-main text-md font-normal py-0.5">Customers</h1>
        </div>
      </div>
      <div
        onClick={() => navigate("all-customers")}
        className=" p-2 bg-white rounded-3xl hover:bg-sub cursor-pointer"
      >
        <h1 className="text-main text-md font-medium py-1 text-center">
          ALL CUSTOMERS
        </h1>
      </div>
    </div>
  );
};

export default CustomerDashboard;
