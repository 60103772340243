const Input = ({
  id,
  type,
  value,
  onChange,
  name,
  hidden,
  styles,
  placeholder,
  checked,
  readOnly
}) => {
  return (
    <input
      id={id}
      type={type}
      onChange={onChange}
      value={value}
      name={name}
      placeholder={placeholder}
      className={styles}
      checked={checked}
      readOnly={readOnly}

    />
  );
};

export default Input;
