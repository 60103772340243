import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getOrderRequest({ payload: {
  token,
  rideId,
},}) {
  console.log(token,rideId);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/get-orders/${rideId}`,
  });
}

export function addOrderRequest({
  payload: {
    token,
    formValues: { ...data },
    rideId,
    mainRoute,
    subRoute

  },
}){
  console.log('mthokeyaanu ');
  console.log(rideId);
    console.log(token);
    console.log(data);
    data.rideId=rideId
    data.mainRoute=mainRoute
    data.subRoute=subRoute
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    data: JSON.stringify( data),

    method: "post",
    url: `${BACKEND_URL}/order/create-order`,
  });
}
export function getRideCustomerOrderRequest(
  token,
  cusId,
  rideId,
) {
  console.log(token,rideId,cusId);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/ride-order/${cusId}?rideId=${rideId}`,
  });
}
