import React, { useState } from "react";
import { CiLocationOn } from "react-icons/ci";

function LocationButton({ buttonText, onLocationChange, onError }) {
  const handleGetLocation = (e) => {
    e.preventDefault(); 
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude;
          const log = position.coords.longitude;

          onLocationChange({ lat, log });
        },
        function (error) {
          alert(
            'To allow access to your location, please follow these steps:\n\n1. Open your Chrome settings (click on the three vertical dots ⋮ in the top right corner).\n2. Navigate to "Site Settings."\n3. Find "Privacy and Security."\n4. Under "Privacy and Security," locate "Location" or "Location Services" and click on it.\n5. Enable location access for your browser (e.g., vwl.kxence.store).\n6. Return to the website and try again.'

            );
        }
      );
    } else {
      onError("Geolocation is not supported in your browser");
    }
  };

  return (
    <div className="flex justify-center items-center">
      <button
        onClick={(e)=>handleGetLocation(e)}
        className="bg-main text-white font-medium py-1 px-4 rounded-lg w-full flex items-center justify-center"
      >
        <CiLocationOn className="mr-2" /> {/* Icon component */}
        {buttonText}
      </button>
    </div>
  );
}

export default LocationButton;
