import React, { useEffect, useState ,useRef} from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const AutocompleteDropdown = ({ customers, onSelect, bgWhite, label,error,errorMessage }) => {
  const [keyword, setKeyword] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (customers) setFilteredCustomers(customers);
  }, [customers]);

  const onKeyUp = (e) => {
    const inputKeyword = e.target.value;
    setShowDropdown(true);
    setKeyword(inputKeyword);
    const filteredCustomers = customers.filter(
      (customer) =>
        customer?.cusID?.toLowerCase().includes(inputKeyword.toLowerCase()) ||
        customer?.name?.toLowerCase().includes(inputKeyword.toLowerCase()) ||
        (typeof customer?.mobile === "number" &&
          customer.mobile.toString().includes(inputKeyword))
    );
    setFilteredCustomers(filteredCustomers);
  };

  const selectOption = (selectedCustomer) => {
    hideDropdown();
    console.log(selectedCustomer);
    setKeyword(selectedCustomer.name);
    onSelect(selectedCustomer); // Call the provided onSelect callback
  };

  const hideDropdown = () => {
    // setKeyword("");
    setShowDropdown(!showDropdown);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevent event propagation to parent elements
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };
  const handleLabelClick = () => {
    // Focus the input field when the label is clicked
    // setLabelMerged(true);

    inputRef.current.focus();
    setShowDropdown(!showDropdown);

  };
  return (
    <div className="w-full relative" onClick={(e) => e.stopPropagation()}>
      <div className="relative">
      {error ? (
        <label
          className={`absolute left-2 z-50 -top-2 bg-white text-xs text-red-600 transition-all`}
        >
          {errorMessage}
        </label>
      ) : (
        <label
        className={`absolute left-2 transition-all z-50 ${
          keyword
            ? "-top-2 bg-white text-xs text-gray-600"
            : "top-0.5 text-sm bg-mud hidden"
        }`}
        // onClick={handleLabelClick}
      >
        {label}
      </label>
      )}
        {/* {label && (
          <label
            className={`absolute left-2 transition-all z-50 ${
              keyword
                ? "-top-2 bg-white text-xs text-gray-600"
                : "top-0.5 text-sm bg-mud hidden"
            }`}
            onClick={handleLabelClick}
          >
            {label}
          </label>
        )} */}
        <input
          id="autocompleteInput"
          placeholder="Search for a customer"
          ref={inputRef} // Ref added to the input element

          className={`px-3 py-1 w-full border border-sub  ${
            bgWhite ? "bg-white" : "bg-mud"
          } placeholder-black rounded-md relative  focus:outline-none focus:border-sky-500 focus:ring-sky-500`}
          onChange={onKeyUp}
          onClick={hideDropdown}
          value={keyword}
          autoComplete="off"
        />
        <div className="pointer-events-none absolute inset-y-1 right-0 bottom-2 flex items-center px-2 text-gray-700">
          {showDropdown ? <AiFillCaretUp /> : <AiFillCaretDown />}
        </div>
      </div>
      <div
        id="dropdown"
        className={`w-full h-60 border border-gray-300 rounded-md bg-white absolute overflow-y-auto ${
          showDropdown ? "" : "hidden"
        }`}
        style={{ zIndex: 1 }}
      >
        {filteredCustomers.map((customer) => (
          <div
            key={customer._id}
            onClick={() => selectOption(customer)}
            className="px-5 py-1 border-b text-xs border-gray-200 text-stone-600 cursor-pointer hover:bg-mud"
            style={{
              color: customer.color,
            }}
          >
            {customer.name} ({customer.mobile})({customer.cusID})
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutocompleteDropdown;
