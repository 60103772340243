/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as yup from "yup";
import Input from "../input/Input";
import TextArea from "../textArea/TextArea";
import Select from "../select/Select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addCustomer,
  setCustomerError,
  setCustomerSuccess,
} from "../../redux/ducks/CustomerSlice";
import { getRide } from "../../config/api/Api";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SendLoading from "../../layouts/Loading";
import { setSendLoading } from "../../redux/ducks/LoadingSlice";
import LocationButton from "../mapBox/CurrentLocation";
import { getCustomerIDRequest } from "../../redux/sagas/requests/CustomerRequest";
import CustomInput from "../customInput/CustomInput";
import SelectCustom from "../select/SelectCustom";
import CustomTextArea from "../textArea/CustomTextArea";
import Nationality from "../Countries/Nationailty";
import CustomSearch from "../selectBySearch/CustomSearch";
const selectMode = [{ name: "cash" }, { name: "credit" },{name:'foc'}];
const customerType = [
  { name: "Family" },
  { name: "Corporate" },
  { name: "Bussiness" },
  { name: "Others" },
];
const nationality = [
  { name: "Indian" },
  { name: "Pakistan" },
  { name: "UAE" },
  { name: "Nepal" },
];

const rateofemptybotte = 17;

const initialValues = {
  secondName: "",
  firstName: "",
  mobile: "",
  whatsAppNo: "",
  Address: "",
  mainRoute: "",
  subRoute: "",
  numberOfBottles: null,
  depositAmount: "",
  paymentMode: "",
  amount: null,
  Nationality: "",
  customerType: "",
  buildingNo: "",
  flatNo: "",
  streetNo: "",
  Email: "",
  Remarks: "",
  landMark: "",
  coordinates: "",
  cusID: "",
};

const customerSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .test(
      "is-capitalized",
      "First letter must be capitalized",
      function (value) {
        if (!value) return true; // Allow empty values
        const firstLetter = value.charAt(0);
        return firstLetter === firstLetter.toUpperCase();
      }
    ),
  secondName: yup
    .string()
    .required("Second Name is required")
    .test(
      "is-capitalized",
      "First letter must be capitalized",
      function (value) {
        if (!value) return true; // Allow empty values
        const firstLetter = value.charAt(0);
        return firstLetter === firstLetter.toUpperCase();
      }
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
  whatsAppNo: yup.string().required("Whatsapp number is required"),
  Address: yup.string().required("Address is required"),
  mainRoute: yup.string().required("Main route is required"),
  subRoute: yup.string().required("Sub route is required"),
  Nationality: yup.string().required("Nationality is required"),
  customerType: yup.string().required("Customer Type is required"),
  streetNo: yup.string().required("Street No is required"),
  landMark: yup.string().required("Land Mark is required"),

  buildingNo: yup.string().required("Building No is required"),
  flatNo: yup.string().required("Flat No is required"),
  Remarks: yup.string(),

  Email: yup.string().email("Invalid email format"), // Use the email() method to check for a valid email format

  numberOfBottles: yup
    .string()
    .required("No. of bottles is required")
    .test("invalid", "invalid", (value) => {
      const val = parseInt(value);
      return val > 0;
    }),
  depositAmount: yup.string(),
  paymentMode: yup.string().required("Payment mode is required"),
  amount: yup
    .string()
    .required("Amount is required")
    .test("invalid", "invalid", (value) => {
      const val = parseInt(value);
      return val > 0;
    }),
});

const AddCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [sameAs, setSameAs] = useState(false);
  const { addCustomerEror } = useSelector((state) => state.customers);
  const { addCustomerSuccess } = useSelector((state) => state.customers);
  const { sendLoading } = useSelector((state) => state.loading);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const [text, setText] = useState(" GPS");
  const [coordiantes, setCoordinate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [Nationality, setNationality] = useState(nationality);


  const { mainRoute, subRoute } = useSelector((state) => state.ride);
  console.log(formValues);
  useEffect(() => {
    if (formValues.numberOfBottles) {
      setFormValues({
        ...formValues,
        depositAmount: rateofemptybotte * formValues.numberOfBottles,
      });
    }
  }, [formValues.numberOfBottles]);

  useEffect(() => {
    return () => {
      dispatch(setSendLoading(false));
    };
  }, []);
// useEffect(()=>{
//   fetch('https://restcountries.com/v3.1/all')
//   .then(response => response.json())
//   .then(data => {
//     const countries = data.map(country => ({ name: country.name.common }));
//     console.log(countries);
//     setNationality(countries)
//   })
//   .catch(error => {
//     console.error('Error fetching country data:', error);
//   });
// },[])
  useEffect(() => {
    const fetchData = async () => {
      if (mainRoute && subRoute) {
        try {
          const { data } = await getCustomerIDRequest(
            token,
            mainRoute,
            subRoute
          );
          setFormValues({
            ...formValues,
            cusID: data.id,
            mainRoute: mainRoute,
            subRoute: subRoute,
          });
        } catch (error) {
          // Handle any errors that occur during the request
          console.error(error);
        }
      }
    };

    fetchData(); // Call the asynchronous function
  }, [mainRoute, subRoute]);

  useEffect(() => {
    if (addCustomerEror) {
      toast.error(" Something went wrong!", {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      if (addCustomerEror.message === "Mobile No Already exists") {
        setFormErrors({ ...formErrors, mobile: addCustomerEror.message });
      }
    }
    return () => {
      dispatch(setCustomerError(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCustomerEror]);
  useEffect(() => {
    if (addCustomerSuccess) {
      toast.success(" customer added successfully!", {
        position: "bottom-center",
        autoClose: 300,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          // This code will execute after the toast is closed
          navigate(-1); // Navigate back to the previous page
        },
      });
    }
    return () => {
      dispatch(setCustomerSuccess(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCustomerSuccess]);

  const [Route, setRoute] = useState([]);
  // useEffect(() => {
  //   if (mainRoute) {
  //     setFormValues({
  //       ...formValues,
  //       mainRoute: mainRoute,
  //       subRoute: subRoute,
  //     });
  //   }
  // }, [mainRoute, subRoute]);

  useEffect(() => {
    getRide()
      .then(({ data }) => {
        console.log(data);

        setRoute(data.routes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSameAsmobile = () => {
    setSameAs(!sameAs);
    setFormValues((prev) => ({
      ...prev,
      whatsAppNo: !sameAs ? formValues.mobile : "",
    }));
    setFormErrors({ ...formErrors, whatsAppNo: null });
  };
  console.log(formValues, "formvalues");
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    try {
      if (value) {
        await yup.reach(customerSchema, name).validate(value);
      }
      if (name === "numberOfBottles") {
        setFormErrors({ ...formErrors, [name]: null, depositAmount: null });
      } else {
        setFormErrors({ ...formErrors, [name]: null });
      }
    } catch (error) {
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await customerSchema.validate(formValues, { abortEarly: false });
      formValues.name = formValues.firstName + " " + formValues.secondName;
      dispatch(addCustomer({ formValues, token }));
      dispatch(setSendLoading(true));
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };
  const onLocationChange = (value) => {
    setCoordinate(value);
    setFormValues({ ...formValues, coordinates: value });

    setIsModalOpen(true);
  };
  useEffect(() => {
    if (coordiantes) {
      setText("change");
    }
  }, [coordiantes]);
  console.log(formValues);
  console.log(coordiantes);
  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-10 select-none">
      <div className="bg-white p-3 rounded-md ">
        <form onSubmit={handleSubmit}>
          <label className="text-red-350">{formErrors?.customerID}</label>

          <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.cusID}
            name={"cusID"}
            placeholder={"LRS-001"}
            styles={`w-full px-3 py-0.5 mb-3 bg-white border shadow-sm  ${
              formErrors?.cusID
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
            readOnly={true}
          />
          {/* <label className="text-red-350">{formErrors?.firstName}</label> */}
          <CustomInput
            value={formValues.firstName}
            onChange={handleInputChange}
            label={"First Name"}
            name={"firstName"}
            type={"text"}
            error={formErrors?.firstName}
            errorMessage={formErrors?.firstName}
          ></CustomInput>
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={formValues.secondName}
            name={"secondName"}
            error={formErrors.secondName}
            errorMessage={formErrors.secondName}
            label={"Second Name"}
          ></CustomInput>

          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.firstName}
            name={"firstName"}
            placeholder={"First Name"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.firstName
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          />
          <label className="text-red-350">{formErrors?.secondName}</label> */}

          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.secondName}
            name={"secondName"}
            placeholder={"Second Name"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.secondName
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}

          <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.mobile}
            name={"mobile"}
            error={formErrors?.mobile}
            errorMessage={formErrors?.mobile}
            label={"Mobile"}
          ></CustomInput>

          {/* <label className="text-red-350">{formErrors?.mobile}</label>
          <Input
            type={"number"}
            onChange={handleInputChange}
            value={formValues.mobile}
            name={"mobile"}
            placeholder={"Mobile No."}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.mobile
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.whatsAppNo}
            name={"whatsAppNo"}
            error={formErrors?.whatsAppNo}
            errorMessage={formErrors?.whatsAppNo}
            label={"Whatsapp No"}
          ></CustomInput>

          {/* <label className="text-red-350">{formErrors?.whatsAppNo}</label>
          <Input
            type={"number"}
            onChange={handleInputChange}
            value={formValues.whatsAppNo}
            name={"whatsAppNo"}
            placeholder={"Whatsapp No."}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.whatsAppNo
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <div className="flex items-center mb-3 px-3.5 text-sm text-mud">
            Same as Mobile No.{" "}
            <Input
              type="checkbox"
              onChange={handleSameAsmobile}
              styles={"w-4 h-4 cursor-pointer ml-2"}
              checked={sameAs}
            />
          </div>
          {/* <label className="text-red-350">{formErrors?.Address}</label> */}
          {/* <TextArea
            onChange={handleInputChange}
            value={formValues.Address}
            name={"Address"}
            placeholder={"Address"}
            style={`w-full px-3 py-0.5 h-14 mb-3 bg-mud border shadow-sm  ${
              formErrors?.Address
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <CustomTextArea
            onChange={handleInputChange}
            value={formValues.Address}
            name={"Address"}
            label={"Address"}
            error={formErrors.Address}
            errorMessage={formErrors.Address}
          ></CustomTextArea>
          {/* <Select
            name={"mainRoute"}
            options={mainRoute}
            label={formErrors?.mainRoute}
            onChange={handleMainRouteChange}
            defaultValue={{ name: "Main route" }}
            labelStyles={"text-red-350 text-sm"}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.mainRoute
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
            childStyles={"cursor-pointer"}
          /> */}
          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={mainRoute}
            name={"name"}
            placeholder={"Name"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.mainRoute
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
            readOnly={true}
          /> */}
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={mainRoute}
            name={"mainRoute"}
            label={"Main Route"}
            error={formErrors?.mainRoute}
            errorMessage={formErrors?.mainRoute}
            readOnly={true}
          ></CustomInput>
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={subRoute}
            name={"subRoute"}
            label={"Sub Route"}
            error={formErrors?.subRoute}
            errorMessage={formErrors?.subRoute}
            readOnly={true}
          ></CustomInput>
          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={subRoute}
            name={"name"}
            placeholder={"Name"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.subRoute
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
            readOnly={true}
          /> */}
          <SelectCustom
            name={"customerType"}
            options={customerType}
            label={"Customer Type"}
            onChange={handleInputChange}
            error={formErrors.customerType}
            errorMessage={formErrors.customerType}
            value={formValues.customerType}
            bgColor={'white'}
          ></SelectCustom>
          {/* <Select
            name={"customerType"}
            options={customerType}
            label={formErrors?.customerType}
            onChange={handleInputChange}
            labelStyles={"text-red-350 text-sm"}
            defaultValue={{ name: "Select Customer Type" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.customerType
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <SelectCustom
            name={"Nationality"}
            options={Nationality}
            label={"Nationality"}
            onChange={handleInputChange}
            error={formErrors.Nationality}
            errorMessage={formErrors.Nationality}
            value={formValues.Nationality}
            bgColor={'white'}

          ></SelectCustom>
          {/* <CustomSearch Obj={Nationality} label={'Nationality'} onSelect={handleInputChange} > </CustomSearch> */}
          {/* <Select
            name={"Nationality"}
            options={Nationality}
            label={formErrors?.Nationality}
            onChange={handleInputChange}
            labelStyles={"text-red-350 text-sm"}
            defaultValue={{ name: "Select Nationality" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.Nationality
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          />
          <label className="text-red-350">{formErrors?.landMark}</label> */}
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={formValues.landMark}
            name={"landMark"}
            error={formErrors?.landMark}
            errorMessage={formErrors?.landMark}
            label={"Land Mark"}
          ></CustomInput>
          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.landMark}
            name={"landMark"}
            placeholder={"Enter Land Marks"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.landMark
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          />
          <label className="text-red-350">{formErrors?.streetNo}</label> */}
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={formValues.streetNo}
            name={"streetNo"}
            error={formErrors?.streetNo}
            errorMessage={formErrors?.streetNo}
            label={"Street No"}
          ></CustomInput>
          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.streetNo}
            name={"streetNo"}
            placeholder={"Enter Street No"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.streetNo
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          />
          <label className="text-red-350">{formErrors?.buildingNo}</label> */}
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={formValues.buildingNo}
            name={"buildingNo"}
            error={formErrors?.buildingNo}
            errorMessage={formErrors?.buildingNo}
            label={"Building No"}
          ></CustomInput>
          {/* <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.buildingNo}
            name={"buildingNo"}
            placeholder={"Enter Building No"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.buildingNo
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          />
          <label className="text-red-350">{formErrors?.flatNo}</label> */}
          {/* <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={formValues.flatNo}
            name={"flatNo"}
            error={formErrors?.flatNo}
            errorMessage={formErrors?.flatNo}
            label={"Flat No"}
          ></CustomInput> */}
          <div className="flex flex-row justify-between gap-2 ">
            {/* <Input
              type={"text"}
              onChange={handleInputChange}
              value={formValues.flatNo}
              name={"flatNo"}
              placeholder={"Enter Flat No"}
              styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
                formErrors?.flatNo
                  ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                  : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
            /> */}
            <div className="w-full pt-2   ">
              <CustomInput
                type={"text"}
                onChange={handleInputChange}
                value={formValues.flatNo}
                name={"flatNo"}
                error={formErrors?.flatNo}
                errorMessage={formErrors?.flatNo}
                label={"Flat No"}
              ></CustomInput>
            </div>

            <div className="mb-3 W-1/2 pt-2 ">
              <LocationButton
                buttonText={text}
                onLocationChange={onLocationChange}
              ></LocationButton>
            </div>
          </div>
          <CustomInput
            type={"text"}
            onChange={handleInputChange}
            value={formValues.Email}
            name={"Email"}
            error={formErrors?.Email}
            errorMessage={formErrors?.Email}
            label={"Email"}
          ></CustomInput>

          {/* <label className="text-red-350">{formErrors?.Email}</label>
          <Input
            type={"text"}
            onChange={handleInputChange}
            value={formValues.Email}
            name={"Email"}
            placeholder={"Enter Email"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.Email
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.numberOfBottles}
            name={"numberOfBottles"}
            error={formErrors?.numberOfBottles}
            errorMessage={formErrors?.numberOfBottles}
            label={"No of Bottles"}
          ></CustomInput>
          {/* <label className="text-red-350">{formErrors?.numberOfBottles}</label>
          <Input
            type={"number"}
            onChange={handleInputChange}
            value={formValues.numberOfBottles}
            name={"numberOfBottles"}
            placeholder={"No. of bottles"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.numberOfBottles
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          {/* <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.depositAmount}
            name={"depositAmount"}
            error={formErrors?.depositAmount}
            errorMessage={formErrors?.depositAmount}
            label={"Deposit Amount"}
          ></CustomInput> */}
          {/* <label className="text-red-350">{formErrors?.depositAmount}</label>
          <Input
            type={"number"}
            // onChange={handleInputChange}
            value={formValues.depositAmount}
            name={"depositAmount"}
            placeholder={"Deposit Amount"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.depositAmount
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
            readOnly={true}
          /> */}
          {/* <Select
            name={"paymentMode"}
            options={selectMode}
            label={formErrors?.paymentMode}
            onChange={handleInputChange}
            labelStyles={"text-red-350 text-sm"}
            defaultValue={{ name: "Select Mode" }}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.paymentMode
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <SelectCustom
            name={"paymentMode"}
            options={selectMode}
            error={formErrors?.paymentMode}
            errorMessage={formErrors?.paymentMode}
            label={"Payment Mode"}
            value={formValues.paymentMode}
            onChange={handleInputChange}
            bgColor={'white'}

          ></SelectCustom>
          <CustomInput
            type={"number"}
            onChange={handleInputChange}
            value={formValues.amount}
            name={"amount"}
            error={formErrors?.amount}
            errorMessage={formErrors?.amount}
            label={"Rate per bottle"}
          ></CustomInput>
          {/* <label className="text-red-350">{formErrors?.amount}</label>
          <Input
            type={"number"}
            onChange={handleInputChange}
            value={formValues.amount}
            name={"amount"}
            placeholder={"Amount"}
            styles={`w-full px-3 py-0.5 mb-3 bg-mud border shadow-sm  ${
              formErrors?.amount
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}

          {/* <label className="text-red-350">{formErrors?.Remarks}</label>
          <TextArea
            onChange={handleInputChange}
            value={formValues.Remarks}
            name={"Remarks"}
            placeholder={"Remarks"}
            style={`w-full px-3 py-0.5 h-10 mb-3 bg-mud border shadow-sm  ${
              formErrors?.Remarks
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <CustomTextArea
            onChange={handleInputChange}
            value={formValues.Remarks}
            name={"Remarks"}
            label={"Remarks"}
            error={formErrors.Remarks}
            errorMessage={formErrors.Remarks}
          ></CustomTextArea>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-main text-white font-medium py-2 px-4 rounded-md mt-6 w-52"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {sendLoading ? <SendLoading></SendLoading> : ""}
      <ToastContainer />
    </div>
  );
};

export default AddCustomer;
