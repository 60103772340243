import { createSlice } from "@reduxjs/toolkit";

const SummarySlice = createSlice({
  name: "summary",
  initialState: {
    Summary: '',
    SummaryLoading : false,



  },
  reducers: {
    getSummary() { },
    addSummary(){},

   
    setSummaryLoading(state, action) {
      console.log('working action succes payload');
      console.log(action);
      return { ...state, SummaryLoading : action.payload }
    }


  },
});

export const { getSummary,  setSummaryLoading ,addSummary} = SummarySlice.actions;

export default SummarySlice.reducer;
