import * as React from "react";
import Map, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { BsStar } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MdDirections } from "react-icons/md";
import { getCustomer } from "../../redux/ducks/CustomerSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./map.css";
const apiKey = process.env.REACT_APP_MAP_API_KEY;
function MapPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setvalue] = React.useState(null);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const rideId = useSelector((state) => state.ride._id);
  const { customers } = useSelector((state) => state.customers);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  const handleSearch = (query) => {
    setSearchQuery(query);

    // Filter customers based on the search query
    const filtered = customers.filter((customer) => {
      // Convert both the customer name and mobile number to lowercase for case-insensitive search
      const customerName = customer.name.toLowerCase();
      const mobileNumber = customer.mobile.toString();

      // Check if either the customer name or mobile number includes the query
      return (
        customerName.includes(query.toLowerCase()) ||
        mobileNumber.includes(query.toString())
      );
    });
    setFilteredCustomers(filtered);
  };

  useEffect(() => {
    dispatch(getCustomer({ token, rideId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (customers.length > 0) {
      setFilteredCustomers(customers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);
  function handlePop(id) {
    setvalue(id);
  }

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const direction = (lat, log) => {
    window.location.href = `https://www.google.com/maps?q=${lat},${log}`;
  };
  const handlePopupClose = () => {
    // This function will be called when the popup is closed.
    // You can add your custom logic here.
    // Add any additional actions you want to perform when the popup is closed.
    setvalue(null); // Close the popup by setting popupInfo to null
  };
  const closeButtonStyle = {
    fontSize: "154px", // Adjust the font size to increase the button size
    width: "130px", // Adjust the width to increase the button size
    height: "130px", // Adjust the height to increase the button size
  };
  return (
    <div className="w-screen h-screen">
      <div className="h-full relative">
        <div className="absolute inset-y-0 left-0 flex items-center ">
          <Map
            initialViewState={{
              latitude: 23.4241,
              longitude: 53.8478,
              zoom: 4,
            }}
            style={{ width: "100vw", height: "100vh" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={apiKey}
          >
            <NavigationControl />
            <GeolocateControl />
            {filteredCustomers?.map((item, index) => (
              <div key={index}>
                {item.coordinates?.lat && item.coordinates?.log ? (
                  <Marker
                    longitude={item.coordinates.log}
                    latitude={item.coordinates.lat}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="w-full h-6 rounded-md bg-white pt-1 cursor-pointer shadow-md"
                      onClick={() => handlePop(item._id)}
                    >
                      <p className="text-md font-bold">{item.name}</p>
                    </div>
                  </Marker>
                ) : null}
                {item._id == value && (
                  <Popup
                    longitude={item.coordinates.log}
                    latitude={item.coordinates.lat}
                    closeButton={true}
                    anchor="top"
                    closeOnClick={false}
                    maxWidth={300}
                    onClose={handlePopupClose}
                    className="custom-popup"
                  >
                    <div>
                      <div className="flex flex-row items-center rounded-full">
                        <img
                          src="\userProfile.74cda66309e83509d2bd.avif"
                          className="w-24 h-24"
                          style={{ borderRadius: "100%" }}
                        />
                        <div className="max-w-64 p-4 space-y-1">
                          <p className="text-md font-semibold">{item.name}</p>
                          <MdDirections
                            fontSize="34px"
                            color="green"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              direction(
                                item.coordinates.lat,
                                item.coordinates.log
                              );
                            }}
                          />
                          <ul className="list-disc ml-4">
                            <li className="text-xs">
                              <span className="font-normal">Address:</span>{" "}
                              {item.Address}
                            </li>
                            <li className="text-xs">
                              <span className="font-normal">Street No:</span>{" "}
                              {item.streetNo}
                            </li>
                            <li className="text-xs">
                              <span className="font-normal">Landmark:</span>{" "}
                              {item.landMark}
                            </li>
                            <li className="text-xs">
                              <span className="font-normal">
                                Building No:
                              </span>{" "}
                              {item.buildingNo}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Popup>
                )}
              </div>
            ))}
          </Map>
        </div>
        <div className="fixed w-full p-4 flex justify-center items-center">
          <form className="w-full md:w-1/2 pr-5">
            <label className="relative block">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 fill-black"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <path d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z"></path>
                </svg>
              </span>
              <input
                className="w-full bg-white placeholder:font-italitc border border-sub rounded-full py-2 pl-10 pr-4 focus:outline-none"
                placeholder="Search"
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </label>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MapPage;
