import { createSlice } from "@reduxjs/toolkit";
const rideSlice = createSlice({
  name: "ride",
  initialState: null,

  reducers: {
    setRide(state, action) {
      const ride = action.payload;
      return { ...state, ...ride };
    },
    resetRide(state, action) {
      return null; // Reset the state to initial state (null)
    },
   
    addRide() {},
    getRide(){},
    endRide(){}
  },
});

export const { setRide ,addRide,getRide,endRide,resetRide} = rideSlice.actions;

export default  rideSlice.reducer;
