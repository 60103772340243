import { call, put } from "redux-saga/effects";
import { addStockRequest, getStockRequest } from "../requests/StockRequest";
import { setStock } from "../../ducks/StockSlice";
import { setLoading } from "../../ducks/LoadingSlice";
import handleAuthError from "./HandleAuthErrors";

export function* handleGetStock(action) {
  console.log(action);
  try {
    const { data } = yield call(getStockRequest, action);
    if (data) {
      yield put(setStock(data));
    }
    yield put(setLoading({ loading: false }));
  } catch (error) {
    console.log(error);
    handleAuthError(error);
  }
}

export function* handleAddStock(action) {
  try {
    const { data } = yield call(addStockRequest, action);
    if (data) {
      yield put(setStock(data));
    } else {
      throw Error("Stock is not added to the data base");
    }
  } catch (error) {
    console.log(error);
  }
}
