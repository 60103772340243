import { useNavigate } from "react-router-dom";

const StartRideSection = () => {
  const navigate = useNavigate();


  return (
    <div className="flex min-h-base justify-center items-center pb-14">
      <div
        onClick={() => navigate("/start-your-ride")}
        className="rounded-full w-48 h-48 bg-white flex flex-col items-center justify-center cursor-pointer hover:bg-sub"
      >
        <h1 className="text-main text-2xl font-medium">Start your</h1>
        <h1 className="text-main text-2xl font-medium">Ride</h1>
      </div>
    </div>
  );
};

export default StartRideSection;
