import React, { useState } from "react";
import Select, { StylesConfig } from "react-select";

const CustomerSearch = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  const customers = [
    { id: 1, name: "John Doe", mobile: "123456-7890" },
    { id: 2, name: "Jane Smith", mobile: "987654-3210" },
    { id: 3, name: "ane Smith", mobile: "87654-3210" },
    { id: 4, name: "ne Smith", mobile: "7654-3210" },
    // ... other customer data
  ];

  const handleInputChange = (newValue) => {
    if (!newValue) {
      // If input is empty, reset the options
      setOptions([]);
      return;
    }

    const inputNumber = Number(newValue);
    if (!isNaN(inputNumber)) {
      // If input can be converted to a number, filter by ID
      const filteredOptions = customers.filter(
        (customer) =>
          customer.id.toString().includes(inputNumber.toString())
      );
      setOptions(
        filteredOptions.map((customer) => ({
          value: customer.id.toString(),
          label: customer.name,
        }))
      );
    } else {
      // If input is a string, filter by name or mobile
      const filteredOptions = customers.filter(
        (customer) =>
          customer.name.toLowerCase().includes(newValue.toLowerCase()) ||
          customer.mobile.includes(newValue)
      );
      setOptions(
        filteredOptions.map((customer) => ({
          value: customer.id.toString(),
          label: customer.name,
        }))
      );
    }
  };

 
     

     
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: 6,
      minHeight: '10px !important', // Adjust the border curve here
      border: '1px solid #B3E5AA', // Border style
      boxShadow: 'none', // Remove default box shadow
      backgroundColor: "white",
      height: '30px',
      fontSize: '15px',
    }),
    option: (defaultStyles, state) => ({
        ...defaultStyles,
        color: state.isSelected ? "#212529" : "#fff",
        backgroundColor: state.isSelected ? 'red' : "#212529",
        width:'100%'
      }),
    indicatorsContainer: (provided) => ({
      ...provided,
    //  backgroundColor: "red",  
     height: '30px',

      // Customize indicator arrow color
    }),
  };
  

  return (
    <Select
      value={selectedOption}
      onChange={(selected) => setSelectedOption(selected)}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      isSearchable
      placeholder="Search for a customer..."
      styles={customStyles} // Apply custom styles to the select container
    />
  );
};

export default CustomerSearch;
