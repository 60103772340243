import { takeLatest, fork, all } from "redux-saga/effects";
import { userLogin } from "../ducks/UserSlice";
import { addStock, getStock } from "../ducks/StockSlice";
import { addOrder, getOrder } from "../ducks/OrderSlice";
import { addRide, endRide, getRide } from "../ducks/RideSlice";

import { handleUserLogin } from "./handlers/UserHandler";
import { handleAddStock, handleGetStock } from "./handlers/StockHandler";
import { handleAddOrder, handleGetOrder } from "./handlers/OrderHandler";
import {
  handleAddRide,
  handleEndRide,
  handleGetRide,
} from "./handlers/rideHandler";

import {
  handleAddCustomer,
  handleGetCustomer,
  handleGetCustomerDashboard,
  handleGetSingleCustomer,
  handleGetCustomerOrderHistory
} from "./handlers/CustomerHandler";
import {
  addCustomer,
  getCustomer,
  getCustomerDashboard,
  getSingleCustomer,
  getCustomerOrderHistory
  
} from "../ducks/CustomerSlice";
import {
  handleGetCreditCustomer,
  handleGetPaymentCollection,
  handlePayCredit,
  handleGetPaymentHistory
} from "./handlers/CreditCustomerHandler";
import {
  getCreditCustomers,
  getPaymentCollection,
  payCredit,
  getPaymentHistory
} from "../ducks/CreditCustomerSlice";

import {
  handleAddreturn,
  handleGetReturnCustomer,
} from "./handlers/ReturnHandler";
import { addReturn, getReturnCustomers } from "../ducks/ReturnSlice";

import { getVisitHistory } from "../ducks/VisitHistorySlice";
import { handleGetVisitHistory } from "./handlers/VisitHistoryHandler";

import { handleAddSummary } from "./handlers/SummaryHandler";
import { addSummary } from "../ducks/SummarySlice";

import { addMembership, getMembership } from "../ducks/MembershipSlice";
import {
  handleAddMembership,
  handleGetMembershipCustomers,
} from "./handlers/MemberHandler";

import { addVisitHistory } from "../ducks/VisitHistorySlice";

import { handleAddVisitHistory } from "./handlers/VisitHistoryHandler";

import {
  getCashSales,
  getCreditSales,
  getTodaySales,
} from "../ducks/SalesSlice";

import {
  handleGetCashSales,
  handleGetCreditSales,
  handleGetTodaySales,
} from "./handlers/SalesHandler";

// login watchers
export function* watchLoginUser() {
  yield takeLatest(userLogin, handleUserLogin);
}

// stock watchers
export function* watchGetStock() {
  yield takeLatest(getStock, handleGetStock);
}

export function* watchAddStock() {
  yield takeLatest(addStock, handleAddStock);
}
export function* watchGetOrder() {
  yield takeLatest(getOrder, handleGetOrder);
}
export function* watchAddRide() {
  yield takeLatest(addRide, handleAddRide);
}
export function* watchGetRide() {
  yield takeLatest(getRide, handleGetRide);
}
export function* watchEndRide() {
  yield takeLatest(endRide, handleEndRide);
}

export function* watchAddOrder() {
  console.log("WORKING");
  yield takeLatest(addOrder, handleAddOrder);
}
export function* watchReturnBottle() {
  console.log("WORKING");
  yield takeLatest(addReturn, handleAddreturn);
}

// customer watchers
export function* watchGetCustomer() {
  yield takeLatest(getCustomer, handleGetCustomer);
}
export function* watchGetCreditCustomer() {
  yield takeLatest(getCreditCustomers, handleGetCreditCustomer);
}
export function* watchGetCustomersDashboard() {
  yield takeLatest(getCustomerDashboard, handleGetCustomerDashboard);
}
export function* watchGetVisitHistory() {
  yield takeLatest(getVisitHistory, handleGetVisitHistory);
}
export function* watchAddVisitHistory() {
  yield takeLatest(addVisitHistory, handleAddVisitHistory);
}
export function* watchAddSummary() {
  yield takeLatest(addSummary, handleAddSummary);
}
export function* watchGetPaymentCollection() {
  yield takeLatest(getPaymentCollection, handleGetPaymentCollection);
}
export function* watchAddCustomer() {
  yield takeLatest(addCustomer, handleAddCustomer);
}
export function* watchGetMembershipCustomers() {
  yield takeLatest(getMembership, handleGetMembershipCustomers);
}
export function* watchPayCredit() {
  yield takeLatest(payCredit, handlePayCredit);
}
export function* watchAddMembership() {
  yield takeLatest(addMembership, handleAddMembership);
}
export function* watchGetSingleCustomer() {
  yield takeLatest(getSingleCustomer, handleGetSingleCustomer);
}
export function* watchGetReturnCustomer() {
  yield takeLatest(getReturnCustomers, handleGetReturnCustomer);
}
export function* watchGetTodaySale() {
  yield takeLatest(getTodaySales, handleGetTodaySales);
}
export function* watchGetCreditSale() {
  yield takeLatest(getCreditSales, handleGetCreditSales);
}
export function* watchGetCashSale() {
  yield takeLatest(getCashSales, handleGetCashSales);
}
export function* watchGetCustomerOrderHistory() {
  yield takeLatest(getCustomerOrderHistory, handleGetCustomerOrderHistory);
}

export function* watchGetPaymentHistory() {
  yield takeLatest(getPaymentHistory, handleGetPaymentHistory);
}
export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchGetStock),
    fork(watchAddStock),
    fork(watchAddRide),
    fork(watchGetOrder),
    fork(watchAddOrder),
    fork(watchGetCustomer),
    fork(watchAddCustomer),
    fork(watchGetCreditCustomer),
    fork(watchReturnBottle),
    fork(watchGetPaymentCollection),
    fork(watchGetVisitHistory),
    fork(watchAddSummary),
    fork(watchEndRide),
    fork(watchPayCredit),
    fork(watchGetRide),
    fork(watchGetCustomersDashboard),
    fork(watchAddMembership),
    fork(watchAddVisitHistory),
    fork(watchGetMembershipCustomers),
    fork(watchGetSingleCustomer),
    fork(watchGetReturnCustomer),
    fork(watchGetTodaySale),
    fork(watchGetCashSale),
    fork(watchGetCreditSale),
    fork(watchGetCustomerOrderHistory),
    fork(watchGetPaymentHistory),





  ]);
}
