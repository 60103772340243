/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { addStock } from "../../redux/ducks/StockSlice";
import Input from "../input/Input";
import Select from "../select/Select";
import { useEffect, useState, useMemo } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { addSummary } from "../../redux/ducks/SummarySlice";

import SendLoading from "../../layouts/Loading";
import { setSendLoading } from "../../redux/ducks/LoadingSlice";
import { getRouteStockRequest } from "../../redux/sagas/requests/StockRequest";
import {
  getQuantityRequest,
  getSummaryStockRequest,
} from "../../redux/sagas/requests/SalesRequest";
import { ToastContainer, toast } from "react-toastify";
import { addSummaryRequest } from "../../redux/sagas/requests/SummaryRequest";
import { getRide } from "../../redux/ducks/RideSlice";
import CircularLoading from "../circularloading/CircularLoading";

const initialValues = {
  FOC: "",
  waterDamage: 0,
  capLeak: 0,
  netTotal: "",
  balanceStock: 0,
  openingStock: "",
  loadedStock: "",
  BottleLeak: 0,
  total: "",
  additionalStock: 0,
  emptyBottle: 0,
};

const ViewSummary = () => {
  const [openingStock, setOpeningStock] = useState(0);
  const [loadedStock, setLoadedeStock] = useState(0);
  const [additionalStock, setAdditionalStock] = useState(0);
  const [balanceStock, setBalanceStock] = useState(0);
  const [balance, setBalance] = useState(0);
  const [err, setErr] = useState(true);

  const {
    waterDamage,
    capLeak,
    BottleLeak,
    FOC,
    emptyBottle,
    soldBottle,
    mainRoute,
  } = useSelector((state) => state.ride) || {};
  const { token } = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const rideId = useSelector((state) => state.ride._id);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [sendLoading, setSendLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);

  const rideSchema = yup.object().shape({
    additionalStock: yup.string().test("", "invalid", (value) => {
      const net = parseInt(value);
      return net >= 0;
    }),
    FOC: yup.string(),
    waterDamage: yup.string().test("", "invalid", (value) => {
      const net = parseInt(value);
      return net >= 0 && err;
    }),
    netTotal: yup.string().test("", "invalid", (value) => {
      const net = parseInt(value);
      return net >= 0;
    }),
    capLeak: yup.string().test("", "invalid", (value) => {
      const net = parseInt(value);
      return net >= 0 && err;
    }),
    BottleLeak: yup.string().test("invalid", "invalid", (value) => {
      const net = parseInt(value);
      return net >= 0 && err;
    }),
    balanceStock: yup.string(),
  });

  const [stockItems, setStockItems] = useState([]);
  const [sold, setSold] = useState([]);
  useEffect(() => {
    dispatch(getRide({ token }));
  }, [refresh]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRouteStockRequest(token, mainRoute);
      setStockItems(data);
    };
    fetchData();
  }, [refresh]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { sold, emptyBottle } = await getQuantityRequest(token, rideId);
        // Handle data or set state here
        console.log(sold, "hi");
        if (sold) {
          setSold(sold);
        }
        // setEmpty(emptyBottle);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the asynchronous function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { openingStock, loadedStock, additionalStock, balanceStock } =
          await getSummaryStockRequest(token, rideId);
        setOpeningStock(openingStock);
        setAdditionalStock(additionalStock);
        setLoadedeStock(loadedStock);
        setBalanceStock(balanceStock);
        setBalance(balanceStock);
        // Handle data or set state here
        // console.log(sold, "hi");
        // setSold(sold);
        // setEmpty(emptyBottle);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the asynchronous function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const totalQuantity = stockItems.reduce(
    (total, obj) => total + obj.quantity,
    0
  );
  const totalSold = (sold && sold.length > 0 && sold[0]?.totalQuantity) || 0;

  const handleInputChange = async (e) => {
    let { name, value } = e.target;
    if (!value) {
      setFormValues({
        ...formValues,
        [name]: "",
      });
      return;
    }

    if (name === "additionalStock") {
      const total = (parseInt(value) || 0) + openingStock + loadedStock;
      const net =
        total -
        ((parseInt(formValues.waterDamage) || 0) +
          (parseInt(formValues.capLeak) || 0) +
          (parseInt(formValues.BottleLeak) || 0));
      setFormValues({
        ...formValues,
        [name]: value,
        total: total,
        netTotal: net,
      });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }

    try {
      await yup.reach(rideSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      console.log(error);
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };
  useEffect(() => {
    const data =
      (parseInt(formValues.BottleLeak, 10) || 0) +
      (parseInt(formValues.capLeak, 10) || 0) +
      (parseInt(formValues.waterDamage, 10) || 0);
    if (data > balance) {
      setErr(false);
    } else {
      setFormErrors({
        ...formErrors,
        BottleLeak: null,
        capLeak: null,
        waterDamage: null,
      });

      setErr(true);
    }
  }, [formValues.waterDamage, formValues.BottleLeak, formValues.capLeak]);

  useEffect(() => {
    const netTotal =
      parseInt(formValues.total) -
      ((parseInt(formValues.waterDamage) || 0) +
        (parseInt(formValues.capLeak) || 0) +
        (parseInt(formValues.BottleLeak) || 0));
    setFormValues({ ...formValues, netTotal: netTotal });
    if (netTotal > 0) {
      setFormErrors({
        ...formErrors,
        netTotal: null,
      });
    }
  }, [formValues.BottleLeak, formValues.capLeak, formValues.waterDamage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (toastVisible) {
      return;
    }
    try {
      await rideSchema.validate(formValues, { abortEarly: false });
      setToastVisible(true);
      setSendLoading(true);
      await addSummaryRequest(token, formValues, rideId);
      setSendLoading(false);
      toast.success(" summary updated successfully!", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          setToastVisible(false);
        },
      });
      setRefresh(!refresh);
    } catch (error) {
      const validationErrors = {};
      if (error.inner) {
        setToastVisible(false);

        error.inner.forEach((innerError) => {
          validationErrors[innerError.path] = innerError.message;
        });
        setFormErrors(validationErrors);
      } else {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "bottom-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            setToastVisible(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    const total =
      (parseInt(additionalStock) || 0) +
      (parseInt(openingStock) || 0) +
      (parseInt(loadedStock) || 0);
    setFormValues({
      ...formValues,
      total: total,
      netTotal: total - waterDamage - FOC - capLeak,
      waterDamage: waterDamage,
      capLeak: capLeak,
      BottleLeak: BottleLeak,
      emptyBottle: emptyBottle,
      soldBottle: soldBottle,
      FOC: FOC,
    });
  }, [openingStock, loadedStock, additionalStock, balanceStock]);

  return (
    <div className="flex flex-col min-h-base px-8 py-8 select-none  ">
      <div className="bg-white p-6 rounded-md ">
        <form onSubmit={handleSubmit}>
          <div className=" py-5">
            <div className="flex gap-4 mb-3 items-center">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md font-normal  ">
                Opening Stock
              </h1>
              <div className="w-2/5">
                {/* <label className="text-red-350 text-md">
                  {formErrors?.openingStock}
                </label> */}
                <Input
                  type={"number"}
                  name={"openingStock"}
                  // onChange={handleInputChange}
                  value={openingStock || 0}
                  styles={`inline w-full px-3 py-0.5 bg-mud border text-center shadow-sm 
                  ${
                    formErrors?.openingStock
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md  focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex gap-4 mb-3 items-center">
              <h1 className="whitespace-nowrap text-mud w-3/5  font-normal text-md">
                Loaded from Plant
              </h1>
              <div className="w-2/5">
                <Input
                  type={"number"}
                  name={"loadedStock"}
                  value={loadedStock}
                  styles={`inline w-full px-3 py-0.5 bg-mud border text-center shadow-sm 
                  ${
                    formErrors?.loadedStock
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex gap-4 mb-3 items-center">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md  font-normal ">
                Additional Adding
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors?.additionalStock}
                </label>
                <Input
                  type={"number"}
                  name={"additionalStock"}
                  onChange={handleInputChange}
                  value={additionalStock}
                  styles={`inline w-full px-3 py-0.5 bg-mud border text-center shadow-sm 
                  ${
                    formErrors?.additionalStock
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md  font-normal ">
                Total
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.total}
                </label>
                <Input
                  type={"number"}
                  name={"total"}
                  // onChange={handleInputChange}
                  value={formValues.total}
                  styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.total
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center mb-3">
              <h1 className="whitespace-medium text-mud w-3/5 text-md  font-normal  ">
                Bottle Leak
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.BottleLeak}
                </label>
                <Input
                  type={"number"}
                  name={"BottleLeak"}
                  onChange={handleInputChange}
                  value={formValues.BottleLeak}
                  styles={`inline w-full max-h-[30px] px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.BottleLeak
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md  font-normal  ">
                Cap Leak
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.capLeak}
                </label>
                <Input
                  type={"number"}
                  name={"capLeak"}
                  onChange={handleInputChange}
                  value={formValues.capLeak}
                  styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.capLeak
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md font-normal   ">
                Water Damage
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.waterDamage}
                </label>
                <Input
                  type={"number"}
                  name={"waterDamage"}
                  value={formValues.waterDamage}
                  onChange={handleInputChange}
                  styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.waterDamage
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md font-normal   ">
                FOC
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">{formErrors.FOC}</label>
                <Input
                  type={"number"}
                  name={"FOC"}
                  onChange={handleInputChange}
                  value={formValues.FOC}
                  styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.FOC
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md font-normal   ">
                Net Total
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.netTotal}
                </label>
                <Input
                  type={"number"}
                  name={"netTotal"}
                  value={formValues.netTotal}
                  styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.netTotal
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex gap-4 items-center mb-3">
              <h1 className="whitespace-medium text-mud w-3/5 text-md  font-normal  ">
                Empty Bottle
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.emptyBottle}
                </label>
                <Input
                  type={"number"}
                  name={"BottleLeak"}
                  onChange={handleInputChange}
                  value={formValues.emptyBottle}
                  styles={`inline w-full max-h-[30px] px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.emptyBottle
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 mb-3">
              <h1 className="whitespace-nowrap text-mud w-3/5 text-md font-normal   ">
                Balance Stock
              </h1>
              <div className="w-2/5">
                <label className="text-red-350 text-md">
                  {formErrors.balanceStock}
                </label>
                <Input
                  type={"number"}
                  name={"balanceStock"}
                  onChange={handleInputChange}
                  value={balanceStock}
                  styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm text-center
                  ${
                    formErrors?.balanceStock
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-md focus:ring-1`}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-main text-white font-normal py-2 px-4 rounded-md mt-6 w-52"
              >
                {toastVisible ? <CircularLoading /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* {sendLoading ? <SendLoading></SendLoading> : ""} */}
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ViewSummary;
