import React, { useEffect, useState, useRef } from "react";
import { AiFillCaretDown } from "react-icons/ai";

const SelectCustom = ({
  value,
  label,
  options,
  onChange,
  error,
  errorMessage,
  name,
  bgColor,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [labelMerged, setLabelMerged] = useState(!!value);
  const inputRef = useRef(null);

  const handleSelectChange = (event) => {
    setInputValue(event.target.value);
    setLabelMerged(!!event.target.value);
    onChange && onChange(event);
  };
  useEffect(() => {
    if (value) {
      setInputValue(value);
      setLabelMerged(true);
    }
  }, [value]);
  const handleLabelClick = () => {
    // Focus the input field when the label is clicked
    // setLabelMerged(true);

    inputRef.current.focus();
    inputRef.current.click();
  };
  return (
    <div className="relative mb-3">
      <select
        ref={inputRef}
        className={`border appearance-none w-full rounded-md px-2 py-1 focus:outline-none bg-white focus:ring focus:border-sub ${labelMerged ? "border-merged" : ""} ${
          error ? "border-error border-red-500" : "border-sub"
        }`}
        onChange={handleSelectChange}
        value={inputValue}
        name={name}
      >
        {
          <option value="" className="hidden">
            {""}
          </option>
        }
        {/* Change 'selected' to 'defaultValue' */}
        {options&&options?.map((option, idx) => (
          <option key={idx} value={option.value}>
            {option?.name}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute inset-y-0.5  right-0 bottom-1 flex items-center px-2 text-gray-700">
        <AiFillCaretDown />
      </div>
      {error ? (
        <label
          className={`absolute left-2 px-3
          -top-2 bg-white text-xs text-red-600
          transition-all`}
        >
          {errorMessage}
        </label>
      ) : (
        <label
          onClick={handleLabelClick}
          className={`absolute left-0 ml-3 bg-white ${
            labelMerged
              ? "-top-2 bg-white text-xs text-gray-600"
              : `top-0.5 text-md `
          } transition-all`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default SelectCustom;
