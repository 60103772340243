import { createSlice } from "@reduxjs/toolkit";

const returnSlice = createSlice({
  name: "return",
  initialState: {
    returnCustomers:null
  },
  reducers: {
    addReturn() {},
    getReturnCustomers() {},
    setReturnCustomers(state, action) {
      return { ...state, returnCustomers : action.payload };
    },

    
  },
});

export const {  addReturn, getReturnCustomers,setReturnCustomers} = returnSlice.actions;

export default returnSlice.reducer;
