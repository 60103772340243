import { call, put } from "redux-saga/effects";
import handleAuthError from "./HandleAuthErrors";

import { getSummaryRequest } from "../requests/SummaryRequest";
import { setSendLoading } from "../../ducks/LoadingSlice";

export function* handleAddSummary(action) {
  try {
    // yield put(setLoading({ loading: true }));

    yield call(getSummaryRequest, action);
    yield put(setSendLoading(false))

    console.log('working');

    // yield put(setLoading({ loading: false }),
    // )
  } catch (error) {
    console.log(error);
    // yield put(setSummaryLoading(true))

    // handleAuthError(error);
  }
}


