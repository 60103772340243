/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import Input from "../input/Input";
import Select from "../select/Select";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import TextArea from "../textArea/TextArea";
import { addReturn } from "../../redux/ducks/ReturnSlice";
import DatePicker from "react-datepicker";
import { getCustomer } from "../../redux/ducks/CustomerSlice";
import SendLoading from "../../layouts/Loading";
import { setSendLoading, setSendSuccess } from "../../redux/ducks/LoadingSlice";
import { ToastContainer, toast } from "react-toastify";
import AutocompleteDropdown from "../selectBySearch/SearchBySelect";
import "react-toastify/dist/ReactToastify.css";
import CustomInput from "../customInput/CustomInput";
import SelectCustom from "../select/SelectCustom";
import CustomTextArea from "../textArea/CustomTextArea";
import { getCustomerSingleDetailService } from "../../redux/sagas/requests/CustomerRequest";
const initialValues = {
  customerId: "",
  noOfBottles: null,
  returnBottles: null,
  amount: null,
  returnDepositAmount: 0,
  remarks: "",
};

const ReturnFormSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rideId = useSelector((state) => state.ride._id);
  const { customers } = useSelector((state) => state.customers);
  const { sendLoading, sendSuccess } = useSelector((state) => state.loading);

  const [cusName, setCusName] = useState([]);
  const [amount, setAmount] = useState("");
  const [bottles, setBottles] = useState("");
  const rideSchema = yup.object().shape({
    noOfBottles: yup.string().required("bottle is required"),
    customerId: yup.string().required("CustomerName is required"),
    returnBottles: yup
      .string()
      .required(" Required")
      .test("greater-than-one", "invalid", function (value) {
        const returnBottles = parseFloat(value);

        return returnBottles > 0 && returnBottles <= bottles;
      }),
    returnDepositAmount: yup
      .string()
      .required("Required")
      .test("less than deposit amount", "Invalid", function (value) {
        const returnDepositAmount = parseFloat(value);

        return returnDepositAmount >= 0 && returnDepositAmount <= amount;
      }),
    remarks: yup.string().required("Remarks is Required"),
    depositAmount: yup.string(),
  });
  console.log(cusName);
  useEffect(() => {
    dispatch(getCustomer({ token, rideId }));
  }, []);
  useEffect(() => {
    if (sendSuccess) {
      toast.success("Return added successfully!", {
        position: "bottom-center",
        autoClose: 100,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          navigate(-1); // Navigate back to the previous page
        },
      });
    } else {
      console.log("sendSuccess is false");
    }

    return () => {
      dispatch(setSendSuccess(false)); // Reset sendSuccess state
    };
  }, [sendSuccess]);

  useEffect(() => {
    if (customers) {
      setCusName(
        customers.map((item) => {
          return { name: item.name, value: item._id }; // Modify "item.value" if the key is different
        })
      );
    }
  }, [customers]);

  useEffect(() => {
    setFormValues({ ...formValues, rideId: rideId });
  }, [rideId]);
  const [startDate, setStartDate] = useState(new Date());

  const { token } = JSON.parse(localStorage.getItem("user"));

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });

    try {
      await yup.reach(rideSchema, name).validate(value);
      setFormErrors({ ...formErrors, [name]: null });
    } catch (error) {
      console.log(error);
      setFormErrors({ ...formErrors, [name]: error.message });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await rideSchema.validate(formValues, { abortEarly: false });
      console.log("working");
      dispatch(addReturn({ token, formValues }));
      dispatch(setSendLoading(true));
    } catch (error) {
      const validationErrors = {};

      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };
  // const CustomerSelect = async (e) => {
  //   const { name, value } = e.target;
  //   if (value) {
  //     const customerByName = customers.find(
  //       (customer) => customer._id === value
  //     );
  //     setFormValues({
  //       ...formValues,
  //       [name]: value,
  //       depositAmount: customerByName.depositAmount,
  //       noOfBottles: customerByName.bottlesInHand,
  //     });
  //     setFormErrors({
  //       ...formErrors,
  //       [name]: null,
  //       depositAmount: null,
  //       noOfBottles: null,
  //     });
  //     setAmount(customerByName.depositAmount);
  //     setBottles(customerByName.bottlesInHand);
  //   }
  // };
  const onSelect = async (value) => {
    if (value) {
      const { data } = await getCustomerSingleDetailService(token, value._id);

      const customerByName = data;

      setFormValues({
        ...formValues,
        customerId: customerByName._id,
        depositAmount: customerByName.depositAmount,
        noOfBottles: customerByName.bottlesInHand,
      });
      setFormErrors({
        ...formErrors,
        customerId: null,
        depositAmount: null,
        noOfBottles: null,
      });
      setAmount(customerByName.depositAmount);
      setBottles(customerByName.bottlesInHand);
    }
  };
  console.log(formValues);
  return (
    <div className="flex flex-col min-h-base px-8  py-8 select-none">
      <div className="bg-white p-6   rounded-md ">
        <form onSubmit={handleSubmit}>
          {/* <label className="text-red-350">{formErrors?.name}</label> */}

          {/* <div className="w-full sm:w-96 mb-3">
            <DatePicker
              locale="us"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="w-full  pr-32 px-3 py-0.5 bg-mud border shadow-sm border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500 placeholder-mud text-mud block rounded-md sm:text-sm focus:ring-1"
              minDate={new Date()} // Disable all dates before today
              maxDate={new Date()} // Disable all dates after today
            />
          </div> */}
          <CustomInput
            type={"date"}
            value={new Date().toISOString().slice(0, 10)}
            label={"Date"}
            readOnly={true}
          ></CustomInput>

          {/* <Select
            name={"customerId"}
            options={cusName}
            label={formErrors?.customerId}
            defaultValue={{ name: "Select Customer" }}
            value={formValues.customerId}
            onChange={CustomerSelect}
            labelStyles={"text-red-350 text-sm"}
            parentStyles={"relative inline-block w-full"}
            styles={`cursor-pointer block appearance-none w-full px-3 py-0.5 mb-3 bg-mud text-mud border shadow-sm
            ${
              formErrors?.customerId
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } block rounded-md sm:text-sm focus:ring-1`}
          /> */}
          <label className="text-red-350">{formErrors?.customerId}</label>

          <div className="relative mb-3">
            <AutocompleteDropdown
              customers={customers}
              onSelect={onSelect}
              label={"Select a customer"}
              bgWhite={"white"}
            ></AutocompleteDropdown>
          </div>
          {/* <label className="text-red-350 text-sm">
            {formErrors?.noOfBottles}
          </label> */}
          {/* <div className="flex gap-4 mb-3 items-center ">
            <p className="whitespace-nowrap text-mud w-3/6  px-3 font-normal text-md leading-tight">
              Bottle in Hand
            </p>
            <div className="w-3/6">
              <Input
                type={"number"}
                name={"noOfBottles"}
                value={bottles}
                styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                  ${
                    formErrors?.noOfBottles
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
                readOnly={true}
              />
            </div>
          </div> */}
          <CustomInput
            type={"number"}
            name={"noOfBottles"}
            value={bottles}
            label={"No of Bottles in Hand"}
            errorMessage={formErrors.noOfBottles}
            readOnly={true}
          ></CustomInput>
          <CustomInput
            type={"number"}
            name={"returnBottles"}
            value={formValues.returnBottles}
            onChange={handleInputChange}
            error={formErrors.returnBottles}
            errorMessage={formErrors.returnBottles}
            label={"Return Bottle"}
          ></CustomInput>

          {/* <div className="flex items-center gap-4  mb-3">
            <h1 className="whitespace-nowrap text-mud w-3/6 px-3 font-normal text-md">
              Return Bottle
            </h1>

            <div className="w-3/6">
              <label className="text-red-350 text-sm">
                {formErrors?.returnBottles}
              </label>
              <Input
                type={"number"}
                name={"returnBottles"}
                value={formValues.returnBottles}
                onChange={handleInputChange}
                styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  
                  ${
                    formErrors?.returnBottles
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
              />
            </div>
          </div> */}
          {/* <label className="text-red-350 text-sm">
            {formErrors.depositAmount}
          </label> */}
          <CustomInput
            type={"number"}
            name={"depositAmount"}
            value={amount}
            error={formErrors.depositAmount}
            errorMessage={formErrors.depositAmount}
            readOnly={true}
            label={"Deposit amount"}
          ></CustomInput>
          {/* <div className="flex items-center gap-4 mb-3">
            <h1 className="whitespace-nowrap text-mud w-9/12 px-3  font-normal text-sm">
              Deposited Amount Collected
            </h1>
            <div className="w-1/4">
              <Input
                type={"number"}
                name={"depositAmount"}
                value={amount}
                styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  ${
                    formErrors?.depositAmount
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
                readOnly={true}
              />
            </div>
          </div> */}
          <CustomInput
            type={"number"}
            name={"returnDepositAmount"}
            onChange={handleInputChange}
            value={formValues.returnDepositAmount}
            error={formErrors.returnDepositAmount}
            errorMessage={formErrors.returnDepositAmount}
            label={"Return deposit Amount"}
          ></CustomInput>
          {/* <div className="flex items-center gap-4 mb-3">
            <h1 className="whitespace-nowrap text-mud font-normal w-9/12 px-3 text-sm">
              Return Deposit Amount
            </h1>
            <div className="w-1/4 flex flex-col">
              <label className="text-red-350 text-sm px-3">
                {formErrors.returnDepositAmount}
              </label>
              <Input
                type={"number"}
                name={"returnDepositAmount"}
                onChange={handleInputChange}
                value={formValues.returnDepositAmount}
                styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  ${
                    formErrors?.returnDepositAmount
                      ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                      : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                  }
                  block rounded-md sm:text-sm focus:ring-1`}
              />
            </div>
          </div> */}

          {/* <label className="text-red-350">{formErrors?.remarks}</label> */}
          <CustomTextArea
            onChange={handleInputChange}
            value={formValues.remarks}
            name={"remarks"}
            label={"Remarks"}
            type={'text'}
            error={formErrors.remarks}
            errorMessage={formErrors.remarks}
          ></CustomTextArea>
          {/* <TextArea
            onChange={handleInputChange}
            value={formValues.remarks}
            name={"remarks"}
            placeholder={"Remarks"}
            style={`w-full px-3 py-0.5 h-14 mb-3 bg-mud border shadow-sm  ${
              formErrors?.remarks
                ? "border-red-500 focus:outline-none focus:border-red-500 focus:ring-red-500"
                : "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
            } placeholder-mud  block rounded-md sm:text-sm focus:ring-1`}
          /> */}

          <div className="flex justify-center mb-10">
            <button
              type="submit"
              className="bg-main text-white font-normal py-2 px-4 rounded-md mt-6 w-52"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {sendLoading ? <SendLoading></SendLoading> : ""}
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ReturnFormSection;
