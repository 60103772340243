import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getCustomerRequest({ payload: {
  token,
  rideId,
},}) {
console.log(token);
console.log(rideId);


  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}` ,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/get-users/${rideId}`,
  });
}

export function addCustomerRequest({
  payload: {
    token,
    formValues: { ...data },
  },
}) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify({ data }),
    url: `${BACKEND_URL}/customer/create-customer`,
  });
}
export function getCustomerDashboardRequest({
  payload: {
    token, 
    rideId

  },
}) {
  console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/customer-dashboard/?rideId=${rideId}`,
  });
}
export function getSingleCustomerRequest({ payload: {
  token,
  id,
},}) {
console.log(token);
console.log(id);


  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}` ,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/customer/${id}`,
  });
}
export function getCustomerOrderHistoryRequest({ payload: {
  token,
  id,
},}) {
console.log(token);
console.log(id);


  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}` ,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/order-history/${id}`,
  });
}
export function getDispenserHistoryRequest(
  token,
  cusId,
) {
  console.log(token,cusId);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/dispenser-history/${cusId}`,
  });
}
export function getACustomerDetailRequest(token,id) {
console.log(token);


  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}` ,
    },
    method: "get",
    url: `${BACKEND_URL}/customer/detail/${id}`,
  });
}
export function getCustomerPendingOrder(token,id) {
  console.log(token);
  
  
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}` ,
      },
      method: "get",
      url: `${BACKEND_URL}/customer/pending-orders/${id}`,
    });
  }
export function addCustomerLocationRequest(data,token) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify( data ),
    url: `${BACKEND_URL}/customer/set-location`,
  });
}

export function getCustomerIDRequest(token,mainRoute,subRoute) {
  console.log(token);
  
  
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}` ,
      },
      method: "get",
      url: `${BACKEND_URL}/customer/get-customerID?mainRoute=${mainRoute}&subRoute=${subRoute}`,
    });
  }
  export function getCustomerSingleDetailService(token,id) {
    
      return axios.request({
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}` ,
        },
        method: "get",
        url: `${BACKEND_URL}/customer/single-customer/${id}`,
      });
    }
  
  