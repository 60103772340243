import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RootLayout from "../layouts/RootLayout";

const PrivateRoute = () => {
  const { user } = useSelector((state) => state.user);
  const isAuthenticated = user;

  return isAuthenticated ? <RootLayout /> : <Navigate to="/login" />;
};

export default PrivateRoute;
