import React, { useEffect, useState } from "react";

const CustomTextArea = ({
  value,
  label,
  onChange,
  name,
  type,
  error,
  errorMessage,
  readOnly,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [labelMerged, setLabelMerged] = useState(!!value);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setLabelMerged(!!event.target.value);
    onChange && onChange(event);
  };
  useEffect(() => {
    if(value){
      setInputValue(value);
      setLabelMerged(true)  
    }
 
  }, [value]);
  return (
    <div className="relative mb-3">
      <textarea
        type={type}
        name={name}
        placeholder={label}
        className={`border w-full  rounded-md px-3 placeholder-black text-black focus:outline-none bg-white focus:ring focus:border-sub ${
          labelMerged ? "border-merged" : ""
        } ${error ? "border-error border-red-600" : "border-sub"}`}
        onChange={handleInputChange}
        value={inputValue}
        readOnly={readOnly}
      />
      {error ? (
        <label
          className={`absolute left-2
          -top-2 bg-white text-xs text-red-600
          transition-all`}
        >
          {errorMessage}
        </label>
      ) : (
        <label
          className={`absolute left-3 ${
            labelMerged
              ? "-top-2 bg-white text-xs text-black"
              : "top-0.5 text-sm bg-white hidden"
          } transition-all`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CustomTextArea;
