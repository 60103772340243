import { createSlice } from "@reduxjs/toolkit";

const stockSlice = createSlice({
  name: "stock",
  initialState: null,
  reducers: {
    getStock() {},
    addStock() {},
    setStock(state, action) {
      const stock = action.payload;
      return { ...state, ...stock };
    },
  },
});

export const { getStock, addStock, setStock } = stockSlice.actions;

export default stockSlice.reducer;
