/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getRide } from "../../redux/ducks/RideSlice";
import { useNavigate } from "react-router-dom";
import {
  getPendingAmountRequest,
  getQuantityRequest,
  getTotalSalesListRequest,
} from "../../redux/sagas/requests/SalesRequest";
import { getRouteStockTotalRequest } from "../../redux/sagas/requests/RideRequest";
import {
  getHomeStockBlance,
  getRouteStockRequest,
} from "../../redux/sagas/requests/StockRequest";
import {
  setCashAmount,
  setCreditAmount,
  setSoldStock,
  setStockBalance,
  setStockItems,
  setTotalAmount,
} from "../../redux/ducks/VanLife";

const HomeSection = ({ stock }) => {
  const dispatch = useDispatch();
  const { token } = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const rideId = useSelector((state) => state.ride._id);
  const { cash, credit, total, stockItems, stockBalance ,totalSold} = useSelector(
    (state) => state.vanLife
  );


  const [salesList, setSalesList] = useState([]);
  // const [cash, setCash] = useState(0);
  // const [credit, setCredit] = useState(0);
  // const [total, setTotal] = useState(0);
  const [totalSale, setTotalSale] = useState(0);

  const [data, setData] = useState([]);
  const [sold, setSold] = useState([]);
  const [empty, setEmpty] = useState([]);
  // const [stockItems, setStockItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getTotalSalesListRequest(token, rideId);
        const { totalSum, totalCash, totalCredit, totalSale } = data;
        // Handle data or set state here
        // setCash(totalCash);
        dispatch(setCashAmount(totalCash));
        dispatch(setCreditAmount(totalCredit));
        dispatch(setTotalAmount(totalSum));
        // setCredit(totalCredit);
        // setTotal(totalSum)
        setTotalSale(totalSale);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the asynchronous function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Make sure to pass an empty dependency array if you want it to run only once

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPendingAmountRequest(token, rideId);
        // Handle data or set state here
        setData(data);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the asynchronous function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { sold, emptyBottle } = await getQuantityRequest(token, rideId);
        // Handle data or set state here
        console.log(sold, "hi");
        setSold(sold);
        setEmpty(emptyBottle);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the asynchronous function

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log("WORKING");
    dispatch(getRide({ token }));
  }, []);
  const {
    cashSale,
    creditSale,
    totalStockInHand,
    openingStock,
    soldBottle,
    cashInHand,
    additionalStock,
    loadedStock,
    mainRoute,
  } = useSelector((state) => state.ride);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getRouteStockRequest(token, mainRoute);
      // setStockItems(data);
      dispatch(setStockItems(data));
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getHomeStockBlance(token, rideId);
      const {totalStock,soldQuantity} =data
      dispatch(setStockBalance(totalStock));
      dispatch(setSoldStock(soldQuantity))
      // setStockItems(data);
      // dispatch(setStockItems(data))
    };
    fetchData();
  }, []);
  const totalEmpty = (empty.length > 0 && empty[0]?.totalQuantity) || 0;
  const totalQuantity = stockItems.reduce(
    (total, obj) => total + obj.quantity,
    0
  );

  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-10 select-none">
      <div className="flex flex-col justify-center items-center p-3 bg-white rounded-3xl">
        <h1 className="text-main text-4xl font-bold py-2">{stockBalance}</h1>
        <h1 className="text-main text-md font-medium py-2">Stock Balance</h1>
      </div>
      <div
        className="flex flex-col justify-center items-center p-3 bg-white rounded-3xl hover:bg-sub cursor-pointer"
        onClick={() => {
          navigate("/today-sales");
        }}
      >
        <h1 className="text-main text-4xl font-bold py-2">
         {totalSold}
        </h1>
        <h1 className="text-main text-md font-medium py-2">Sold</h1>
      </div>
      <div
        className="flex flex-col justify-center items-center p-3 bg-white rounded-3xl  cursor-pointer"
        // onClick={() => {
        //   navigate("/today-sales");
        // }}
      >
        <h1 className="text-main text-4xl font-bold py-2">{total || 0}</h1>
        <h1 className="text-main text-md font-medium py-2">Cash in Hand</h1>
      </div>

      <div className="flex flex-col justify-center items-center p-3 bg-white rounded-3xl">
        <div className="flex w-full justify-center">
          <div
            className="flex flex-col justify-center items-center w-full px-5  border-r border-[#B3E5AA]  hover:bg-sub"
            onClick={() => {
              navigate("/cash-sales");
            }}
          >
            <h1 className="text-main text-md font-medium py-0.5">{cash}</h1>
            <h1 className="text-main text-md font-medium py-0.5">AED</h1>
            <h1 className="text-main text-md font-normal py-0.5">Cash Sale</h1>
          </div>
          <div
            className="flex flex-col justify-center items-center w-full px-5  hover:bg-sub"
            onClick={() => {
              navigate("/credit-sales");
            }}
          >
            <h1 className="text-main text-md font-medium py-0.5">{credit}</h1>
            <h1 className="text-main text-md font-medium py-0.5">AED</h1>
            <h1 className="text-main text-md font-normal py-0.5">
              Credit Sale
            </h1>
          </div>
        </div>
        <h1 className="text-main text-md font-medium pt-2">
          Total Sales Amount :
        </h1>
        <h1 className="text-main text-md font-medium py-2">{totalSale} AED</h1>
      </div>
      <div
        className="flex flex-col justify-center items-center p-3 bg-white rounded-3xl  cursor-pointer"
        // onClick={() => {
        //   navigate("/today-sales");
        // }}
      >
        <h1 className="text-main text-4xl font-bold py-2">
          {(data.length > 0 && data[0]?.difference) || 0}
        </h1>
        <h1 className="text-main text-md font-medium py-2">
          My pending amount
        </h1>
      </div>
    </div>
  );
};

export default HomeSection;
