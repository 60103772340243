import axios from "axios";
import { BACKEND_URL } from "../../../config/Config";

export function getRideRequest({payload: {
  token, }}) {
    console.log(token);

  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/get-ride`,
  });
}
export function getnewRideRequest() {
  const user = JSON.parse(localStorage.getItem("user"))
  console.log(user);
  var token = ''
  if (user) {

    token = user.token
  }
  console.log(token);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    method: "get",
    url: `${BACKEND_URL}/ride/new-ride`,
  });
}

export function addRideRequest({
  payload: {
    token,
    formValues: { ...data },
  },
}) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify({ data }),
    url: `${BACKEND_URL}/ride/new-ride`,
  });
}
export function endRideRequest({
  payload: {
    token,
    formValues: { ...data },
  },
}) {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "post",
    data: JSON.stringify({ data }),
    url: `${BACKEND_URL}/ride/end-ride`,
  });
}

export function getSubRouteRequest() {
  const user = JSON.parse(localStorage.getItem("user"))
  console.log(user);
  var token = ''
  if (user) {

    token = user.token
  }
  console.log(token);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    method: "get",
    url: `${BACKEND_URL}/ride/sub-route`,
  });
}
export async function getRouteStockTotalRequest(token,mainRoute) {
const {data}= await axios.request({
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${BACKEND_URL}/ride/get-route-stock/${mainRoute}`,
  });
  return data
}