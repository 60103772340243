import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders:'',
    orderLoading:false,
    orderSuccess:false,
    orderFail:false



  },
  reducers: {
    getOrder() {},
    addOrder() {},
    setOrder(state, action) {
      const  orders = action.payload
     return{...state, orders:orders}

    },
    setOrderLoading(state, action) {
        return{...state, orderLoading:action.payload}

    },
    setOrderSuccess(state,action){
      console.log(action);
      return{...state, orderSuccess :action.payload}
    },
    setOrderFail(state,action){
      console.log(action);
      return{...state, orderFail :action.payload}
    },
   

   
  },
});

export const { getOrder, addOrder, setOrder,setOrderLoading ,setOrderSuccess,setOrderFail} = orderSlice.actions;

export default orderSlice.reducer;
