import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Pagination = ({
  currentPage,
  totalPages,
  goToPreviousPage,
  goToNextPage,
}) => {
  return (
    <div className="flex justify-center mt-5">
      <button
        onClick={goToPreviousPage}
        className={`rounded-full bg-white border-black  w-6 h-6 border-2 text-black ${
          currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
        }`}
        disabled={currentPage === 1}
      >
        <IoIosArrowBack className="w-full h-full p-1" />
      </button>

      <div className="flex items-center mx-4">
        Page {currentPage} of {totalPages}
      </div>

      <button
        onClick={goToNextPage}
        className={`rounded-full bg-white border-2 w-6 h-6 border-black text-black ${
          currentPage === totalPages
            ? 'opacity-50 cursor-not-allowed'
            : 'cursor-pointer'
        }`}
        disabled={currentPage === totalPages}
      >
        <IoIosArrowForward className="w-full h-full p-1" />
      </button>
    </div>
  );
};

export default Pagination;
