/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import Input from "../input/Input";
import Section from "../commonListSection/Section";
import { getMembership ,} from "../../redux/ducks/MembershipSlice";
import Skeleton from "../skeleton/Skeleton";
import Pagination from "../pagination/Pagination";
const MembershipCustomers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.membership.MembershipCustomers);
  const {mcLoading} =  useSelector((state) => state.membership);
  console.log(useSelector((state) => state.customers));
  const { token } = JSON.parse(localStorage.getItem("user"));
  console.log(useSelector((state) => state.ride._id));
  const rideId = useSelector((state) => state.ride._id);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const handleFiltering = (event) => {
    const input = event.target.value;
    setInputValue(input);

    // Filter options by input value
    const filtered = customers?.filter((option) => {
      const name = option.name;
      return name.toLowerCase().includes(input.toLowerCase());
    });

    setFilteredOptions(filtered);
  };

  useEffect(() => {
    dispatch(getMembership({ token, rideId }));
  }, []);
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    const totalPages = Math.ceil(filteredOptions.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const visibleCustomers = filteredOptions.slice(startIndex, endIndex);


  useEffect(() => {
    if (customers?.length) {
      setFilteredOptions(customers);
    }
  }, [customers]);
  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-10 select-none">
      <div className="flex gap-7">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-5 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <Input
            id={"search__input"}
            type={"text"}
            value={inputValue}
            onChange={handleFiltering}
            name={"search__input"}
            placeholder={"Search"}
            styles={`w-full px-3 py-2 pl-14 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              placeholder-slate-400  block sm:text-sm focus:ring-1 rounded-3xl`}
          />
        </div>
        <div
          onClick={() => navigate("/membership-sale")}
          className="flex items-center justify-center h-9 rounded-full bg-mud-plus w-[2.7rem] cursor-pointer"
        >
          <AiOutlinePlus />
        </div>
      </div>
      {mcLoading ? (
        <div className="flex flex-col gap-4">
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>

        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {customers?.length ? (
            visibleCustomers?.length ? (
              visibleCustomers.map((x, idx) => (
                <Section
                  key={idx}
                  name={x.name}
                  subOne={`${x.mainRoute},${x.subRoute}`}
                  mobile={x.mobileNumber}
                  onClick={() => navigate(`/customers/customer/${x._id}`)}
                  coupon={true}
                  foc={x.FOC}
                  img={ "/userProfile.74cda66309e83509d2bd.avif"}
                  type={2}
                  phone={x.mobile}
                />
              ))
            ) : (
              <div className="flex justify-center items-center h-48">
                No customers matching with that name
              </div>
            )
          ) : (
            <div className="flex justify-center items-center h-48">
              No customers
            </div>
          )}
            {visibleCustomers?.length ? (
            <Pagination
              currentPage={currentPage}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              totalPages={Math.ceil(filteredOptions.length / itemsPerPage)}
            ></Pagination>
          ) : (
            ""
          )}
          
        </div>
      )}
      
    </div>
  );
};

export default MembershipCustomers;
