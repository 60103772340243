/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Input from "../input/Input";
import { AiFillPlusCircle } from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import * as yup from "yup";
import { endRide } from "../../redux/ducks/RideSlice";
import { useDispatch, useSelector } from "react-redux";
import CircularLoading from "../circularloading/CircularLoading";

const endRideForm = {
  endedKm: 0,
  traveledKm: 0,
  expense: "",
};

export default function EndRIde() {
  const { startedKm } = useSelector((state) => state.ride);
  const [count, setCount] = useState(1);
  const { _id } = useSelector((state) => state.ride);
  const [toastVisible, setToastVisible] = useState(false);

  const [number, setNumber] = useState([
    { id: count, _id: Math.random(), name: `Expense`, expense: "", amount: 0 },
  ]);

  useEffect(() => {
    setFormValues({ ...formValues, rideId: _id });
  }, [_id]);
  const { token } = JSON.parse(localStorage.getItem("user"));
  const endRideSchema = yup.object().shape({
    endedKm: yup
      .string()
      .required("EndingKm is required")
      .test("greater than starting km", (value) => {
        return parseInt(value) > 0;
      }),
    traveledKm: yup
      .string()
      .required(" required")
      .test("greater than starting km", (value) => {
        return parseInt(value) > 0;
      }),
    expense: yup.array().required("Expense is required"),
  });
  const [formValues, setFormValues] = useState(endRideForm);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  function handleIncrement() {
    const newf = {
      id: count + 1,
      _id: Math.random(),
      name: `Expense`,
      expense: "",
      amount: 0,
    };
    setNumber((prevNumber) => [...prevNumber, newf]);
    setCount((prevCount) => prevCount + 1);
  }
  function handleDecrement(id) {
    if (number.length === 1) {
      return; // Avoid removing when array is already empty
    }

    setNumber((prevNumber) => prevNumber.filter((obj) => obj._id !== id));
    setCount((prevCount) => prevCount - 1);
  }

  const handleInputChange = async (e, id) => {
    const { name, value } = e.target;
    if (name === "endedKm") {
      const totalkm = parseInt(value) - parseInt(startedKm) || 0;
      setFormValues({
        ...formValues,
        [name]: parseInt(value) || 0,
        traveledKm: totalkm,
      });

      try {
        await yup.reach(endRideSchema, name).validate(value);
        setFormErrors({ ...formErrors, [name]: null, traveledKm: null });
      } catch (error) {
        setFormErrors({ ...formErrors, [name]: error.message });
      }
    } else {
      const updatedData = number.map((obj) => {
        if (obj._id === id) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      setNumber(updatedData);

      setFormValues({
        ...formValues,
        expense: updatedData,
      });
      try {
        if (name === "endedKm") {
          await yup.reach(endRideSchema, name).validate(value);
          setFormErrors({ ...formErrors, [name]: null });
        }
      } catch (error) {
        if (name === "endedKm") {
          setFormErrors({ ...formErrors, [name]: error.message });
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (toastVisible) {
      return;
    }
    try {
      setToastVisible(true);
      await endRideSchema.validate(formValues, { abortEarly: false });
      dispatch(endRide({ formValues, token }));
      setToastVisible(false);
    } catch (error) {
      setTimeout(() => {
        setToastVisible(false);
      }, 500);
      const validationErrors = {};

      error.inner.forEach((innerError) => {
        validationErrors[innerError.path] = innerError.message;
      });
      setFormErrors(validationErrors);
    }
  };
  return (
    <div className="flex flex-col min-h-base px-1 py-4 bg-mud select-none">
      <div className="bg-white p-3 py-24 rounded-md ">
        <form onSubmit={handleSubmit}>
          <div className="flex gap-5 mb-3 items-center">
            <h1 className="whitespace-nowrap text-main w-3/6 text-end sm:text-center text-2xl tracking-wide font-semibold">
              Ending KM
            </h1>
            <div className="w-3/6">
              <label className="text-red-350 text-sm">
                {formErrors?.endedKm}
              </label>
              <Input
                type={"number"}
                name={"endedKm"}
                onChange={handleInputChange}
                styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm 
                  border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500
                 block rounded-md text-md font-semibold text-main text-center  focus:ring-1`}
              />
            </div>
          </div>
          <div className="flex gap-5 mb-3 items-center">
            <h1 className="whitespace-nowrap text-main w-3/6  text-end sm:text-center text-2xl tracking-wide font-semibold">
              Travelled KM
            </h1>
            <div className="w-3/6">
              <label className="text-red-350 text-sm">
                {formErrors?.traveledKm}
              </label>
              <Input
                type={"number"}
                name={"traveledKm"}
                value={formValues.traveledKm}
                styles={`inline w-full px-3 py-0.5   bg-mud border shadow-sm 
                    border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500
                   block rounded-md text-md font-semibold text-main text-center focus:ring-1`}
                readOnly={true}
              />
            </div>
          </div>
          <div className="py-8">
            <div className="flex justify-end  mb-3  ">
              <div className="flex row  w-9/12 sm:w-full  justify-evenly sm:justify-around ">
                <div className=" text-main text-center w-3/6  text-sm font-medium ">
                  remarks
                </div>
                <div className=" text-main text-center w-3/6  text-sm pr-4 font-medium   ">
                  amount
                </div>
              </div>
            </div>

            {number.map((obj, idx) => (
              <div
                className="flex gap-4 mb-3 items-center justify-around"
                key={obj.id}
              >
                <h1 className="whitespace-nowrap text-main  text-end sm:text-center text-md ">
                  {obj.name}
                  {idx + 1}
                </h1>
                <div className="w-48 sm:w-full">
                  {/* <label className="text-red-350 text-sm">
                    {formErrors?.expense}
                  </label> */}
                  <Input
                    type={"text"}
                    name={"expense"}
                    //   value={formValues.openingStock}
                    value={obj?.expense}
                    // placeholder="!"
                    onChange={(e) => handleInputChange(e, obj._id)}
                    styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm

                      ${
                        obj?.expense
                          ? "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                          : "border-red-500  text-center focus:outline-none focus:border-red-500 focus:ring-red-500"
                      }
                      block rounded-md sm:text-sm focus:ring-1`}
                  />
                </div>

                <div className="w-32 sm:w-full">
                  {/* <label className="text-red-350 text-sm">
                  {formErrors?.expense}

                  </label> */}
                  <Input
                    type={"number"}
                    name={"amount"}
                    onChange={(e) => handleInputChange(e, obj._id)}
                    value={obj?.amount}
                    styles={`inline w-full px-3 py-0.5 bg-mud border shadow-sm

                      ${
                        obj?.amount
                          ? "border-sub focus:outline-none focus:border-sky-500 focus:ring-sky-500"
                          : "border-red-500  text-center focus:outline-none focus:border-red-500 focus:ring-red-500"
                      }
                      block rounded-md sm:text-sm focus:ring-1`}
                  />
                </div>
                {obj.id === 1 ? (
                  <div
                    className="rounded-full cursor-pointer"
                    onClick={handleIncrement}
                  >
                    <AiFillPlusCircle size={15} color="green" />
                  </div>
                ) : (
                  <div
                    className="rounded-full cursor-pointer"
                    onClick={() => handleDecrement(obj._id)}
                  >
                    <GrFormClose size={15} color="#E11010" />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-around mb-10">
            <button
              type="submit"
              className="bg-main text-white font-medium py-2 px-4 rounded-md mt-6 w-52"
            >
              {toastVisible ? <CircularLoading /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
