import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    Rideerrors:'',

  },
  reducers: {
    setError(state, action) {
        state.Rideerrors = action.payload

    },
   
  },
});

export const {setError, } = errorSlice.actions;

export default errorSlice.reducer;
