import { createSlice } from "@reduxjs/toolkit";
const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    show:false
  },

  reducers: {
    setShow(state, action) {
      return { ...state,show:true };
    },
    setClose(state, action) {
        return { ...state,show:false };
      },
   
    
  },
});

export const { setShow,setClose} = sidebarSlice.actions;

export default  sidebarSlice.reducer;
