import { createSlice } from "@reduxjs/toolkit";

const vanLifeSlice = createSlice({
  name: "vanLife",
  initialState: {
    item: [],
    cash: 0,
    credit: 0,
    total: 0,
    stockItems: [],
    stockBalance: 0,
    totalSold: 0,
  },
  reducers: {
    setItems(state, action) {
      state.item = action.payload;
    },
    setCashAmount(state, action) {
      state.cash = action.payload;
    },
    setCreditAmount(state, action) {
      state.credit = action.payload;
    },
    setStockItems(state, action) {
      state.stockItems = action.payload;
    },
    setTotalAmount(state, action) {
      state.total = action.payload;
    },
    setStockBalance(state, action) {
      state.stockBalance = action.payload;
    },
    setSoldStock(state, action) {
      state.totalSold = action.payload;
    },
  },
});

export const {
  setItems,
  setCashAmount,
  setCreditAmount,
  setStockItems,
  setTotalAmount,
  setStockBalance,
  setSoldStock
} = vanLifeSlice.actions;

export default vanLifeSlice.reducer;
