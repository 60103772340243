import CustomerDispenser from "../../components/customerDispencer/CustomerDispenser";
const CustomerDispenserPage = () => {
  return (
    <>
      <CustomerDispenser />
    </>
  );
};

export default CustomerDispenserPage;
