import StartRideFormSection from "../../components/startRideForm/StartRideFormSection";

const StartRideForm = () => {
  return (
    <>
      <StartRideFormSection />
    </>
  );
};

export default StartRideForm;
