import { call, put } from "redux-saga/effects";
import handleAuthError from "./HandleAuthErrors";
import {
  addCustomerRequest,
  getCustomerRequest,
  getCustomerDashboardRequest,
  getSingleCustomerRequest,
  getCustomerOrderHistoryRequest
} from "../requests/CustomerRequest";
import {
  setCustomer,
  setCustomerError,
  setCustomerSuccess,
  setCustomerLoading,
  setCustomerDashboard,
  setSingleCustomer,
  setCustomerOrderHistory
} from "../../ducks/CustomerSlice";
import { setLoading, setSendLoading } from "../../ducks/LoadingSlice";

export function* handleGetCustomer(action) {
  try {
    // yield put(setLoading({ loading: true }));

    const { data } = yield call(getCustomerRequest, action);

    yield put(setCustomer(data));
    console.log("working");
    yield put(setCustomerLoading(false));

    yield put(setLoading({ loading: false }));
  } catch (error) {
    console.log(error);
    yield put(setCustomerLoading(true));

    handleAuthError(error);
  }
}

export function* handleAddCustomer(action) {
  try {
    yield call(addCustomerRequest, action);
    yield put(setSendLoading(false));

    yield put(setCustomerSuccess(true));
  } catch (error) {
    yield put(setSendLoading(false));

    yield put(setCustomerError(error.response.data));
    console.log(error);
    console.log(error.response.data);
  }
}
export function* handleGetCustomerDashboard(action) {
  try {
    const { data } = yield call(getCustomerDashboardRequest, action);
    yield put(setCustomerDashboard(data));
  } catch (error) {
    console.log(error);
    console.log(error.response.data);
  }
}
export function* handleGetSingleCustomer(action) {
  try {

    const { data } = yield call(getSingleCustomerRequest, action);

    yield put(setSingleCustomer(data));
    console.log("working");

  } catch (error) {
    console.log(error);

    handleAuthError(error);
  }
}
export function* handleGetCustomerOrderHistory(action) {
  try {

    const { data } = yield call(getCustomerOrderHistoryRequest, action);

    yield put(setCustomerOrderHistory(data));
    console.log("working");

  } catch (error) {
    console.log(error);

    handleAuthError(error);
  }
}
