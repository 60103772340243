import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import Input from "../input/Input";
import Section from "../commonListSection/Section";
import { getOrder } from "../../redux/ducks/OrderSlice";
import Pagination from "../pagination/Pagination";
import Skeleton from "../skeleton/Skeleton";
import { useParams } from "react-router-dom";
import { getRideCustomerOrderRequest } from "../../redux/sagas/requests/OrderRequest";
import { ADMIN_BACKEND_URL } from "../../config/Config";
const CustomerOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { token } = JSON.parse(localStorage.getItem("user"));
  const { mainRoute, subRoute } = useSelector((state) => state.ride) ?? {};
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const rideId = useSelector((state) => state.ride._id);
  //   const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [orders, setOrders] = useState([]);


  //   const handleFiltering = (event) => {
  //     const input = event.target.value;
  //     setInputValue(input);

  //     // Filter options by input value
  //     const filtered = orders?.filter((option) => {
  //       const Name = option.Name;
  //       return Name?.toLowerCase().includes(input.toLowerCase());
  //     });

  //     setFilteredOptions(filtered);
  //   };

  // useEffect(() => {
  //   dispatch(getOrder({ token, rideId }));
  // }, [dispatch]);

    useEffect(() => {
      if (id) {
        const fetchData = async()=>{
          const {data }= await getRideCustomerOrderRequest(token,id,rideId)
          // setItem(data)
          // setFilteredOptions(data)
          setOrders(data)
          console.log(data,'hi data kiti');
          setFilteredOptions(data)
          
          
         }
         fetchData()
     
      }
    }, [id]);
  // useEffect(() => {
  //   if (orders?.length) {
  //     const customerorders = orders.find((obj) => obj._id === id);

  //     setFilteredOptions(customerorders.invoice);
  //   }
  // }, [orders, id]);
  // console.log(filteredOptions);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    const totalPages = Math.ceil(filteredOptions.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleOrders = filteredOptions.slice(startIndex, endIndex);
  return (
    <div className="flex flex-col gap-8 min-h-base px-8 py-8 select-none">
      {/* <div className="flex gap-7">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-5 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <Input
            id={"search__input"}
            type={"text"}
            value={inputValue}
            onChange={handleFiltering}
            name={"search__input"}
            placeholder={"Search"}
            styles={`w-full px-3 py-2 pl-14 bg-white border shadow-sm border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500"
              placeholder-slate-400  block sm:text-sm focus:ring-1 rounded-3xl`}
          />
        </div>
        <div
          onClick={() => navigate("/orders/add-order")}
          className="flex items-center justify-center h-9 rounded-full bg-mud-plus w-[2.7rem] cursor-pointer"
        >
          <AiOutlinePlus />
        </div>
      </div> */}
      {!orders ? (
        <div className="flex flex-col gap-4">
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            {orders?.length ? (
              visibleOrders.length ? (
                visibleOrders.map((x, idx) => (
                  <Section
                    key={idx}
                    name={`Order No : ${x.orderNo}`}
                    subOne={`Quantity : ${x.quantity}`}
                    mobile={x.mobileNumber}
                    Total={x.total}
                    img={`${ADMIN_BACKEND_URL}/items/${x.image}`}

                    Mode={`Mode : ${x.paymentMethod}`}
                    // onClick={() => navigate(`/orders/customer/${x._id}`)}
                    phone={x.mobile}
                    type={2}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center h-48">
                  No orders matching with that name
                </div>
              )
            ) : (
              <div className="flex justify-center items-center h-48">
                No orders
              </div>
            )}
          </div>

          {visibleOrders.length ? (
            <Pagination
              currentPage={currentPage}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              totalPages={Math.ceil(filteredOptions.length / itemsPerPage)}
            ></Pagination>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default CustomerOrder;
