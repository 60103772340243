import { AiFillCaretDown } from "react-icons/ai";

const Select = ({
  label,
  options,
  defaultValue, // Change 'selected' to 'defaultValue'
  onChange,
  styles,
  value,
  labelStyles,
  parentStyles,
  childStyles,
  id,
  name,
 
}) => {
  return (
    <>
      {label && (
        <label htmlFor={id} className={labelStyles}> {/* Change 'for' to 'htmlFor' */}
          {label}
        </label>
      )}
      <div className={parentStyles}>
        <select onChange={onChange}  value={value }name={name} id={id} className={styles} defaultValue={defaultValue} >
          {/* Remove the 'disabled' attribute from the default option */}
          {defaultValue && <option value="" >{defaultValue?.name}</option>} {/* Change 'selected' to 'defaultValue' */}
          {options &&
            options.map((x, idx) => (
              <option key={idx} className={childStyles} value={x.value}>
                {x.name}
              </option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 bottom-2 flex items-center px-2 text-gray-700">
          <AiFillCaretDown />
        </div>
      </div>
    </>
  );
};

export default Select;
