import { createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./LoadingSlice";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    addCustomerEror: "",
    addCustomerSuccess: false,
    customerLoading: true,
    customers: [],
    customerDashboard: null,
    singleCustomer: null,
    customerOrderHistory:null

  },
  reducers: {
    getCustomer() {},
    addCustomer() {},
    getCustomerDashboard() {},
    getSingleCustomer() {},
    getCustomerOrderHistory () {},
    setCustomer(state, action) {
      const customers = action.payload;
      return { ...state, customers };
    },
    setCustomerError(state, action) {
      console.log("working action payload");
      console.log(action);
      return { ...state, addCustomerEror: action.payload };
    },
    setCustomerSuccess(state, action) {
      return { ...state, addCustomerSuccess: action.payload };
    },
    setCustomerLoading(state, action) {
      return { ...state, customerLoading: action.payload };
    },

    setCustomerDashboard(state, action) {
      console.log("working action success payload");
      console.log(action);
      return { ...state, customerDashboard: action.payload };
    },
    setSingleCustomer(state, action){
      console.log("working action success payload");
      console.log(action);
      return {...state, singleCustomer: action.payload };
    },
    setCustomerOrderHistory(state, action) {
      return {...state, customerOrderHistory:action.payload };

    }
  },
});

export const {
  getCustomer,
  addCustomer,
  setCustomer,
  getCustomerOrderHistory,
  setCustomerError,
  setCustomerSuccess,
  setCustomerLoading,
  setCustomerDashboard,
  getCustomerDashboard,
  getSingleCustomer,
  setSingleCustomer,
  setCustomerOrderHistory
} = customerSlice.actions;

export default customerSlice.reducer;
