import { createSlice } from "@reduxjs/toolkit";

const VisitHistorySlice = createSlice({
  name: "visitHistory",
  initialState: {
    VisitHistory: '',
    VisitHistoryLoading : true,



  },
  reducers: {
    getVisitHistory() { },
    addVisitHistory(){},

    setVisitHistory(state, action) {
      const VisitHistory = action.payload
      return { ...state, VisitHistory: VisitHistory }

    },
   
    setVisitHistoryLoading(state, action) {
      console.log('working action succes payload');
      console.log(action);
      return { ...state, VisitHistoryLoading : action.payload }
    }


  },
});

export const { getVisitHistory, setVisitHistory, setVisitHistoryLoading ,addVisitHistory} = VisitHistorySlice.actions;

export default VisitHistorySlice.reducer;
