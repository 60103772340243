import { call, put } from "redux-saga/effects";
import { addOrderRequest,getOrderRequest } from "../requests/OrderRequest";
import { setLoading } from "../../ducks/LoadingSlice";
import handleAuthError from "./HandleAuthErrors";
import { setOrder ,setOrderFail,setOrderLoading,setOrderSuccess} from "../../ducks/OrderSlice";

export function* handleGetOrder(action) {
  console.log(action);
  try {
    const { data } = yield call(getOrderRequest, action);
    if (data) {
      yield put(setOrder(data));
    }
    console.log('no working');
    console.log(data);
    // yield put(setLoading({ loading: false }));
  } catch (error) {
    console.log('evde');
    console.log(error);
    console.log('nrha is not added to the');

    handleAuthError(error);
  }
}

export function* handleAddOrder(action) {
    console.log(action);
    console.log('ivde nthunaa nadakne');
  try {
    const { data } = yield call(addOrderRequest, action);
    if (data) {
      yield put(setOrderLoading(false));
      yield put(setOrderSuccess(true))

    } else {
      throw Error("Stock is not added to the data base");
    }
  } catch (error) {
    yield put(setOrderLoading(false));

    yield put(setOrderFail(true));
    console.log(error);
  }
}
