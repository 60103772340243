/* eslint-disable no-octal-escape */

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getItemsRequest,
  getSingleItemsRequest,
} from "../../redux/sagas/requests/itemRequest";
import { ADMIN_BACKEND_URL } from "../../config/Config";
import SendLoading from "../../layouts/Loading";
import { useSelector } from "react-redux";

const Single = ({ image, name, subOne, subTwo, mobile, type, onClick }) => {
  const { id } = useParams();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);
  const { mainRoute, subRoute } = useSelector((state) => state.ride)??{};

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getSingleItemsRequest("token", id,mainRoute);
      setItem(data[0]);
      setLoading(false)
    };
    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-3 min-h-base  py-8 px-0 sm:px-[20vw] select-none">
      {loading?<SendLoading></SendLoading>:    <div className="flex flex-col gap-8  bg-white px-6  py-10 select-none">
        <div className="flex items-center justify-between ">
          <div className=" flex items-center justify-start w-1/2 ">
            <div className="flex items-center justify-start ">
              <img
                src={`${ADMIN_BACKEND_URL}/${item?.img}`}
                className="shadow rounded-full w-32 h-32 sm:w-64 sm:h-64 border-none"
                alt=""
              />
            </div>
          </div>

          <div className=" flex flex-col justify-start w-1/2">
            <h4 className=" text-black dark:text-gray-400 text-lg fond-medium">
              {item?.name}
            </h4>
            <h3 className="text-red-500 font-semibold mt-1 ">
              {" "}
              AED {item?.price}
            </h3>

            <h2 className="text-black text-sm mt-1 leading-tight">{item?.stock} {' '}<span className="text-red-500">nos</span> </h2>
          </div>
        </div>
        <div className="mt-4 px-2">
          <h4 className=" text-black dark:text-gray-400 text-lg ">SlNo:1</h4>
          <p className="mb-10 text-black text-sm font-normal dark:text-gray-400 break-all">
            {item?.dis}
          </p>
        </div>
      </div>}
  
    </div>
  );
};

export default Single;
